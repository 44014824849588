import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  tokenReCapcha: '',
  reCapchaStore: '',
  triggerReCapcha: false,
};

if (localStorage.getItem('tokenKazatskaya')) {
  initialState.token = localStorage.getItem('tokenKazatskaya') as string;
}

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setUserToken(state, action) {
      state.token = action.payload;
    },
    setUserTokenReCapcha(state, action) {
      state.tokenReCapcha = action.payload;
    },
    setResReCapcha(state, action) {
      state.reCapchaStore = action.payload;
    },
    setTriggerReCapcha(state, action) {
      state.triggerReCapcha = action.payload;
    },

    removeTokenReCapcha(state) {
      state.tokenReCapcha = '';
    },
    removeAuthorization(state) {
      state.token = '';
    },
  },
});

export const {
  setTriggerReCapcha,
  setUserTokenReCapcha,
  setResReCapcha,
  removeTokenReCapcha,
  setUserToken,
  removeAuthorization,
} = authorizationSlice.actions;
export default authorizationSlice.reducer;
