import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAppDispatch, useAppSelector } from '../../store';
import { setTriggerReCapcha, setUserTokenReCapcha } from '../../store/slices/AuthorizationSlice';
import { AuthorizationUserAPI } from '../../store/services/UserService';

export const ReCaptchaComponent = () => {
  // const { triggerReCapcha } = useAppSelector((state) => state.authorization);
  const [recapchaCheck] = AuthorizationUserAPI.useRecapchaCheckMutation();
  const [tokenRe, setTokenRe] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useAppDispatch();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    if (token) setTokenRe(token);
    // dispatch(setUserTokenReCapcha(token));
    // dispatch(setTriggerReCapcha(false));
  }, [executeRecaptcha]);

  const checkRecaptchaAndRegister = async (tokenReCapcha: string) => {
    const response = await recapchaCheck({ user_response: tokenReCapcha });
    if (!('error' in response)) {
      dispatch(setUserTokenReCapcha(response.data.success));
    }
  };
  useEffect(() => {
    if (tokenRe) checkRecaptchaAndRegister(tokenRe);
  }, [tokenRe]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return <></>;
  //   return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
};
