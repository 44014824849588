import { ReactComponent as VideoLink } from '../../../assets/svg/video_link.svg';
import { Link } from 'react-scroll';
import cn from 'classnames';
import styles from './step-one.module.css';

const Step1 = () => {
  return (
    <section className={styles.step__wrapper}>
      <div className={cn(styles.step__container, 'container')}>
        <div className={styles.step__content}>
          <h2 className={styles.step__title}>Домашнее копчение мясных деликатесов</h2>
          <div className={styles.step__link}>
            <Link to="video-section" offset={40} smooth>
              <VideoLink />
            </Link>
            <span className={styles.link__text}>Процесс производства</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step1;
