import styles from './info-popup.module.css';
import cn from 'classnames';
import Button from '../Button';

type InfoPopupProps = {
  close: () => void;
};

const InfoPopupProf = ({ close }: InfoPopupProps) => {
  return (
    <div className={styles.popup__wrapper__prof}>
      <div className={cn(styles.popup__container__prof, 'container')}>
        <p className={styles.popup__text}>
          <span className={styles.text__string}>Отлично!</span>
          <span className={styles.text__string}>Ваши данные обновлены.</span>
        </p>
        <Button isPrimary text="Хорошо" onClick={() => close()} hasIcon={false} />
      </div>
    </div>
  );
};

export default InfoPopupProf;
