import { useMapConfig } from '../../../hooks/useMapConfig';
import styles from './yandex-map.module.css';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import MapForm from '../../../components/MapForm';
import { useEffect } from 'react';
import { MAP_STATE } from '../../../models/constants';

const YandexMap = () => {
  const { mapConfig } = useMapConfig();
  const balloonHtml = `
    <div class=${styles.balloon__wrapper}>
      <h3 class=${styles.balloon__title}>Пункт самовывоза:</h3>
      <p class=${styles.balloon__text}>
        Липецкая область,
        <br /> 
        с. Казаки, д.93
      </p>
    </div>
  `;

  return (
    <YMaps query={{ lang: 'ru_RU', apikey: '559c6d23-8354-406a-bf66-5fc727a2fc22' }}>
      <section className={styles.map__wrapper}>
        <Map
          defaultState={mapConfig}
          modules={['geoObject.addon.balloon']}
          className={styles.map__container}
        >
          <Placemark
            geometry={[52.625289, 38.267557]}
            options={{
              preset: 'islands#nightDotIcon',
              iconColor: '#000000',
              hideIconOnBalloonOpen: false,
              balloonCloseButton: false,
              balloonOffset: [-118, -12],
              zIndex: 5,
            }}
            properties={{
              balloonContent: balloonHtml,
            }}
          />
        </Map>
        <MapForm />
      </section>
    </YMaps>
  );
};

export default YandexMap;
