import { FC } from 'react';

import styles from './errors.module.css';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';

export type PropsModalParent = {
  close: () => void;
};
export const ModalReLogin: FC<PropsModalParent> = ({ close }) => {
  const navigate = useNavigate();
  const handleClickLogin = () => {
    navigate('/login', { state: { scrollToTop: true } });
    close();
  };
  const handleClickGuest = () => {
    localStorage.removeItem('tokenKazatskaya');
    localStorage.removeItem('isSuperUserKaz');
    close();
  };
  return (
    <div className={styles.container__white}>
      <span> Вам необходимо заново войти или продолжить как гость</span>
      <div className={styles.row}>
        <Button
          hasIcon={false}
          text="Войти"
          isPrimary={true}
          onClick={handleClickLogin}
          full={true}
        />
        <Button
          hasIcon={false}
          text="Гость"
          isPrimary={true}
          onClick={handleClickGuest}
          full={false}
        />
      </div>
    </div>
  );
};
