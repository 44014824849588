import styles from './pay.module.css';
import cn from 'classnames';
import Button from '../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import NotFoundIcon from '../../assets/img1/404.png';

const NotFound = () => {
  const navigate = useNavigate();

  const redirectToMain = () => {
    navigate('/');
  };

  return (
    <section className={styles.page__wrapper}>
      <div className="page__shadow" />
      <div className={cn(styles.page__container, 'container')}>
        <div className={styles.page__content}>
          <div className={styles.content__top}>
            <img src={NotFoundIcon} alt="Страница не найдена" className={styles.top__img} />
            <span className={cn(styles.top__text, styles.not_found__text)}>
              Такой страницы не существует
            </span>
          </div>
          <div className={styles.content__button}>
            <Button isPrimary text="На главную" onClick={redirectToMain} hasIcon={false} full />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
