import cnBind from 'classnames/bind';
import styles from './status-tag.module.css';

const cx = cnBind.bind(styles);

type StatusTagProps = {
  text: string | null;
};

const StatusTag = ({ text }: StatusTagProps) => {
  return (
    <div
      className={cx(styles.status__wrapper, {
        completed: text === 'Отгружен',
        in_progress: text === 'В процессе',
        payed: text === 'Оплачен',
        waiting: text === 'На согласовании',
      })}
    >
      <span className={styles.status__text}>{text}</span>
    </div>
  );
};

export default StatusTag;
