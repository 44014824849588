import styles from './info-popup.module.css';
import cn from 'classnames';
import Button from '../Button';

type InfoPopupProps = {
  close: () => void;
};

const InfoPopup = ({ close }: InfoPopupProps) => {
  return (
    <div className={styles.popup__wrapper}>
      <div className={cn(styles.popup__container, 'container')}>
        <p className={styles.popup__text}>
          <span className={styles.text__string}>Отлично!</span>
          <span className={styles.text__string}>Наш менеджер скоро </span>
          <span className={styles.text__string}>свяжется с вами.</span>
        </p>
        <Button isPrimary text="Хорошо" onClick={() => close()} hasIcon={false} />
      </div>
    </div>
  );
};

export default InfoPopup;
