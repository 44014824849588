import styles from './ordering.module.css';
import BreadScrumbs from '../../components/BreadScrumbs';
import { useLocation, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import cnBind from 'classnames/bind';
import Button from '../../components/atoms/Button';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { EMAIL_REGEX, PHONE_REGEX, TABLET_QUERY } from '../../models/constants';
import { KeyboardEvent, useRef } from 'react';
import CarouselComponent from '../../components/CarouselComponent';
import { Helmet } from 'react-helmet';

const cx = cnBind.bind(styles);

type OrderingDataProps = {
  name: string;
  email: string;
  phone_number: string;
  comment: string;
  delivery: string;
  delivery_address: string;
};

const Ordering = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<OrderingDataProps>();
  const matches = useMediaQuery(TABLET_QUERY);
  const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit = (data: OrderingDataProps) => console.log(data);
  // тут бы лишнее убрать
  const submitClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const MyKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+'];
    if (evt.key === 'Delete' || evt.key === 'Backspace') {
      return true;
    }
    if (allowedKeys.includes(evt.key)) {
      return true;
    }
    evt.preventDefault();
    return false;
  };

  return (
    <section className={styles.page__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className={styles.page__shadow} />
      <div className={styles.page__container}>
        <BreadScrumbs location={location.pathname} />
        <h2 className={styles.page__title}>Оформление заказа</h2>
        <div className={styles.page__content}>
          <h3 className={styles.page__subtitle}>Ваши контакты</h3>
          <div className={styles.ordering__data}>
            <form className={styles.data__grid} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.grid__item}>
                <input
                  type="text"
                  {...register('name')}
                  placeholder="Имя"
                  className={styles.item__input}
                />
                <input
                  type="email"
                  placeholder="E-mail"
                  className={cx(styles.item__input, { input__error: errors?.email })}
                  {...register('email', {
                    required: 'Поле должно быть заполнено',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: '',
                    },
                  })}
                  onInput={() => clearErrors('email')}
                />
                <input
                  type="tel"
                  {...register('phone_number', {
                    required: 'Поле должно быть заполнено',
                    pattern: {
                      value: PHONE_REGEX,
                      message: '',
                    },
                  })}
                  onKeyDown={(evt) => MyKeyPress(evt)}
                  placeholder="Номер телефона"
                  className={cx(styles.item__input, { input__error: errors?.phone_number })}
                  onInput={() => clearErrors('phone_number')}
                />
              </div>
              <div className={styles.grid__item}>
                <textarea
                  className={cn(styles.item__input, styles.item__textarea)}
                  placeholder="Комментарий к заказу"
                  {...register('comment')}
                />
              </div>
              <div className={styles.grid__item}>
                <div className={styles.item__delivery}>
                  <div className={styles.delivery__options}>
                    <h3 className={styles.options__title}>Доставка</h3>
                    <div className={styles.options__checkboxes}>
                      <label htmlFor="door" className={styles.checkbox__label}>
                        До двери
                        <input
                          {...register('delivery')}
                          type="radio"
                          name="delivery"
                          id="door"
                          value="До двери"
                          className={styles.checkbox__input}
                          defaultChecked
                        />
                        <div className={styles.checkbox__indicator} />
                      </label>
                      <label htmlFor="self" className={styles.checkbox__label}>
                        Самовывоз
                        <input
                          {...register('delivery')}
                          type="radio"
                          name="delivery"
                          id="self"
                          value="Самовывоз"
                          className={styles.checkbox__input}
                        />
                        <div className={styles.checkbox__indicator} />
                      </label>
                    </div>
                  </div>
                  <div className={styles.options__address}>
                    <input type="submit" className={styles.submit__input} ref={inputRef} />
                    <h4 className={styles.address__title}>Адрес доставки</h4>
                    <input
                      type="text"
                      {...register('delivery_address')}
                      className={styles.item__input}
                      placeholder="Адрес доставки"
                    />
                  </div>
                </div>
              </div>
              {matches && (
                <div className={styles.grid__item}>
                  <Button text="Оплатить заказ" full hasIcon={false} isPrimary onClick={() => {}} />
                </div>
              )}
            </form>
            {!matches && (
              <div className={styles.ordering__total}>
                <div className={styles.total__info}>
                  <div className={cn(styles.total__info, styles.info__content)}>
                    <div className={styles.content__section}>
                      <h3 className={styles.top__title}>Сумма</h3>
                      <div className={styles.top__sum}>
                        <span>52 023</span>
                        <span>₽</span>
                      </div>
                    </div>
                    <div className={styles.content__section}>
                      <h4 className={styles.section__title}>Всего товаров</h4>
                      <div className={styles.section__value}>
                        <span>24</span>
                        <span>шт</span>
                      </div>
                    </div>
                    <div className={styles.content__section}>
                      <h4 className={styles.section__title}>Доставка</h4>
                      <div className={styles.section__value}>
                        <span>300</span>
                        <span>₽</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.section__divider} />
                  <div className={styles.content__section}>
                    <h3 className={styles.top__title}>Итого</h3>
                    <div className={styles.top__sum}>
                      <span>52 323</span>
                      <span>₽</span>
                    </div>
                  </div>
                </div>
                <div className={styles.total__bottom}>
                  <p className={styles.bottom__link}>
                    Оформляя заказ, вы соглашаетесь {''}с
                    <Link to="/confidentiality-policy" target="_blank">
                      <span>политикой конфиденциальности</span>
                    </Link>
                  </p>
                  <Button
                    text="Оплатить заказ"
                    full
                    hasIcon={false}
                    isPrimary
                    onClick={() => submitClick()}
                  />
                </div>
              </div>
            )}
          </div>
          {matches && <CarouselComponent title="Добавьте к заказу" />}
        </div>
      </div>
    </section>
  );
};

export default Ordering;
