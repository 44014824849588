import { useState } from 'react';

export const useSearchInput = () => {
  const [searchValue, setValue] = useState<string>('');
  const [searchVisible, setVisible] = useState<boolean>(false);

  const onValueChange = (text: string) => setValue(text);
  const toggleSearchVisible = () => setVisible(!searchVisible);

  return {
    searchValue,
    searchVisible,
    onValueChange,
    toggleSearchVisible,
  };
};
