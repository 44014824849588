import DashboardHeader from '../DHeader';
import DashboardSidebar from '../DSidebar';
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './d-layout.module.css';
import { ProductsAPI } from '../../../store/services/ProductsService';
import { useAppSelector } from '../../../store';
import { AuthorizationUserAPI } from '../../../store/services/UserService';
import { IError } from '../../../store/storeInterfaces';
import { LoadingScreen } from '../../atoms/loading';
import { ServerError } from '../../atoms/ServerError/server-error';
import { ModalReLogin } from '../../atoms/ServerError/modalRelogin';
import DragModal from '../DragModal';
import cnBind from 'classnames/bind';

const cx = cnBind.bind(styles);

const DashboardLayout = () => {
  const location = useLocation();
  ProductsAPI.useGetAllProductsQuery({});
  ProductsAPI.useGetCategoriesQuery();
  const [trigger] = AuthorizationUserAPI.useLazyGetUserQuery();
  ProductsAPI.useGetAllProductsQuery({});
  const { token } = useAppSelector((state) => state.authorization);
  const [activeTab, setActiveTab] = useState('Заказы');
  const [rejectedEndpointName, setRejectedEndpointName] = useState<string | undefined>(undefined);
  const [isModalReLogin, setModalReLogin] = useState(false);
  const [isDragOpen, setDragOpen] = useState<boolean>(false);
  const [productForDrag, setProductForDrag] = useState<string>('');
  const queries = useAppSelector((state) => state.api.queries);
  const mutations = useAppSelector((state) => state.api.mutations);
  const isSomeQueryPending = Object.values(queries).some((query) => query?.status === 'pending');
  const isSomeMutationPending = Object.values(mutations).some(
    (query) => query?.status === 'pending'
  );

  const onChangeTab = (value: string) => {
    setActiveTab(value);
  };

  const handleDragModal = (bool: boolean, id?: string) => {
    if (id) {
      setProductForDrag(id);
    }
    setDragOpen(bool);
  };

  useEffect(() => {
    const rejectedQuery = Object.values(queries).find((query) => query?.status === 'rejected');
    const serverError = rejectedQuery?.error as IError;
    if (rejectedQuery && rejectedQuery.endpointName) {
      if (
        (rejectedQuery?.endpointName === 'getUser' && rejectedQuery, serverError.status === 401)
      ) {
        localStorage.removeItem('tokenKazatskaya');
        localStorage.removeItem('isSuperUserKaz');
        setModalReLogin(true);
      } else setModalReLogin(false);
    }
  }, [queries]);

  useEffect(() => {
    const rejectedMutation = Object.values(mutations).find(
      (mutation) => mutation?.status === 'rejected'
    );

    const serverError = rejectedMutation?.error as IError;
    if (rejectedMutation && rejectedMutation.endpointName) {
      if (serverError?.data?.detail && typeof serverError?.data?.detail === 'string') {
        setRejectedEndpointName(serverError.data.detail);
      } else if (rejectedMutation?.error) setRejectedEndpointName('Ой, что-то пошло не так');
    } else {
      setRejectedEndpointName(undefined);
    }
  }, [mutations]);

  useEffect(() => {
    const rejectedMutation = Object.values(mutations).find(
      (mutation) => mutation?.status === 'rejected'
    );

    const serverError = rejectedMutation?.error as IError;
    if (rejectedMutation && rejectedMutation.endpointName) {
      if (serverError?.data?.detail) {
        setRejectedEndpointName(serverError.data.detail);
      } else setRejectedEndpointName('Ой, что-то пошло не так');
    } else {
      setRejectedEndpointName(undefined);
    }
  }, [mutations]);
  useEffect(() => {
    if (token !== '') trigger();
  }, [token, trigger]);

  useEffect(() => {
    if (location.pathname.includes('goods') || location.pathname.includes('good-detail')) {
      setActiveTab('Товары');
      return;
    }

    if (location.pathname.includes('categories')) {
      setActiveTab('Категории');
    }

    if (location.pathname.includes('content')) {
      setActiveTab('Контент');
    }
  }, [location.pathname]);

  return (
    <section className={styles.dashboard__wrapper}>
      {isDragOpen && (
        <div className={styles.shadow}>
          <DragModal id={productForDrag} close={() => handleDragModal(false)} />
        </div>
      )}
      {isModalReLogin && <ModalReLogin close={() => setModalReLogin(false)} />}
      {isSomeQueryPending && <LoadingScreen />}
      {isSomeMutationPending && <LoadingScreen />}
      {rejectedEndpointName && (
        <ServerError
          message={rejectedEndpointName}
          close={() => setRejectedEndpointName(undefined)}
        />
      )}
      <DashboardHeader />
      <div className={styles.dashboard__content}>
        <DashboardSidebar active={activeTab} onChange={onChangeTab} />
        <div className={cx(styles.dashboard__outlet, { modal__blocked: isDragOpen })}>
          <Outlet context={{ handleDragModal }} />
        </div>
      </div>
    </section>
  );
};

export default DashboardLayout;
