import { ReactComponent as PlusIcon } from '../../../assets/svg/button_plus_icon.svg';
import styles from './d-button.module.css';

type DashboeardButtonProps = {
  text: string;
  hasIcon?: boolean;
  onClick: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

const DashboardButton = ({
  text,
  hasIcon,
  onClick,
  disabled,
  type = 'button',
}: DashboeardButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={styles.dashboard__button}
      onClick={() => onClick()}
    >
      {hasIcon && <PlusIcon />}
      <span className={styles.button__text}>{text}</span>
    </button>
  );
};

export default DashboardButton;
