import cn from 'classnames';
import { ReactComponent as CloseBtn } from '../../../assets/svg/close_cross.svg';
import styles from './dragmodal.module.css';
import ExpDrag from '../ExpDrag';
import { useAppSelector } from '../../../store';
type DragModalProps = {
  id: string;
  close: () => void;
};

const DragModal = ({ id, close }: DragModalProps) => {
  const { productPhotos } = useAppSelector((state) => state.productPhoto);

  return (
    <div className={styles.dragmodal__wrapper}>
      <div className={styles.dragmodal__container}>
        <div className={styles.modal__header}>
          <h4 className={styles.modal__title}>Фотографии</h4>
          <CloseBtn className={cn('icon__default', styles.icon__custom)} onClick={close} />
        </div>
        <ExpDrag
          dropId="modal-drop"
          title="Загрузите или перетащите фотографии."
          subtitle="Рекомендуется больше 4-х фотографий."
          accept="jpeg"
          existData={productPhotos}
          prodId={id}
        />
      </div>
    </div>
  );
};

export default DragModal;
