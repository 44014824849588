import MobileSlider from '../../../components/MobileSlider';
import SwiperCards from '../../../components/SwiperCards';
import SausageBlock from '../../../components/UIKit/SausageBlock';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { useEffect, useState } from 'react';
import { useSwiperHandler } from '../../../hooks/useSwiperHandler';
import cn from 'classnames';
import styles from './step-two.module.css';
import ButtonGroup from '../../../components/UIKit/ButtonGroup';
import { AdminAPI } from '../../../store/services/AdminrService';

const Step2 = () => {
  const { isMobile } = useMatchMedia();
  const { data: photos } = AdminAPI.useGetPhotoGalleryQuery();
  const [photoLinks, setPhotoLinks] = useState<string[]>([]);
  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null);
  const { onForwardClick, rotateForward, onBackClick, rotateBack } = useSwiperHandler();

  useEffect(() => {
    const arrPhoto: string[] = [];
    if (photos && photos.length > 0) {
      photos.forEach((element) => {
        arrPhoto.push(element.file_link);
      });
      setPhotoLinks(arrPhoto);
    }
  }, [photos]);

  return (
    <section className={styles.step__wrapper}>
      <div className={cn(styles.step__container, 'container')}>
        <h3 className="step__title">Галерея</h3>
        {isMobile ? (
          <MobileSlider images={photoLinks} />
        ) : (
          <div className={styles.step__content}>
            <div className={styles.content__left}>
              <SwiperCards
                photoArr={photoLinks}
                prevElement={prevEl}
                nextElement={nextEl}
                forward={onForwardClick}
                back={onBackClick}
              />
              <ButtonGroup
                setPrev={setPrevEl}
                setNext={setNextEl}
                onForwardClick={onForwardClick}
                onBackClick={onBackClick}
              />
            </div>
            <div className={styles.content__right}>
              <SausageBlock forward={rotateForward} back={rotateBack} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Step2;
