import { v4 } from 'uuid';
import { ReadOnlyFile } from '../../../../store/storeInterfaces';

export const createReadOnlyFile = (file: File): ReadOnlyFile => {
  return {
    id: v4(),
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
    file, // В этом поле хранится сам файл, который потом надо отправлять на сервер
  };
};
