import BreadScrumbs from '../../../components/BreadScrumbs';
import CarouselComponent from '../../../components/CarouselComponent';
import OrderingViewer from '../../../components/OrderingViewer';
import styles from './ordering.module.css';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

const OrderingPage = () => {
  const location = useLocation();
  return (
    <section className={styles.page__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className="page__shadow" />
      <div className={cn(styles.page__container, 'container')}>
        <BreadScrumbs location={location.pathname} />
        <h2 className={styles.page__title}>Оформление заказа</h2>
        <OrderingViewer />
        <div className={styles.cart__content}></div>
        {/* <CarouselComponent title="Добавьте к заказу" /> */}
      </div>
    </section>
  );
};

export default OrderingPage;
