import { useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/trash_bin.svg';
import cnBind from 'classnames/bind';
import styles from './categories.module.css';
import { ProductsAPI } from '../../../store/services/ProductsService';
import Button from '../../atoms/Button';

const cx = cnBind.bind(styles);

type CategoriesItemProps = {
  defaultValue: string;
  onEdit?: (arg1: string) => void;
  onDelete?: (arg1: string) => void;
  itemId: number;
  activeItem: number;
  toggleActive: (arg1: number) => void;
};

const CategoriesItem = ({
  defaultValue,
  toggleActive,
  itemId,
  activeItem,
}: CategoriesItemProps) => {
  const [deleteCategories] = ProductsAPI.useDeleteCategoriesMutation();
  const [pathCategories] = ProductsAPI.usePathCategoriesMutation();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [inputValue, setValue] = useState<string>(defaultValue);
  const [isDelId, setDelId] = useState<number>(0);
  const [removeCartWindow, setRemoveCartWindow] = useState(false);

  const onSettingsClick = (id: number) => {
    setDisabled(false);
    toggleActive(id);
  };
  const delCatClick = (id: number) => {
    setDelId(id);
    setRemoveCartWindow(true);
  };
  const handleRemove = () => {
    deleteCategories(isDelId).unwrap();
    setRemoveCartWindow(false);
  };
  const handlePatch = (id: number) => {
    pathCategories({ id: id, title: inputValue }).unwrap();
    // setVisibleSave(false);
  };

  const handleClickCancel = () => {
    setRemoveCartWindow(false);
  };
  return (
    <div
      className={cx(styles.item__wrapper, { active: itemId === activeItem })}
      onClick={() => toggleActive(itemId)}
    >
      {removeCartWindow && (
        <div className={styles.removeCartWindow}>
          <h2> Удалить категорию?</h2>
          <p> По данной категории не будет работать поиск в каталоге товаров</p>
          <div className={styles.row}>
            <button onClick={handleClickCancel} className={styles.repeat__button}>
              Отмена
            </button>
            <Button
              hasIcon={false}
              text="Удалить"
              isPrimary={true}
              onClick={handleRemove}
              full={true}
            />
          </div>
        </div>
      )}
      <div className={styles.position}>
        <input
          className={styles.item__input}
          value={inputValue}
          onChange={(evt) => {
            // setVisibleSave(true);
            setValue(evt.target.value);
          }}
          disabled={disabled}
        />
        {!disabled && (
          <button
            className={styles.button__save}
            type="button"
            onClick={() => {
              handlePatch(itemId);
            }}
          >
            {' '}
            ✓{' '}
          </button>
        )}
      </div>
      <div className={styles.item__icons}>
        <SettingsIcon className={styles.icon__custom} onClick={() => onSettingsClick(itemId)} />
        <DeleteIcon className={styles.icon__custom} onClick={() => delCatClick(itemId)} />
      </div>
    </div>
  );
};

export default CategoriesItem;
