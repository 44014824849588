export const morphString = (num: number) => {
  const word = 'товар';
  const n = num % 10;
  let ending = '';

  if (num >= 11 && num <= 20) {
    ending = 'ов';
  } else if (n >= 2 && n <= 4) {
    ending = 'а';
  } else if ((n >= 5 && n <= 9) || n === 0) {
    ending = 'ов';
  }

  return `${num} ${word}${ending}`;
};
