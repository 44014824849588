/* eslint-disable prettier/prettier */
import styles from './map-form.module.css';
import Button from '../atoms/Button';
import { MapFormData } from '../../store/storeInterfaces';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store';
import { AuthorizationUserAPI } from '../../store/services/UserService';
import { EMAIL_REGEX } from '../../models/constants';
import InfoPopup from '../atoms/InfoPopup';
import cn from 'classnames/bind';

const cx = cn.bind(styles);

const MapForm = () => {
  const { tokenReCapcha } = useAppSelector((state) => state.authorization);

  const [feedbackPost, { isSuccess }] = AuthorizationUserAPI.useFeedbackPostMutation();
  const [nameInput, setNameInput] = useState('');
  const [isOk, setOk] = useState(false);
  const [phoneInput, setPhoneInput] = useState('');
  const [emailInput, setEmailInput] = useState('');

  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<MapFormData>();

  const popupClose = () => {
    setOk(false);
  };

  useEffect(() => {
    if (isSuccess) setOk(true);
  }, [isSuccess]);

  // const checkRecaptchaAndRegister = async (dataReg: MapFormData) => {
  //   const response = await recapchaCheck({ user_response: tokenReCapcha });
  //   if (!('error' in response)) {
  //     if (response.data.success === 'True') {
  //       await feedbackPost(dataReg).unwrap();
  //     }
  //   }
  // };

  const onSubmit = async (data: FieldValues) => {
    const formData: MapFormData = {
      email: data.email,
      phone: data.phone,
      name: data.name || undefined,
    };
    if (tokenReCapcha === 'True') {
      await feedbackPost(formData).unwrap();
      reset();
    }
  };
  if (isOk) return <InfoPopup close={popupClose} />;
  return (
    <div className={styles.map_form__wrapper}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cx(styles.map_form__container, 'container')}
      >
        <div className={styles.map_form__title}>
          <span>Остались вопросы?</span>
          <span>Мы вам перезвоним</span>
        </div>
        <div className={styles.map_form__inputs}>
          <input
            type="text"
            {...register('name', {
              required: 'Поле должно быть заполнено',
            })}
            placeholder="Имя"
            className={cx(styles.map_form__input, { error: errors?.name })}
            onChange={(evt) => setNameInput(evt.target.value)}
            value={nameInput}
          />
          <span className={cx(styles.error__message, { visible: !isValid })}>
            {errors && errors.name?.message}
          </span>
          <input
            type="email"
            value={emailInput}
            placeholder="E-mail"
            {...register('email', {
              required: 'Поле должно быть заполнено',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Введите корректный адрес эл. почты',
              },
            })}
            onInput={() => clearErrors('email')}
            className={cx(styles.map_form__input, { error: errors?.email })}
            onChange={(evt) => setEmailInput(evt.target.value)}
          />
          <span className={cx(styles.error__message, { visible: !isValid })}>
            {errors && errors.email?.message}
          </span>
          <input
            type="tel"
            {...register('phone', {
              required: 'Поле должно быть заполнено',
              minLength: 11,
            })}
            placeholder="Телефон"
            className={cx(styles.map_form__input, { error: errors?.phone })}
            onChange={(evt) => setPhoneInput(evt.target.value)}
            value={phoneInput}
          />
          <span className={cx(styles.error__message, { visible: !isValid })}>
            {errors && errors.phone?.message}
          </span>
        </div>
        <div className={styles.map_form__bottom}>
          <Button text="Отправить" isPrimary hasIcon={false} onClick={() => { }} full />
        </div>
      </form>
    </div>
  );
};

export default MapForm;
