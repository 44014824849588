import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import MainPage from '../pages/MainPage';

import Catalog from '../pages/Catalog';
import GoodPage from '../pages/GoodPage';
import Cart from '../pages/Cart';
import FormPage from '../pages/FormPage';
import Profile from '../pages/Profile';
import Policy from '../pages/Policy';
import { ProtectedRoute } from './protected-route';
import { useAppSelector } from '../store';
import Ordering from '../pages/Ordering';
import OrderingPage from '../pages/Cart/OrderingPage';
import SuccessPay from '../pages/SpecialPages/SuccessPay';
import FailPay from '../pages/SpecialPages/FailPay';
import NotFound from '../pages/SpecialPages/NotFound';
import { AdminProtectedRoute } from './admin-protected-route';

import DashboardLayout from '../components/Dashboard copy/DLayout';
import DashboardOrders from '../pages/DOrders';
import DashboardOrdersDetail from '../pages/DOrderDetail';
import DashboardGoods from '../pages/DGoods';
import DashboardGoodDetail from '../pages/DGoodDetail';
import DashboardContentHandle from '../pages/DContentHandle';
import DashboardCategories from '../pages/DCategories';
import ResetPasswordPage from '../pages/ResetPasswordPage';

const GlobalRoutes = () => {
  const { token } = useAppSelector((state) => state.authorization);
  const { is_superuser } = useAppSelector((state) => state.user);

  return (
    <Routes>
      <Route
        path="admin"
        element={
          <AdminProtectedRoute admin={is_superuser}>
            <DashboardLayout />
          </AdminProtectedRoute>
        }
      >
        <Route
          index
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardOrders />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="orders/:id"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardOrdersDetail />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="goods"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardGoods />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="good-detail/new"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardGoodDetail />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="good-detail/:id"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardGoodDetail />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="content"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardContentHandle />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="categories"
          element={
            <AdminProtectedRoute admin={is_superuser}>
              <DashboardCategories />
            </AdminProtectedRoute>
          }
        />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route index element={<MainPage />} />
        <Route path="auth/verify" element={<ResetPasswordPage />} />
        <Route path="catalog" element={<Catalog />} />
        <Route path="catalog/:id" element={<GoodPage />} />
        <Route path="catalog/cart" element={<Cart />} />
        <Route path="catalog/cart/ordering" element={<Ordering />} />
        <Route path="signup" element={<FormPage />} />
        <Route path="ordering" element={<OrderingPage />} />
        <Route path="login" element={<FormPage />} />
        <Route path="recovery-pass" element={<FormPage />} />
        <Route path="success-pay" element={<SuccessPay />} />
        <Route path="fail-pay" element={<FailPay />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route
          path="admin"
          element={
            <AdminProtectedRoute admin={admin}>
              <AdminPage />
            </AdminProtectedRoute>
          }
        /> */}
        {/* <Route path="reset-pass" element={<FormPage />} /> */}
        <Route
          path="profile"
          element={
            <ProtectedRoute authorization={token}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="confidentiality-policy" element={<Policy />} />
      </Route>
    </Routes>
  );
};

export default GlobalRoutes;
