import { useMatchMedia } from '../../hooks/useMatchMedia';
import cnBind from 'classnames/bind';
import styles from './highlight-block.module.css';

const cx = cnBind.bind(styles);

const HighlightBlock = () => {
  const { isMobile } = useMatchMedia();

  return (
    <section className={styles.step__wrapper}>
      <div className={cx(styles.step__container, { container: !isMobile })}>
        <ul className={styles.step__content}>
          <li className={styles.content__item}>
            <div className={styles.item__spotlight} />
            <div className={cx(styles.item__img, styles.item__one)}></div>
            <div className={styles.item__content}>
              <div className={styles.item__title}>с 9:00 до 18:00</div>
              <span className={styles.item__subtitle}>В будни</span>
            </div>
          </li>
          <li className={styles.content__item}>
            <div className={styles.item__spotlight} />
            <div className={cx(styles.item__img, styles.item__two)}></div>
            <div className={styles.item__content}>
              <div className={styles.item__title}>Доставка</div>
              <span className={styles.item__subtitle}>По договоренности.</span>
            </div>
          </li>
          <li className={styles.content__item}>
            <div className={styles.item__spotlight} />
            <div className={cx(styles.item__img, styles.item__three)}></div>
            <div className={styles.item__content}>
              <div className={styles.item__title}>+7 (925) 754-96-00</div>
              <span className={styles.item__subtitle}>Всегда на связи</span>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HighlightBlock;
