import styles from './search-input.module.css';
import { ReactComponent as CloseBtn } from '../../assets/icons/rotated_cross.svg';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { setSearchValue } from '../../store/slices/GeneralConditionsSlice';
import { ProductType } from '../../store/storeInterfaces';
import { Link } from 'react-router-dom';
import cnBind from 'classnames/bind';
import { v4 } from 'uuid';
import { useSearchInput } from '../../hooks/useSearchInput';

const cx = cnBind.bind(styles);

type SearchInputProps = {
  isVisible: boolean;
  handleClose: () => void;
};

const SearchInput = ({ isVisible, handleClose }: SearchInputProps) => {
  const { searchValue, onValueChange } = useSearchInput();
  const dispatch = useAppDispatch();
  const productsFromStore = useAppSelector((state) => state.products);
  const [predictedTitles, setPredictedTitles] = useState<ProductType[]>([]);
  const [products, setProductsForDisplay] = useState<ProductType[]>([]);

  useEffect(() => {
    if (productsFromStore) setProductsForDisplay(productsFromStore);
  }, [productsFromStore]);

  useEffect(() => {
    if (isVisible) {
      onValueChange('');
      setPredictedTitles([]);
    }
  }, [isVisible]);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onValueChange(evt.target.value);
    dispatch(setSearchValue(evt.target.value.toLowerCase()));
    predictTitle(evt.target.value);
  };

  const predictTitle = (newTitle: string) => {
    const matchedProducts = products.filter((product) =>
      product.title?.toLowerCase().includes(newTitle.toLowerCase())
    );
    setPredictedTitles(matchedProducts);
  };

  const handleLinkClick = () => {
    onValueChange('');
    setPredictedTitles([]);
    handleClose();
  };

  const handleClick = () => {
    if (searchValue) {
      onValueChange('');
      return;
    }

    if (!searchValue) {
      handleClose();
      return;
    }
  };

  const highlightTitle = (title: string) => {
    if (searchValue) {
      const regex = new RegExp(searchValue, 'gi');
      return title.replace(
        regex,
        (match) => `<span class=${styles.item__highlight}>${match}</span>`
      );
    }
    return title;
  };

  return (
    <>
      <form className={styles.search}>
        <div className={styles.search__box}>
          <input
            type="text"
            className={styles.search__input}
            placeholder="Поиск по товарам"
            value={searchValue}
            onChange={(evt) => handleChange(evt)}
          />
          <CloseBtn
            className={cx('icon__default', styles.icon__custom)}
            onClick={() => handleClick()}
          />
          {searchValue && predictedTitles.length === 0 && (
            <div className={styles.search__nothing}>
              <span className={styles.search__item}>Ничего не найдено</span>
            </div>
          )}
          {searchValue && predictedTitles.length > 0 && (
            <ul className={styles.search__list}>
              {predictedTitles.map(({ id, title = '', photo }) => (
                <Link key={v4()} to={`catalog/${id}`} onClick={handleLinkClick}>
                  <li className={styles.search__item}>
                    <img className={styles.item__img} src={photo?.[0]} alt="photo product"></img>
                    <span dangerouslySetInnerHTML={{ __html: highlightTitle(title) }} />
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </div>
      </form>
    </>
  );

  // return (
  //   <>
  //     <form className={cx(styles.form__hidden, { form__visible: isVisible })}>
  //       <input
  //         type="text"
  //         className={styles.searchinput}
  //         placeholder="Поиск по товарам"
  //         onChange={handleChange}
  //         value={inputValue}
  //       />
  //       <CloseBtn className={styles.input__clear_btn} onClick={() => handleClick()} />
  //     </form>
  //     {inputValue && predictedTitles.length === 0 && (
  //       <div className={styles.ul}>Ничего не найдено</div>
  //     )}
  // {predictedTitles.length > 0 && (
  //   <div>
  //     <ul className={styles.ul}>
  //       {predictedTitles.map(({ id, title, photo }) => (
  //         <Link key={v4()} to={`catalog/detail-good/${id}`} onClick={handleLinkClick}>
  //           <li>
  //             {' '}
  //             <img className={styles.img} src={photo?.[0]} alt="photo product"></img>
  //             <span dangerouslySetInnerHTML={{ __html: highlightTitle(title) }} />
  //           </li>
  //         </Link>
  //       ))}
  //     </ul>
  //   </div>
  // )}
  //   </>
  // );
};

export default SearchInput;
