import { Link } from 'react-router-dom';
import styles from './breadscrumbs-item.module.css';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';
import cn from 'classnames';

type BreadScrumbsItemProps = {
  route?: string;
  current?: boolean;
  title: string;
};

const BreadScrumbsItem = ({ route, current, title }: BreadScrumbsItemProps) => {
  return (
    <>
      {current ? (
        <li className={cn(styles.breadscrumbs__item, styles.item__current)}>{title}</li>
      ) : (
        <li className={styles.breadscrumbs__item}>
          <Link to={`/${route}`}>{title}</Link>
          <ArrowRight className={styles.item__icon} />
        </li>
      )}
    </>
  );
};

export default BreadScrumbsItem;
