import { ChangeEvent, DragEvent, useRef, useState, useEffect } from 'react';
import { ReactComponent as UploadIcon } from '../../../assets/svg/upload_icon.svg';
import { ProductVideoRes, ProductPhotoFile, ReadOnlyFile } from '../../../store/storeInterfaces';
import DragFileItem from '../DragFileItem';
import { AdminAPI } from '../../../store/services/AdminrService';
import { ProductsAPI } from '../../../store/services/ProductsService';
import { createReadOnlyFile } from './helper/createReadOnlyFile';
import { checkoutFile } from './helper/checkoutFile';
import styles from './exp-drag.module.css';
import cnBind from 'classnames/bind';
import FileListItem from '../FileListItem';
import DashboardButton from '../../UIKit/DashboardButton';
import DragPhotoItem from '../DragPhotoItem';
import { useAppDispatch } from '../../../store';
import { setAddedPhotos, removeProductPhotos } from '../../../store/slices/ProductPhotoSlice';
import { useNavigate } from 'react-router-dom';

type ExpDragProps = {
  title?: string;
  subtitle?: string;
  accept?: string;
  existData?: ProductVideoRes[] | ProductPhotoFile[];
  dropId: string;
  multiple?: boolean;
  prodId?: string;
};

const cx = cnBind.bind(styles);

const ExpDrag = ({
  title,
  subtitle,
  accept,
  existData,
  dropId,
  multiple,
  prodId,
}: ExpDragProps) => {
  const [deleteVideo] = AdminAPI.useDeleteVideoMutation();
  const [postVideo] = AdminAPI.usePostVideoMutation();
  const [deletePhotoGallery] = AdminAPI.useDeletePhotoGalleryMutation();
  const [postPhotoGallery] = AdminAPI.usePostPhotoGalleryMutation();
  const [postPhoto] = ProductsAPI.usePostPhotoMutation();
  const [deletePhoto] = ProductsAPI.useDeletePhotoMutation();
  const [draggable, setDraggable] = useState<boolean>(false);
  const [fileList, setFileList] = useState<ReadOnlyFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isValidate, setIsValidate] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (fileList.length > 0) {
      const checked = checkoutFile(fileList[0], accept);

      if (!checked.error) {
        setIsValidate(false);
      }
    } else {
      setIsValidate(true);
    }
  }, [fileList, accept]);

  const dropHandler = (evt: DragEvent) => {
    evt.preventDefault();
    const files = Array.from(evt.dataTransfer.files);
    const newFileList = files.map((file) => createReadOnlyFile(file));
    setFilesToUpload(files);
    setFileList(newFileList);
    setDraggable(false);
    dispatch(setAddedPhotos(newFileList));
  };

  const changeHandler = (evt: ChangeEvent) => {
    evt.preventDefault();
    if (inputRef.current?.files) {
      const files = Array.from(inputRef.current.files);
      const newFileList = files.map((file) => createReadOnlyFile(file));
      setFilesToUpload(files);
      setFileList(newFileList);
      dispatch(setAddedPhotos(newFileList));
    }
  };

  const removeFileFromServer = (id: string | number) => {
    deleteVideo(id);
  };

  const removePhotoFromServer = (pk: string | number) => {
    if (dropId === 'photos') {
      deletePhotoGallery(pk);
      return;
    }

    if (dropId === 'modal-drop' && prodId) {
      dispatch(removeProductPhotos(pk));
      deletePhoto({ id: prodId, pk });
      return;
    }
  };

  const removeFileFromList = (id: string) => {
    const newFileList = fileList.filter((file) => file.id !== id);
    setFileList(newFileList);
    dispatch(setAddedPhotos(newFileList));
  };

  const postDataToDatabase = () => {
    for (let i = 0; i < filesToUpload.length; i++) {
      const formData = new FormData();
      formData.append('file', filesToUpload[i]);
      if (dropId === 'videos') {
        postVideo(formData);
      }

      if (dropId === 'modal-drop') {
        if (prodId) {
          postPhoto({ id: prodId, photo: formData }).unwrap();
        }
        sessionStorage.removeItem('recomendTitles');
        navigate(`good-detail/${prodId}`, { state: { scrollToTop: true } });
      }

      if (dropId === 'photos') {
        postPhotoGallery(formData);
      }
    }
    setFileList([]);
  };

  const onDragStart = (evt: DragEvent) => {
    evt.preventDefault();
    setDraggable(true);
  };

  const onDragOver = (evt: DragEvent) => {
    evt.preventDefault();
    setDraggable(false);
  };

  return (
    <div
      className={styles.dnd__wrapper}
      onDragStart={(evt) => onDragStart(evt)}
      onDragLeave={(evt) => onDragOver(evt)}
      onDragOver={(evt) => onDragStart(evt)}
      onDrop={(evt) => dropHandler(evt)}
    >
      <input
        id={dropId}
        type="file"
        className={styles.dnd__input}
        ref={inputRef}
        accept={`.${accept}`}
        multiple={multiple}
        onChange={(evt) => changeHandler(evt)}
      />
      <div className={cx(styles.dnd__droparea, { ready__droparea: draggable })}>
        <label htmlFor={dropId} className={styles.dnd__label}>
          <UploadIcon className={cx('icon__default', styles.icon__custom)} />
          <span className={styles.dnd__title}>{title}</span>
          <span className={styles.dnd__subtitle}>{subtitle}</span>
        </label>
      </div>
      <div className={styles.previews__section}>
        <div>
          <h4 className={styles.section__title}>Новые файлы</h4>
          {fileList.length === 0 && (
            <div className={styles.empty__list}>Пока ничего не загружено</div>
          )}
          {fileList && fileList.length > 0 && (
            <ul className={styles.files__list}>
              {fileList.map((file) => {
                const fileInfo = checkoutFile(file, accept);
                return (
                  <li key={file.id} className={styles.list__item}>
                    <FileListItem
                      fileFormat={accept}
                      fileTitle={file.fileName}
                      errorText={fileInfo?.errorMessage}
                      hasError={fileInfo?.error}
                      fileId={file.id}
                      onRemoveClick={removeFileFromList}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div>
          <h4 className={styles.section__title}>Существующие файлы</h4>
          <ul className={styles.files__list}>
            {accept === 'mp4' &&
              existData?.map((file) => (
                <DragFileItem
                  key={file.id}
                  id={file.id}
                  link={file.file_link}
                  onDeleteClick={removeFileFromServer}
                />
              ))}
            {accept === 'jpeg' &&
              existData?.map((photo) => (
                <DragPhotoItem
                  key={photo.id}
                  link={photo.file_link}
                  id={photo.id}
                  onRemoveClick={removePhotoFromServer}
                />
              ))}
          </ul>
        </div>
      </div>
      <div className={styles.button__container}>
        <DashboardButton text="Сохранить" onClick={postDataToDatabase} disabled={isValidate} />
      </div>
    </div>
  );
};

export default ExpDrag;
