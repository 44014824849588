import { useState, useEffect } from 'react';

interface IUserLocation {
  cityName: string | null;
  lat: number;
  lon: number;
}

export const useUserLocation = () => {
  const [userLocation, setLocation] = useState<IUserLocation | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setLocation({
        cityName: null,
        lat: pos.coords.latitude,
        lon: pos.coords.longitude,
      });
    });
  }, []);

  return { userLocation };
};
