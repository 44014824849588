import { Link } from 'react-router-dom';
import { ReactComponent as OrderIcon } from '../../../assets/svg/dashboard_orders.svg';
import { ReactComponent as GoodsIcon } from '../../../assets/svg/dashboard_goods.svg';
import { ReactComponent as CategoriesIcon } from '../../../assets/svg/dashboard_categories.svg';
import { ReactComponent as ContentIcon } from '../../../assets/svg/dashboard_content.svg';
import cnBind from 'classnames/bind';
import styles from './d-sidebar.module.css';

const cx = cnBind.bind(styles);

type DashboardSidebarProps = {
  active: string;
  onChange: (arg1: string) => void;
};

const DashboardSidebar = ({ active, onChange }: DashboardSidebarProps) => {
  return (
    <aside className={styles.sidebar__wrapper}>
      <nav>
        <ul className={styles.sidebar__container}>
          <Link to="/admin" replace>
            <li
              className={cx(styles.sidebar__link, { active: active === 'Заказы' })}
              onClick={() => onChange('Заказы')}
            >
              <OrderIcon className={styles.link__icon} />
              <span className={styles.link__text}>Заказы</span>
            </li>
          </Link>
          <Link to="/admin/goods" replace>
            <li
              className={cx(styles.sidebar__link, { active: active === 'Товары' })}
              onClick={() => onChange('Товары')}
            >
              <GoodsIcon className={styles.link__icon} />
              <span className={styles.link__text}>Товары</span>
            </li>
          </Link>
          <Link to="/admin/categories" replace>
            <li
              className={cx(styles.sidebar__link, { active: active === 'Категории' })}
              onClick={() => onChange('Категории')}
            >
              <CategoriesIcon className={styles.link__icon} />
              <span className={styles.link__text}>Категории</span>
            </li>
          </Link>
          <Link to="/admin/content" replace>
            <li
              className={cx(styles.sidebar__link, { active: active === 'Контент' })}
              onClick={() => onChange('Контент')}
            >
              <ContentIcon className={styles.link__icon} />
              <span className={styles.link__text}>Контент</span>
            </li>
          </Link>
        </ul>
      </nav>
    </aside>
  );
};

export default DashboardSidebar;
