import styles from './cart.module.css';
import BreadScrumbs from '../../components/BreadScrumbs';
import { useLocation } from 'react-router-dom';
import CarouselComponent from '../../components/CarouselComponent';
import CartViewer from '../../components/CartViewer';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const Cart = () => {
  const location = useLocation();

  useScrollToTop();
  return (
    <section className={styles.cart__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className="page__shadow" />
      <div className={cn(styles.cart__container, 'container')}>
        <BreadScrumbs location={location.pathname} />
        <div className={styles.cart__content}>
          <h2 className={styles.cart__title}>Корзина</h2>
          <CartViewer />
        </div>
        <div className={styles.cart__carousel}>
          <CarouselComponent title="Добавьте к заказу" />
        </div>
      </div>
    </section>
  );
};

export default Cart;
