import { useState, useEffect } from 'react';
import { ProductType } from '../store/storeInterfaces';
import { ProductsAPI } from '../store/services/ProductsService';

export const useRecomendations = (
  id = '0',
  recomendations: ProductType[] | undefined,
  products: ProductType[]
) => {
  const [titles, setTitles] = useState<string[]>(['']);
  const [ids, setIds] = useState<number[] | null>(null);
  const [deleteItemFromRecomendations] = ProductsAPI.useDeleteItemFromRecomendationsMutation();

  const allProducts = recomendations?.map((prod) => {
    const { title, id } = prod;
    return { title, id };
  });

  useEffect(() => {
    if (recomendations) {
      const mapped = recomendations.map((el) => el.title || 'Наименование');
      const newArr = [...mapped, ''];
      setTitles(newArr);
      return;
    } else {
      setTitles(['']);
    }
  }, [recomendations]);

  const idsUpdater = (arr: string[]) => {
    const idsList: number[] = [];
    arr.forEach((string) => {
      const prod = allProducts?.find((el) => el.title === string);
      if (prod) {
        idsList.push(prod?.id || 0);
      }
    });

    setIds(idsList);
  };

  const removeFromCurrentRecomends = (index: number) => {
    const removingTitle = titles.find((_, i) => i === index);
    const removingProd = products.find((el) => el.title === removingTitle);
    if (removingProd?.id) deleteItemFromRecomendations({ id, pk: removingProd.id });
    const filteredRecomends = titles.filter((_, i) => i !== index);
    setTitles(filteredRecomends);
    sessionStorage.setItem('recomendTitles', JSON.stringify(filteredRecomends));
    idsUpdater(filteredRecomends);
  };

  const addCurrentRecomend = () => {
    const newRecomends = [...titles, ''];
    setTitles(newRecomends);
  };

  const onValueChange = (value: string, ind: number) => {
    const updatedRecomends = [...titles];
    updatedRecomends[ind] = value;
    setTitles(updatedRecomends);
    sessionStorage.setItem('recomendTitles', JSON.stringify(updatedRecomends));
    idsUpdater(updatedRecomends);
  };

  return {
    titles,
    ids,
    removeFromCurrentRecomends,
    addCurrentRecomend,
    onValueChange,
  };
};
