import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../../assets/svg/logo.svg';
import styles from './logo.module.css';

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.logo__wrapper}
      onClick={() => navigate('/', { state: { scrollToTop: true } })}
    >
      <LogoIcon />
      <span className={styles.logo__text}>Казацкая изба</span>
    </div>
  );
};

export default Logo;
