import styles from './pay.module.css';
import cn from 'classnames';
import Button from '../../components/atoms/Button';
import SuccessIcon from '../../assets/img1/success_pay.png';
import { useNavigate } from 'react-router-dom';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const SuccessPay = () => {
  const { isMobile } = useMatchMedia();
  const navigate = useNavigate();

  const redirectToMain = () => {
    navigate('/', { state: { scrollToTop: true } });
  };

  return (
    <section className={styles.page__wrapper}>
      <div className="page__shadow" />
      <div className={cn(styles.page__container, 'container')}>
        <div className={styles.page__content}>
          <div className={styles.content__top}>
            <img src={SuccessIcon} alt="Успешная оплата" className={styles.top__img} />
            <span className={styles.top__text}>
              Менеджер свяжется {isMobile && <br />}с вами {!isMobile && <br />}в ближайшее время
            </span>
          </div>
          <div className={styles.content__button}>
            <Button isPrimary text="На главную" onClick={redirectToMain} hasIcon={false} full />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessPay;
