import { Link } from 'react-router-dom';
import { useState } from 'react';
import styles from './order.module.css';
import { ReactComponent as ArrowDown } from '../../assets/icons/arr_down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arr_up.svg';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import cnBind from 'classnames/bind';
import { v4 } from 'uuid';
import { OrderProducts, OrdersGetRequest, ProductType } from '../../store/storeInterfaces';
import { useAppDispatch, useAppSelector } from '../../store';
import { addProductToCart } from '../../store/slices/CartSlice';
import { morphString } from './helper/morphString';
import RepeatOrderPopup from '../atoms/RepeatOrderPopup';
import { HashLink } from 'react-router-hash-link';

const cx = cnBind.bind(styles);

type OrderProps = {
  order: OrdersGetRequest;
};
const Order = ({ order }: OrderProps) => {
  const { isMobile, isTablet } = useMatchMedia();
  const [visible, setVisible] = useState<boolean>(false);
  const products = useAppSelector((state) => state.products);
  const dispatch = useAppDispatch();
  const getProductById = (productId: string | number) => {
    return products.find((product: ProductType) => product.id === +productId);
  };
  const [isAddedToCart, setAddedToCart] = useState(false);

  const handleClick = () => setAddedToCart(true);

  const handleClose = (prods: OrderProducts[]) => {
    prods.forEach((product) => {
      const productToAdd = getProductById(product.product);
      if (productToAdd) {
        dispatch(
          addProductToCart({
            id: product.product,
            count: product.amount,
            productWight: product.amount,
          })
        );
      }
    });
    setAddedToCart(false);
  };

  return (
    <div className={styles.order__wrapper}>
      <div className={cx(styles.order__container, 'container')}>
        {isAddedToCart && <RepeatOrderPopup close={() => handleClose(order.order_products)} />}
        <div className={styles.container__header}>
          <div className={styles.top__block}>
            <div className={styles.header__top}>
              <span className={styles.top__element}>{order.created_on}</span>
              {!isTablet && (
                <span className={styles.top__element}>
                  {morphString(order.order_products.length)}
                </span>
              )}
              {!isMobile && (
                <button
                  disabled={order.order_products.length < 1}
                  onClick={handleClick}
                  className={cx(styles.repeat__button, { tablet__position: !isMobile })}
                >
                  {order.order_products.length > 0 ? ' Повторить' : 'Недоступен'}
                </button>
              )}
            </div>
            <h4 className={styles.header__middle}>Заказ №{order.id}</h4>
          </div>
          <div className={styles.header__bottom}>
            <div className={styles.bottom__element}>
              <span>Детали заказа</span>
              {!visible ? (
                <ArrowDown className={styles.icon__custom} onClick={() => setVisible(true)} />
              ) : (
                <ArrowUp className={styles.icon__custom} onClick={() => setVisible(false)} />
              )}
            </div>
            {isTablet && (
              <span className={styles.top__element}>
                {morphString(order.order_products.length)}
              </span>
            )}
          </div>
        </div>
        {visible && (
          <ul className={styles.order__list}>
            {order.order_products.map((product) => {
              const productById = getProductById(product.product ?? 0);
              return productById ? (
                <li key={v4()} className={styles.list__item}>
                  <div className={styles.item__left}>
                    <HashLink smooth to={`/catalog/${productById.id}#top`}>
                      <span className={styles.item__text}>{productById.title}</span>
                    </HashLink>
                    {isTablet && (
                      <span
                        className={styles.digits__bottom}
                      >{`${product.amount} ${productById.units}`}</span>
                    )}
                  </div>
                  <div className={styles.item__digits}>
                    <span className={styles.digits__top}>{`${product.price} ₽`}</span>
                    {!isTablet && (
                      <span
                        className={styles.digits__bottom}
                      >{`${product.amount} ${productById.units}`}</span>
                    )}
                  </div>
                </li>
              ) : (
                <li key={v4()} className={styles.list__item}>
                  <span className={styles.top__element}>Данный товар не доступен</span>
                </li>
              );
            })}
          </ul>
        )}

        {isMobile && (
          <button onClick={handleClick} className={styles.repeat__button}>
            Повторить
          </button>
        )}
      </div>
    </div>
  );
};

export default Order;

/*
  <li className={styles.list__item}>
              <div className={styles.item__left}>
                <span className={styles.item__text}>Колбаски венские</span>
                {isTablet && <span className={styles.digits__bottom}>{`5 кг.`}</span>}
              </div>
              <div className={styles.item__digits}>
                <span className={styles.digits__top}>{`182 ₽`}</span>
                {!isTablet && <span className={styles.digits__bottom}>{`5 кг.`}</span>}
              </div>
            </li>

*/
