import { createSlice } from '@reduxjs/toolkit';
import { ProductPhotoFile, ReadOnlyFile } from '../storeInterfaces';

interface IProductPhotosState {
  productPhotos: ProductPhotoFile[];
  addedPhotos: ReadOnlyFile[];
  isChanged: boolean;
}

const initialState: IProductPhotosState = {
  productPhotos: [],
  addedPhotos: [],
  isChanged: false,
};
const productPhotoSlice = createSlice({
  name: 'product-photos',
  initialState,
  reducers: {
    setProductPhotos: (state, action) => {
      state.productPhotos = action.payload;
    },
    setAddedPhotos: (state, action) => {
      state.addedPhotos = action.payload;
    },
    removeProductPhotos: (state, action) => {
      state.productPhotos = state.productPhotos.filter((el) => el.id !== action.payload);
    },
    setChange: (state, action) => {
      state.isChanged = action.payload;
    },
  },
});

export const { setProductPhotos, setAddedPhotos, removeProductPhotos, setChange } =
  productPhotoSlice.actions;
export default productPhotoSlice.reducer;
