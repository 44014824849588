import styles from './counter-button.module.css';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus_icon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus_icon.svg';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';

type CounterButtonProps = {
  text: string;
  onCountChange: (newCount: number) => void;
  weight: number;
  id: string | number;
};

const CounterButton = ({ text, onCountChange, weight, id }: CounterButtonProps) => {
  const [count, setCount] = useState<number>(+weight);

  const countD = useAppSelector((state) => {
    const product = state.cart.find((product) => product.id?.toString() === id);
    return product ? product.count : 0;
  });
  const countW = useAppSelector((state) => {
    const product = state.cart.find((product) => product.id?.toString() === id);
    return product ? product.productWight : 0;
  });
  const [initialCountD, setInitialCountD] = useState<number | null>(null);

  useEffect(() => {
    if (initialCountD === null && countW !== 0) {
      setInitialCountD(countW);
    }
  }, [countW, initialCountD]);

  useEffect(() => {
    if (countD) setCount(countD);
  }, [countD]);

  const handleIncrement = () => {
    if (initialCountD) {
      const newCount = count + initialCountD;
      setCount(newCount);
      onCountChange(newCount);
    }
  };

  const handleDecrement = () => {
    if (initialCountD) {
      const newCount = count - initialCountD;
      setCount(newCount);
      onCountChange(newCount);
    }
  };

  return (
    <div className={styles.button__wrapper}>
      <MinusIcon className={styles.button__icon} onClick={handleDecrement} />
      <div className={styles.button__text}>
        <span>{count.toFixed(1)}</span>
        <span>{text}</span>
      </div>
      <PlusIcon className={styles.button__icon} onClick={handleIncrement} />
    </div>
  );
};

export default CounterButton;
