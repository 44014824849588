import styles from './good.module.css';
import { useLocation, useParams } from 'react-router-dom';
import BreadScrumbs from '../../components/BreadScrumbs';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';
import GoodImage from '../../assets/img/good_image.png';
import Button from '../../components/atoms/Button';
import { ReactComponent as CartPlus } from '../../assets/icons/cart_icon_plus.svg';
import CarouselComponent from '../../components/CarouselComponent';
import { ProductsAPI } from '../../store/services/ProductsService';
import { useEffect, useState } from 'react';
import { ProductType } from '../../store/storeInterfaces';
import { replaceId } from './helpers/replaceId';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  addProductToCart,
  removeProductFromCart,
  updateProductCount,
} from '../../store/slices/CartSlice';
import CounterButton from '../../components/atoms/CounterButton';
import { Helmet } from 'react-helmet';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import cnBind from 'classnames/bind';

const cx = cnBind.bind(styles);

const GoodPage = () => {
  const { id } = useParams();
  const { data: productReq } = ProductsAPI.useGetProductByIdQuery(id ?? '0');
  const { data: productPhoto } = ProductsAPI.useGetProductPhotoByIdQuery(id ?? '0');
  const { data: productRecomendations } = ProductsAPI.useGetAllRecomendationsProductsQuery(
    id ?? '0'
  );
  const [images, setImages] = useState<string[]>([]);
  const [activeImage, setActiveImage] = useState<number>(0);
  const { isFullHD, isDesktop, isTablet } = useMatchMedia();
  const [product, setProduct] = useState<ProductType>();
  const [productRecomended, setProductRecomended] = useState<ProductType[]>();
  const location = useLocation();
  const [inCart, setInCart] = useState(false);
  const dispatch = useAppDispatch();
  const cartProducts = useAppSelector((state) => state.cart);
  const count = useAppSelector((state) => {
    const product = state.cart.find((product) => product.id?.toString() === id);
    return product ? product.count : 0;
  });
  const allCategories = useAppSelector((state) => state.categories);
  const [currentCategory, setCurrentCategory] = useState<string>('категория');

  const [isCountWight, setCountWight] = useState<number>();

  useEffect(() => {
    if (count) setCountWight(count);
  }, [count]);

  useEffect(() => {
    if (productRecomendations) setProductRecomended(productRecomendations);
  }, [productRecomendations]);

  useEffect(() => {
    if (productPhoto) setImages(productPhoto.map((e) => e.file_link));
  }, [productPhoto]);

  useEffect(() => {
    if (productReq) {
      const category = allCategories.find((c) => c.id === productReq.category);
      if (category) setCurrentCategory(category.title);
      setProduct(productReq);
    }
  }, [productReq, allCategories]);

  useEffect(() => {
    const productInCart = cartProducts.find((product) => product.id?.toString() === id);
    setInCart(!!productInCart);
  }, [cartProducts, id]);

  const handleAddToCart = () => {
    dispatch(
      addProductToCart({ id, count: product?.weight ?? 1, productWight: product?.weight ?? 1 })
    );
    setInCart(true);
  };

  const handleUpdateCount = (newCount: number) => {
    if (newCount <= 0) {
      dispatch(removeProductFromCart(id));
      setInCart(false);
    } else {
      dispatch(updateProductCount({ id, count: newCount }));
    }
  };

  const handleImages = (direction: string) => {
    switch (direction) {
      case 'back':
        setActiveImage(activeImage - 1);
        break;
      case 'forward':
        setActiveImage(activeImage + 1);
        break;
      default:
        setActiveImage(0);
    }
  };

  return (
    <section className={styles.good__wrapper}>
      <Helmet>
        <title>{product?.seo_title}</title>
        <meta name="description" content={product?.seo_description ?? product?.title} />
        <link rel="canonical" href="/catalog" />
      </Helmet>
      <div className="page__shadow" />
      <div id="top" className={cx(styles.good__container, 'container')}>
        <BreadScrumbs
          location={replaceId(location.pathname, product?.title ?? 'название товара')}
        />
        <h2 className={styles.good__title}>{product?.title ?? 'Название товара'}</h2>
        <div className={styles.good__detail}>
          <div className={styles.detail__images}>
            <div className={styles.detail__image_big}>
              {product?.photo && product?.photo?.length > 1 && (
                <span
                  className={cx(styles.image__handler, styles.handler__left, {
                    handler__disabled: activeImage <= 0,
                  })}
                >
                  <ArrowLeft
                    className={styles.handler__arrow}
                    onClick={() => handleImages('back')}
                  />
                </span>
              )}
              <img
                src={images[activeImage] ?? GoodImage}
                alt="Изображение товара"
                className={styles.image__pic}
              />
              {product?.photo && product?.photo?.length > 1 && (
                <span
                  className={cx(styles.image__handler, styles.handler__right, {
                    handler__disabled: activeImage === images.length - 1,
                  })}
                >
                  <ArrowRight
                    className={styles.handler__arrow}
                    onClick={() => handleImages('forward')}
                  />
                </span>
              )}
            </div>
            {(isDesktop || isFullHD) && (
              <ul className={styles.detail__gallery}>
                {product?.photo &&
                  product?.photo?.length > 1 &&
                  product?.photo?.map((image, i) => {
                    if (i <= 3) {
                      return (
                        <li key={i} className={styles.gallery__item}>
                          <img
                            src={image}
                            alt="Другое изображение товара"
                            className={styles.item__image}
                          />
                        </li>
                      );
                    }
                  })}
              </ul>
            )}
            {isTablet && (
              <div className={styles.good__description}>
                <h3 className={styles.description__title}>Описание</h3>
                <p className={styles.description__text}>{product?.description}</p>
              </div>
            )}
          </div>
          <div className={styles.detail__conditions}>
            <div className={styles.conditions__container}>
              <ul className={styles.conditions__content}>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Срок изготовления</h3>
                  <span className={styles.item__text}>
                    {product?.cook_time ?? '25 дней со дня заказа'}
                  </span>
                </li>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Доставка по г. Москва</h3>
                  <span className={styles.item__text}>{`${product?.delivery} рублей`}</span>
                </li>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Температура хранения</h3>
                  <span className={styles.item__text}>
                    {product?.temperature ?? 'От 9 до 20 градусов'}
                  </span>
                </li>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Вес</h3>
                  <span className={styles.item__text}>
                    {`${product?.weight} ${product?.units ?? ''}`}
                  </span>
                </li>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Категория</h3>
                  <span className={styles.item__text}>{currentCategory}</span>
                </li>
                <li className={styles.content__item}>
                  <h3 className={styles.item__title}>Состав</h3>
                  <span className={styles.item__text}>
                    {product?.composition ?? 'Свинина, говядина'}
                  </span>
                </li>
              </ul>

              {inCart ? (
                <CounterButton
                  id={id ?? 1}
                  onCountChange={handleUpdateCount}
                  text={product?.units ?? 'кг'}
                  weight={product?.weight ?? 1}
                />
              ) : (
                <Button
                  hasIcon={true}
                  text={(product?.price && `${product?.price} ₽`) || 'договорная цена'}
                  isPrimary={true}
                  onClick={handleAddToCart}
                  icon={<CartPlus className={styles.icon} />}
                  full={true}
                />
              )}
            </div>
          </div>
        </div>
        {!isTablet && (
          <div className={styles.good__description}>
            <h3 className={styles.description__title}>Описание</h3>
            <p className={styles.description__text}>{product?.description}</p>
          </div>
        )}

        {productRecomended && (
          <CarouselComponent recommended={productRecomended} title="Рекомендуем" />
        )}
      </div>
    </section>
  );
};

export default GoodPage;
