import { ReactComponent as CatalogBtn } from '../../../assets/svg/catalog_btn.svg';
import cnBind from 'classnames/bind';
import styles from './catalog_button.module.css';

type CatalogButtonProps = {
  color: 'black' | 'white';
};

const cx = cnBind.bind(styles);

const CatalogButton = ({ color }: CatalogButtonProps) => {
  return (
    <div className={styles.catalog_btn__wrapper}>
      <CatalogBtn
        className={cx('icon__default', {
          icon__custom: color === 'black',
          icon__custom_w: color === 'white',
        })}
      />
      <span className={cx(styles.catalog_btn__text, { catalog_btn__text_w: color === 'white' })}>
        Каталог
      </span>
    </div>
  );
};

export default CatalogButton;
