import { OrderStatistic } from '../../../store/storeInterfaces';
import styles from './statistics.module.css';

const StatisticsWidget = (data: OrderStatistic) => {
  return (
    <ul className={styles.statistics__row}>
      <li className={styles.row__item}>
        <div className={styles.item__content}>
          <h6 className={styles.item__title}>Всего заказов</h6>
          <span className={styles.item__value}>{data.total_orders}</span>
        </div>
      </li>
      <li className={styles.row__item}>
        <div className={styles.item__content}>
          <h6 className={styles.item__title}>Прибыль месяц</h6>
          <span className={styles.item__value}>{`${data.total_price} ₽`}</span>
        </div>
      </li>
      <li className={styles.row__item}>
        <div className={styles.item__content}>
          <h6 className={styles.item__title}>Заказов за месяц</h6>
          <span className={styles.item__value}>{data.orders_per_month}</span>
        </div>
      </li>
      <li className={styles.row__item}>
        <div className={styles.item__content}>
          <h6 className={styles.item__title}>Всего покупателей</h6>
          <span className={styles.item__value}>{data.total_users}</span>
        </div>
      </li>
    </ul>
  );
};

export default StatisticsWidget;
