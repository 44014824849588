import HeaderTop from '../HeaderTop';
import Header from '../Header';
import Footer from '../Footer';
import Curtain from '../Curtain';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import styles from './layout.module.css';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useMobileMenu } from '../../hooks/useMobileMenu';
import { IError, ProductType } from '../../store/storeInterfaces';
import { LoadingScreen } from '../atoms/loading';
import { ServerError } from '../atoms/ServerError/server-error';
import { AuthorizationUserAPI } from '../../store/services/UserService';
import { ProductsAPI } from '../../store/services/ProductsService';
import { setTotalInHeader, setTotalWight } from '../../store/slices/GeneralConditionsSlice';
import { ModalReLogin } from '../atoms/ServerError/modalRelogin';

const Layout = () => {
  const { curtainOpen, toggleMenu } = useMobileMenu();
  const [trigger] = AuthorizationUserAPI.useLazyGetUserQuery();
  // const [admin] = AuthorizationUserAPI.useAdminMutation();
  const location = useLocation();
  ProductsAPI.useGetAllProductsQuery({});
  ProductsAPI.useGetCategoriesQuery();
  const { token } = useAppSelector((state) => state.authorization);

  const [rejectedEndpointName, setRejectedEndpointName] = useState<string | undefined>(undefined);
  const [isModalReLogin, setModalReLogin] = useState(false);
  const queries = useAppSelector((state) => state.api.queries);
  const mutations = useAppSelector((state) => state.api.mutations);
  const isSomeQueryPending = Object.values(queries).some((query) => query?.status === 'pending');
  const navigate = useNavigate();
  const isSomeMutationPending = Object.values(mutations).some(
    (query) => query?.status === 'pending'
  );
  const cartProducts = useAppSelector((state) => state.cart);
  const products = useAppSelector((state) => state.products);
  const dispatch = useAppDispatch();
  const getProductById = (productId: string | number) => {
    return products.find((product: ProductType) => product.id === +productId);
  };
  const exceptionQueries = Object.values(queries).find(
    (querie) => querie?.status === 'pending'
  )?.endpointName;
  ScrollToTop();
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  // function ScrollToTop() {
  //   const { pathname } = useLocation();

  //   useEffect(() => {
  //     const scrollToTop = () => {
  //       const c = document.documentElement.scrollTop || document.body.scrollTop;
  //       if (c > 0) {
  //         window.requestAnimationFrame(scrollToTop);
  //         window.scrollTo(0, c - c / 7);
  //       }
  //     };

  //     scrollToTop();
  //   }, [pathname]);

  //   return null;
  // }

  useEffect(() => {
    const totalPriceS = cartProducts.reduce((total, item) => {
      const product = getProductById(item.id ?? 0);
      if (product && item.count && product.weight && product.price) {
        const productPrice = product.price * (item.count / product.weight || 1);
        dispatch(setTotalInHeader(total + productPrice));
        return total + productPrice;
      }

      return total;
    }, 0);

    dispatch(setTotalInHeader(totalPriceS));
    localStorage.setItem('totalKazatskaya', totalPriceS.toString());

    const totalWightS = cartProducts.reduce((total, item) => {
      const product = getProductById(item.id ?? 0);
      if (product && item.count) {
        let productWight = item.count;
        if (item.count && product.units === 'г') {
          productWight = item.count / 1000;
        }
        if (product.weight && item.count && product.units === 'шт') {
          productWight = item.count * product.weight;
        }
        return total + productWight;
      }
      return total;
    }, 0);
    dispatch(setTotalWight(totalWightS));
    localStorage.setItem('totalWightKazatskaya', totalPriceS.toString());
  }, [cartProducts, dispatch, getProductById]);

  useEffect(() => {
    if (token !== '') trigger();
    // admin().unwrap;
  }, [token, trigger]);

  useEffect(() => {
    const rejectedQuery = Object.values(queries).find((query) => query?.status === 'rejected');
    const serverError = rejectedQuery?.error as IError;
    if (rejectedQuery && rejectedQuery.endpointName) {
      if (
        (rejectedQuery?.endpointName === 'getUser' && rejectedQuery, serverError.status === 401)
      ) {
        localStorage.removeItem('tokenKazatskaya');
        localStorage.removeItem('isSuperUserKaz');
        navigate('/', { state: { scrollToTop: true } });
        // setModalReLogin(true);
      } else setModalReLogin(false);
    }
  }, [navigate, queries]);

  useEffect(() => {
    const rejectedMutation = Object.values(mutations).find(
      (mutation) => mutation?.status === 'rejected'
    );

    const serverError = rejectedMutation?.error as IError;
    if (rejectedMutation && rejectedMutation.endpointName) {
      if (serverError?.data?.detail && typeof serverError?.data?.detail === 'string') {
        setRejectedEndpointName(serverError.data.detail);
      } else setRejectedEndpointName('Ой, что-то пошло не так');
    } else {
      setRejectedEndpointName(undefined);
    }
  }, [mutations]);

  return (
    <div className={styles.layout__wrapper}>
      {isModalReLogin && <ModalReLogin close={() => setModalReLogin(false)} />}
      {isSomeQueryPending && exceptionQueries !== 'check' && <LoadingScreen />}
      {isSomeMutationPending && <LoadingScreen />}
      {rejectedEndpointName && (
        <ServerError
          message={rejectedEndpointName}
          close={() => setRejectedEndpointName(undefined)}
        />
      )}
      <div className={styles.layout__container}>
        {curtainOpen && <Curtain close={toggleMenu} />}
        <HeaderTop />
        <Header burgerFunc={toggleMenu} mobMenu={curtainOpen} />
        {curtainOpen ? (
          <div className={styles.content__blocked} />
        ) : (
          <>
            <Outlet />
            <Footer location={location.pathname} />
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;
