import { useMatchMedia } from '../../hooks/useMatchMedia';
import styles from './footer.module.css';
import cnBind from 'classnames/bind';
import OxyLogo from '../../assets/img/oxy_logo.png';
import MobileOxyLogo from '../../assets/img/mobile_oxy_logo.png';
import TelegramIcon from '../../assets/icons/telegram_icon.png';
import InstagramIcon from '../../assets/icons/instagram_icon.png';
import WhatsappIcon from '../../assets/icons/whatsapp_icon.png';
import VKIcon from '../../assets/icons/vk_icon.png';
import { PHONE_NUMBER, FORM_LOCATIONS } from '../../models/constants';

type FooterProps = {
  location: string;
};

const cx = cnBind.bind(styles);

const Footer = ({ location }: FooterProps) => {
  const { isMobile } = useMatchMedia();

  return (
    <footer
      className={cx(styles.footer, {
        footer__blocked: FORM_LOCATIONS.includes(location) && isMobile,
      })}
    >
      <div className={styles.footer__top} />
      <div className={styles.footer__bottom}>
        <div className={cx(styles.bottom__container, 'container')}>
          <div className={styles.bottom__top}>
            <div className={styles.top__main}>
              <span className={styles.main__top}>г. Москва</span>
              <a href={`tel:${PHONE_NUMBER}`} className={styles.main__bottom}>
                {PHONE_NUMBER}
              </a>
            </div>
            <div className={styles.top__info}>
              <span>ИНН: 480703405080</span>
              <span>ИП Оноприенко Валерий Валериевич</span>
            </div>
            <div className={styles.top__socials}>
              <a href="https://t.me/kazatskayaizba" target="_blank" rel="noreferrer">
                <img src={TelegramIcon} alt="Ссылка на нашу группу в Telegram" />
              </a>
              <a
                href="https://instagram.com/kazatskayaizba?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="Ссылка на нашу группу в Instagram" />
              </a>
              <a href="https://wa.me/79257549600" target="_blank" rel="noreferrer">
                <img src={WhatsappIcon} alt="Ссылка на нашу группу в Whatsapp" />
              </a>
              <a href="https://vk.com/id817622373" target="_blank" rel="noreferrer">
                <img src={VKIcon} alt="Ссылка на нашу группу ВКонтакте" />
              </a>
            </div>
          </div>
          <div className={styles.bottom__logo}>
            <a href="https://oxy-group.ru/" target="_blank" rel="noopener noreferrer">
              <img src={isMobile ? MobileOxyLogo : OxyLogo} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
