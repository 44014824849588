import Logo from '../../UIKit/Logo';

import cn from 'classnames';
import styles from './d-header.module.css';
import { useNavigate } from 'react-router-dom';
import { removeUser, useAppDispatch } from '../../../store';
import { removeAuthorization } from '../../../store/slices/AuthorizationSlice';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSignOut = () => {
    navigate('/', { state: { scrollToTop: true } });
    dispatch(removeUser());
    dispatch(removeAuthorization());
    localStorage.removeItem('totalKazatskaya');
    localStorage.removeItem('cart');
    localStorage.removeItem('tokenKazatskaya');
    localStorage.removeItem('isSuperUserKaz');
  };

  return (
    <header className={styles.dashboard__header}>
      <div className={cn(styles.header__container, 'container')}>
        <Logo />

        <div className={styles.header__button}>
          <button onClick={handleSignOut}>Выход</button>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
