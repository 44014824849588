import styles from './userinfo.module.css';
import cn from 'classnames';
import { ReactComponent as ChiefIcon } from '../../assets/icons/chief_cap.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart_icon_default.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { useState, useEffect } from 'react';
import { ProductType } from '../../store/storeInterfaces';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { HashLink } from 'react-router-hash-link';

type UserInfoProps = {
  nameVisible: boolean;
};

const UserInfo = ({ nameVisible }: UserInfoProps) => {
  const { token } = useAppSelector((state) => state.authorization);
  const { name } = useAppSelector((state) => state.user);
  const { total } = useAppSelector((state) => state.generalConditions);
  const cartProducts = useAppSelector((state) => state.cart);
  const [totalPrice, setTotalPrice] = useState(0);
  const products = useAppSelector((state) => state.products);
  const { isMobile } = useMatchMedia();
  const getProductById = (productId: string | number) => {
    return products.find((product: ProductType) => product.id === +productId);
  };
  useEffect(() => {
    if (total) setTotalPrice(total);
    if (total === 0) setTotalPrice(0);
  }, [total]);

  return (
    <div className={styles.userinfo__wrapper}>
      <div className={styles.userinfo__data}>
        {token ? (
          <HashLink smooth to="/profile">
            <div className={styles.user}>
              {!nameVisible && <span className={styles.user__name}>{name}</span>}
              <ChiefIcon className={cn('icon__default', styles.icon__custom)} />
            </div>
          </HashLink>
        ) : (
          <HashLink smooth to="/signup">
            <div className={styles.guest__section}>
              {!nameVisible && <span className={styles.guest__text}>Регистрация</span>}
              <ChiefIcon className={cn('icon__default', styles.icon__custom)} />
            </div>
          </HashLink>
        )}
        <HashLink smooth to="catalog/cart">
          <div className={styles.cart__info}>
            <CartIcon className={cn('icon__default', styles.icon__custom)} />
            <div className={styles.cart__text}>
              {totalPrice ? (
                <>
                  <span>{totalPrice.toFixed(2)}</span>
                  <span>&#8381;</span>
                </>
              ) : (
                <span>Корзина пуста</span>
              )}
            </div>
          </div>
        </HashLink>
      </div>
    </div>
  );

  // return (
  //   <div className={styles.userinfo__wrapper}>
  //     {token ? (
  //       <>
  //         {!isMobile && (
  //           <Link to="/profile">
  //             <div className={styles.userinfo__data}>
  //               <div className={styles.user}>
  //                 <span className={styles.user__name}>{name ?? 'покупатель'}</span>
  //                 <ChiefIcon />
  //               </div>
  //             </div>
  //           </Link>
  //         )}

  //         <Link to="catalog/cart">
  //           <div className={styles.cart__info}>
  //             <CartIcon />
  //             <p className={styles.cart__text}>
  //               <span>{totalPrice.toFixed(2)}</span>
  //               <span>&#8381;</span>
  //             </p>
  //           </div>
  //         </Link>

  //         <Link to="/profile">
  //           <div className={styles.userinfo__data}>
  //             <div className={styles.user}>
  //               <span className={styles.user__name}>{name ?? 'покупатель'}</span>
  //               <ChiefIcon />
  //             </div>
  //           </div>
  //         </Link>
  //       </>
  //     ) : (
  //       <Link to={'/signup'}>
  //         <div className={styles.guest__section}>
  //           <span className={styles.guest__text}>Гость</span>
  //           <div className={styles.guest__icon}>
  //             <GuestIcon className={styles.guest__image} />
  //           </div>
  //         </div>
  //       </Link>
  //     )}
  //     <Link to="catalog/cart">
  //       <div className={styles.cart__info}>
  //         <CartIcon />
  //         {/* {totalPrice !== 0 && (
  //           <p className={styles.cart__text}>
  //             <span>{totalPrice.toFixed(2)}</span>
  //             <span>&#8381;</span>
  //           </p>
  //         )} */}
  //         <p className={styles.cart__text}>
  //           {totalPrice !== 0 ? (
  //             <span>
  //               {totalPrice.toFixed(2)}
  //               <span>&#8381;</span>
  //             </span>
  //           ) : (
  //             <span>Корзина пуста</span>
  //           )}
  //         </p>
  //       </div>
  //     </Link>
  //   </div>
  // //);
};

export default UserInfo;
