import styles from './HeaderTop.module.css';
import { PHONE_NUMBER } from '../../models/constants';

import cn from 'classnames';
import { useUserLocation } from '../../hooks/useUserLocation';

const HeaderTop = () => {
  const { userLocation } = useUserLocation();

  return (
    <div className={styles.headertop__wrapper}>
      <div className={cn(styles.headertop__content, 'container')}>
        <div className={styles.headertop__element}>{userLocation?.cityName ?? 'Москва'}</div>
        <div className={styles.headertop__element}>
          <a href={`tel: ${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
