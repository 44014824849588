/* eslint-disable prettier/prettier */
import styles from './profile.module.css';
import cn from 'classnames';
import BreadScrumbs from '../../components/BreadScrumbs';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/Button';
import Order from '../../components/Order';
import { useForm, FieldValues } from 'react-hook-form';
import { removeUser, setUserName, setUserPhone, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import { AuthorizationUserAPI } from '../../store/services/UserService';
import { removeAuthorization } from '../../store/slices/AuthorizationSlice';
import exitPic from '../../assets/img/exit.png';
import { ProductsAPI } from '../../store/services/ProductsService';
import { v4 } from 'uuid';
import { OrdersGetRequest } from '../../store/storeInterfaces';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import InfoPopupProf from '../../components/atoms/InfoPopup/indexProf';
import { Helmet } from 'react-helmet';
import { removeCart } from '../../store/slices/CartSlice';

const Profile = () => {
  const { token } = useAppSelector((state) => state.authorization);
  const navigate = useNavigate();
  ProductsAPI.useOrdersGetQuery();
  const { ordersfromServer } = useAppSelector((state) => state.generalConditions);
  const { isDesktop, isFullHD } = useMatchMedia();
  const user = useAppSelector((state) => state.user);
  const location = useLocation();
  const [patchUserMe, { isSuccess }] = AuthorizationUserAPI.usePatchUserMeMutation();
  const [setPassword, { isSuccess: isSuccessPass }] = AuthorizationUserAPI.useSetPasswordMutation();
  const dispatch = useAppDispatch();
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordNewRepeat, setPasswordNewRepeat] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [isOrders, setOrders] = useState<OrdersGetRequest[]>();
  const [isOk, setOk] = useState(false);
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    if (user.name) setNameInput(user.name);
    if (user.phone) setPhoneInput(user.phone);
    if (user.email) setEmailInput(user.email);
  }, [user.email, user.name, user.phone]);

  useEffect(() => {
    if (isSuccess || isSuccessPass) setOk(true);
  }, [isSuccess, isSuccessPass]);

  useEffect(() => {
    if (ordersfromServer && ordersfromServer.length > 0) setOrders(ordersfromServer);
  }, [ordersfromServer]);

  useEffect(() => {
    if (!token) navigate('/');
  }, [token, navigate]);

  const onSubmit = async (data: FieldValues) => {
    const formData = {
      name: data.name || undefined,
      phone: data.phone || undefined,
    };
    patchUserMe(formData).unwrap();
    dispatch(setUserName(data.name));
    dispatch(setUserPhone(data.phone));
  };

  const onSubmitPassword = async (data: FieldValues) => {
    if (data.passwordNew !== data.passwordNewRepeat) {
      setError('passwordNew', { message: 'пароли не совпадают' });
      setError('passwordNewRepeat', { message: 'пароли не совпадают' });
      return;
    }
    const formDataPasswords = {
      current_password: data.passwordOld,
      new_password: data.passwordNew,
      re_new_password: data.passwordNewRepeat,
    };
    setPassword(formDataPasswords).unwrap();
  };

  const handleSignOut = () => {
    navigate('/', { state: { scrollToTop: true } });
    dispatch(removeUser());
    dispatch(removeCart());
    dispatch(removeAuthorization());
    localStorage.removeItem('totalKazatskaya');
    localStorage.removeItem('cart');
    localStorage.removeItem('tokenKazatskaya');
    localStorage.removeItem('isSuperUserKaz');
  };

  return (
    <section className={styles.profile__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      {isOk && <InfoPopupProf close={() => setOk(false)} />}
      <div className={styles.page__shadow} />
      <div className={cn(styles.profile__container, 'container')}>
        <BreadScrumbs location={location.pathname} />
        <h2 className={styles.profile__title}>Личный кабинет</h2>
        <div className={styles.profile__personal}>
          {(isDesktop || isFullHD) && (
            <div className={styles.exit__section}>
              <img alt="exit icon" src={exitPic} className={styles.img__exit} />
              <button onClick={handleSignOut} className={styles.repeat__button}>
                <span className={styles.repeat__text}>Выйти из профиля</span>
              </button>
            </div>
          )}

          <div className={styles.form__section}>
            <form className={styles.personal__section} onSubmit={handleSubmit(onSubmit)}>
              <h3 className={styles.section__title}>Контактные данные</h3>
              <input
                type="text"
                {...register('name')}
                placeholder="Имя"
                className={styles.section__input}
                onChange={(evt) => setNameInput(evt.target.value)}
                value={nameInput}
              />
              <input
                type="email"
                value={emailInput}
                placeholder="E-mail"
                className={styles.section__input}
                onChange={() => { }}
                style={{ pointerEvents: 'none', color: 'grey' }}
              />
              <input
                type="tel"
                {...register('phone')}
                placeholder="Телефон"
                className={styles.section__input}
                onChange={(evt) => setPhoneInput(evt.target.value)}
                value={phoneInput}
              />
              {/* {(isTablet || isMobile) && (
                <Button isPrimary={true} text="Сохранить" hasIcon={false} onClick={() => {}} />
              )} */}
              <div className={styles.button__right}>
                <Button isPrimary={true} text="Сохранить" hasIcon={false} onClick={() => { }} />
              </div>
            </form>
            <form className={styles.personal__section} onSubmit={handleSubmit(onSubmitPassword)}>
              <h3 className={styles.section__title}>Безопасность</h3>
              <input
                type="password"
                placeholder="Введите текущий пароль"
                className={styles.section__input}
                {...register('passwordOld')}
                onChange={(evt) => setPasswordOld(evt.target.value)}
                value={passwordOld}
              />
              <input
                type="password"
                placeholder="Введите новый пароль"
                className={styles.section__input}
                {...register('passwordNew')}
                onChange={(evt) => setPasswordNew(evt.target.value)}
                value={passwordNew}
              />
              <input
                type="password"
                placeholder="Повторите новый пароль"
                className={styles.section__input}
                {...register('passwordNewRepeat')}
                onChange={(evt) => setPasswordNewRepeat(evt.target.value)}
                value={passwordNewRepeat}
              />
              <div className={styles.button__right}>
                <Button
                  isPrimary={true}
                  text="Изменить пароль"
                  hasIcon={false}
                  onClick={() => { }}
                />
              </div>
            </form>
          </div>
        </div>

        {isOrders && (
          <div className={styles.orders__timeline}>
            <h3 className={cn(styles.section__title, styles.timeline__title)}>История заказов</h3>
            <ul className={styles.timeline__list}>
              {isOrders.map((product) => (
                <Order key={v4()} order={product} />
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Profile;
