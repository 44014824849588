import styles from './cleanup-popup.module.css';
import cn from 'classnames';

type CleanupPopupProps = {
  handleClear: () => void;
  handleCancel: () => void;
};

const CleanupPopup = ({ handleClear, handleCancel }: CleanupPopupProps) => {
  return (
    <div className={styles.popup__wrapper}>
      <span className={styles.popup__title}>Очистить корзину?</span>
      <span className={styles.popup__text}>Все товары будут удалены.</span>
      <div className={styles.popup__buttons}>
        <button className={styles.popup__button} onClick={handleCancel}>
          <span className={styles.popup__text}>Отмена</span>
        </button>
        <button className={cn(styles.popup__button, styles.button__confirm)} onClick={handleClear}>
          <span className={styles.popup__text}>Очистить</span>
        </button>
      </div>
    </div>
  );
};

export default CleanupPopup;
