import styles from './cart-viewer.module.css';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Button from '../atoms/Button';
import CartItem from '../CartItem';
import GoodImage from '../../assets/img/good_image.png';
import emptyCartIco from '../../assets/img/Mask group.png';
import { useAppDispatch, useAppSelector } from '../../store';
import { ProductType, SoppingCart } from '../../store/storeInterfaces';
import { v4 } from 'uuid';
import { removeCart } from '../../store/slices/CartSlice';
import { setTotalInHeader, setTotalWight } from '../../store/slices/GeneralConditionsSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ProductsAPI } from '../../store/services/ProductsService';
import { AuthorizationUserAPI } from '../../store/services/UserService';
import CleanupPopup from '../atoms/CleanupPopup';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const CartViewer = () => {
  const [shoppingCartPost, { isSuccess }] = ProductsAPI.useShoppingCartPostMutation();
  const user = useAppSelector((state) => state.user);
  const [triggerGetGuestId] = AuthorizationUserAPI.useLazyGetGuestIdQuery();
  const cartProducts = useAppSelector((state) => state.cart);
  const products = useAppSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getProductById = (productId: string | number) => {
    return products.find((product: ProductType) => product.id === +productId);
  };

  const { isMobile } = useMatchMedia();

  const { total, totalWight: totalWightW } = useAppSelector((state) => state.generalConditions);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWight, setTotalWightState] = useState(0);
  const { token } = useAppSelector((state) => state.authorization);
  const [emptyCart, setEmptyCart] = useState(true);
  const [removeCartWindow, setRemoveCartWindow] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (cartProducts.length < 1) setEmptyCart(true);
    else setEmptyCart(false);
  }, [cartProducts]);

  useEffect(() => {
    if (total) setTotalPrice(total);
    if (totalWightW) setTotalWightState(totalWightW);
  }, [total, totalWightW]);

  useEffect(() => {
    if (!token) triggerGetGuestId();
  }, [token, triggerGetGuestId]);

  useEffect(() => {
    if (user.id) setUserId(user.id);
  }, [user.id]);

  useEffect(() => {
    if (isSuccess) navigate('/ordering', { state: { scrollToTop: true } });
  }, [isSuccess, navigate]);

  const handleRemoveCart = () => {
    setRemoveCartWindow(true);
  };

  const handleClickCancel = () => {
    setRemoveCartWindow(false);
  };

  const handleRemoveCartTrue = () => {
    dispatch(removeCart());
    setTotalPrice(0);
    setTotalWightState(0);
    setRemoveCartWindow(false);
  };

  const handleClick = () => {
    const cartData: SoppingCart[] = cartProducts.map((cartProduct) => {
      return {
        product: Number(cartProduct.id),
        user_id: userId,
        amount: cartProduct.count,
      };
    });

    shoppingCartPost(cartData).unwrap();
  };

  return (
    <div className={styles.viewer__wrapper}>
      <div className={styles.viewer__container}>
        <div className={styles.viewer__header}>
          <p className={styles.header__text}>Ваши товары</p>
          <button className={styles.header__right} onClick={handleRemoveCart}>
            <span className={styles.right__text}>Очистить все</span>
            <TrashIcon className={styles.right__icon} />
          </button>
          {removeCartWindow && (
            <CleanupPopup handleClear={handleRemoveCartTrue} handleCancel={handleClickCancel} />
          )}
        </div>
        <div className={styles.viewer__content}>
          {emptyCart ? (
            <div className={styles.viewer__empty}>
              <img src={emptyCartIco} alt="иконка для пустой корзины" />
              <p className={styles.empty__text}>Пока что здесь {isMobile && <br />}нет товаров</p>
            </div>
          ) : (
            <ul className={styles.cart__list}>
              {cartProducts.map((item) => {
                const product = getProductById(item.id ?? 0);
                if (product) {
                  return (
                    <CartItem
                      key={v4()}
                      count={item.count && product.weight ? item.count / product.weight : 1}
                      id={product.id ?? 0}
                      title={product.title ?? ''}
                      image={product.photo?.[0] ?? GoodImage}
                      amount={product.weight ?? 1}
                      unit={product.units ?? 'кг'}
                      price={product.price ?? 0}
                    />
                  );
                }
                return null;
              })}
            </ul>
          )}

          <div className={styles.cart__total}>
            <div className={styles.total__top}>
              <div className={styles.top__header}>
                <span className={styles.header__title}>Сумма</span>
                <p className={styles.header__sum}>{`${totalPrice.toFixed(2)} ₽`}</p>
              </div>
              <div className={styles.top__footer}>
                <span className={styles.footer__title}>Всего товаров</span>
                <p className={styles.footer__sum}>
                  {cartProducts.length} <span>шт</span>
                </p>
              </div>
              <div className={styles.top__footer}>
                <span className={styles.footer__title}>Общий вес</span>
                <p className={styles.footer__sum}>
                  {totalWight.toFixed(2)} <span>кг</span>
                </p>
              </div>
            </div>
            <div className={styles.total__divider} />
            <div className={styles.total__bottom}>
              {token ? (
                <Button
                  hasIcon={false}
                  text="Оформить заказ"
                  disabled={emptyCart}
                  isPrimary
                  onClick={handleClick}
                  full
                />
              ) : (
                <>
                  {' '}
                  <Button
                    hasIcon={false}
                    text="Регистрация"
                    isPrimary
                    onClick={() => navigate('/signup', { state: { scrollToTop: true } })}
                    full
                  />
                  <button
                    disabled={emptyCart}
                    onClick={handleClick}
                    className={styles.repeat__button}
                  >
                    <span className={styles.repeat__text}>Продолжить как гость</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartViewer;
