import { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import RegGoodsItem from '../RegGoodsItem';
import styles from './recomenations.module.css';
import { ProductType } from '../../../store/storeInterfaces';

type RecomendationsGoodsProps = {
  recomendationsList: string[];
  allProducts: ProductType[];
  removeFromCurrentRecomends: (ind: number) => void;
  addCurrentRecomend: () => void;
  onValueChange: (arg1: string, arg2: number) => void;
};

const RecomendationsGoods = ({
  allProducts,
  recomendationsList,
  removeFromCurrentRecomends,
  addCurrentRecomend,
  onValueChange,
}: RecomendationsGoodsProps) => {
  const [allTitles, setAllTitles] = useState<string[]>([]);

  useEffect(() => {
    const titles = allProducts.map((el) => el.title || 'Наименование');
    setAllTitles(titles);
  }, [allProducts]);

  return (
    <ul className={styles.recomendations__wrapper}>
      {recomendationsList.map((el, i) => (
        <li key={v4()} className={styles.recomendations__element}>
          <RegGoodsItem
            allTitles={allTitles}
            defaultValue={el}
            onCloseClick={removeFromCurrentRecomends}
            onAddClick={addCurrentRecomend}
            index={i}
            currentRecomendationsLength={recomendationsList.length}
            onValueChange={onValueChange}
          />
        </li>
      ))}
    </ul>
  );
};

export default RecomendationsGoods;
