import { useMatchMedia } from '../../../hooks/useMatchMedia';
import styles from './smoke.module.css';
import SmokeMob from '../../../assets/img1/smoke_mob.png';
import SmokeTab from '../../../assets/img1/smoke_tab.png';
import SmokeDesk from '../../../assets/img1/smoke_desk.png';

const Smoke = () => {
  const { isMobile, isDesktop, isTablet, isFullHD } = useMatchMedia();

  return (
    <div className={styles.smoke}>
      {isMobile && <img src={SmokeMob} alt="Дым на заднем фоне" />}
      {isTablet && <img src={SmokeTab} alt="Дым на заднем фоне" />}
      {(isDesktop || isFullHD) && <img src={SmokeDesk} alt="Дым на заднем фоне" />}
    </div>
  );
};

export default Smoke;
