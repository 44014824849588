import styles from './repeat-oreder.module.css';

type RepeatOrderPopupProps = {
  close: () => void;
};

const RepeatOrderPopup = ({ close }: RepeatOrderPopupProps) => {
  return (
    <div className={styles.popup__wrapper}>
      <span className={styles.popup__title}>
        Товары добавлены
        <br />в корзину
      </span>
      <span className={styles.popup__text}>
        Оформите заказ <br />
        или добавьте товары
      </span>
      <button className={styles.popup__button} onClick={() => close()}>
        Подтвердить
      </button>
    </div>
  );
};

export default RepeatOrderPopup;
