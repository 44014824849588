import FileIcon from '../../../assets/img1/file_icon.png';
import VideoFileIcon from '../../../assets/img1/video_file_icon.png';
import { ReactComponent as SuccessIcon } from '../../../assets/svg/correct_icon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/error_icon.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/svg/close_cross.svg';
import cnBind from 'classnames/bind';
import styles from './file.module.css';

type FileListItemProps = {
  fileFormat?: string;
  fileTitle: string;
  successText?: string;
  errorText?: string;
  hasError?: boolean;
  fileId: string;
  onRemoveClick: (arg1: string) => void;
};

const cx = cnBind.bind(styles);

const FileListItem = ({
  fileTitle,
  errorText,
  hasError,
  fileFormat,
  fileId = '0',
  onRemoveClick,
}: FileListItemProps) => {
  return (
    <div className={styles.file__wrapper}>
      <div className={styles.wrapper__left}>
        {fileFormat === 'jpeg' ? (
          <img src={FileIcon} alt="Схематическое изображение jpeg файла" />
        ) : (
          <img src={VideoFileIcon} alt="Схематическое изображение mp4 файла" />
        )}

        <span className={styles.file__title}>{fileTitle}</span>
      </div>
      <div className={styles.wrapper__right}>
        <div className={styles.file__status}>
          <span className={cx(styles.status__text, { text__error: hasError })}>
            {hasError ? errorText : 'Файл загружен'}
          </span>
          {hasError ? <ErrorIcon className={styles.error__icon} /> : <SuccessIcon />}
        </div>
        <RemoveIcon
          className={cx('icon__default', styles.icon__custom)}
          onClick={() => onRemoveClick(fileId)}
          title="Удалить файл из загрузки"
        />
      </div>
    </div>
  );
};

export default FileListItem;
