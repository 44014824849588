import CatalogButton from '../UIKit/CatalogButton';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseBtn } from '../../assets/svg/rotate-cross.svg';
import { ReactComponent as ChiefIcon } from '../../assets/svg/chief_icon_big.svg';
import { useAppSelector } from '../../store';
import cnBind from 'classnames/bind';
import styles from './curtain.module.css';

type CurtainProps = {
  close: () => void;
};

const cx = cnBind.bind(styles);

const Curtain = ({ close }: CurtainProps) => {
  const navigate = useNavigate();

  const { token } = useAppSelector((state) => state.authorization);

  const redirectToCatalog = () => {
    close();
    navigate('/catalog', { state: { scrollToTop: true } });
  };

  const redirectToMain = () => {
    close();
    navigate('/', { state: { scrollToTop: true } });
  };

  const toggleRedirection = () => {
    close();
    if (token) {
      navigate('/profile', { state: { scrollToTop: true } });
    } else {
      navigate('/signup', { state: { scrolToTop: true } });
    }
  };

  return (
    <div className={cx(styles.curtain__wrapper)}>
      <div className={styles.curtain__content}>
        <div className={styles.content__top}>
          <CloseBtn className={styles.icon__custom} onClick={() => close()} />
          <div className={styles.top__circle} onClick={toggleRedirection}>
            <ChiefIcon />
          </div>
          <span className={styles.top__text}>{token ? 'Личный кабинет' : 'Регистрация'}</span>
        </div>
        <div className={styles.content__bottom}>
          <div className={styles.catalog__link} onClick={redirectToCatalog}>
            <CatalogButton color="white" />
          </div>
          <div className={styles.link__text} onClick={redirectToMain}>
            На главную
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curtain;
