import { ReactComponent as DeleteButton } from '../../../assets/svg/error_icon.svg';
import styles from './photo-list-item.module.css';

type PhotoListItemsProps = {
  link: string;
  id: string | number;
  onRemoveClick: (id: string | number) => void;
};

const DragPhotoItem = ({ link, id, onRemoveClick }: PhotoListItemsProps) => {
  return (
    <li className={styles.list__item}>
      <img src={link} alt="" />
      <DeleteButton
        title="Удалить фото из галлереи"
        className={styles.delete__icon}
        onClick={() => onRemoveClick(id)}
      />
    </li>
  );
};

export default DragPhotoItem;
