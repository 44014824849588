import { useNavigate } from 'react-router-dom';
import StatusTag from '../../UIKit/StatusTag';
import styles from './orders-item.module.css';

type OrdersItemProps = {
  order_number: number | string;
  customer_name: string;
  phone: string;
  order_sum: number;
  order_date: string;
  deliveryStatus: string;
  paymentStatus: boolean;
};

const OrdersItem = ({
  order_number,
  customer_name,
  phone,
  order_sum,
  order_date,
  deliveryStatus,
  paymentStatus,
}: OrdersItemProps) => {
  const navigate = useNavigate();

  return (
    <li
      className={styles.order_item__wrapper}
      onClick={() => navigate(`orders/${order_number}`, { state: { scrollToTop: true } })}
    >
      <div className={styles.element__left}>
        <span className={styles.item__elem}>{order_number}</span>
        <span className={styles.item__elem}>{customer_name}</span>
        <span className={styles.item__elem}>{phone}</span>
        <span className={styles.item__elem}>
          {order_sum}
          <span>₽</span>
        </span>
        <span className={styles.item__elem}>{order_date}</span>
      </div>
      <div className={styles.status__column}>
        <StatusTag text={deliveryStatus} />
        <StatusTag text={paymentStatus ? 'Оплачен' : ''} />
      </div>
    </li>
  );
};

export default OrdersItem;
