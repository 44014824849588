import styles from './d-content.module.css';
import cn from 'classnames';
// import { Drag2 } from '../../components/Dashboard copy/DragNDrop/Drrag2';
import ExpDrag from '../../components/Dashboard copy/ExpDrag';
import { AdminAPI } from '../../store/services/AdminrService';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const DashboardContentHandle = () => {
  const [triggerGetVideo, { data: videos }] = AdminAPI.useLazyGetVideoQuery();
  const [triggerGetPhoto, { data: photos }] = AdminAPI.useLazyGetPhotoGalleryQuery();

  useEffect(() => {
    triggerGetVideo();
    triggerGetPhoto();
  }, []);

  return (
    <section className={styles.content__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <form className={cn(styles.content__container, 'container')}>
        <div className={styles.content__section}>
          <h4 className={styles.section__title}>Галерея видеофайлов</h4>
          <div className={styles.section__content}>
            <ExpDrag
              title="Загрузите или перетащите видеофайлы"
              subtitle="Необходимо загрузить 6 видеофайлов. Формат mp4"
              accept="mp4"
              existData={videos}
              dropId="videos"
              multiple={false}
            />
          </div>
        </div>
        <div className={styles.content__section}>
          <h4 className={styles.section__title}>Галерея фотографий</h4>
          <div className={styles.section__content}>
            <ExpDrag
              title="Загрузите или перетащите фотографии"
              subtitle="Рекомендуется больше 3-х фотографий"
              accept="jpeg"
              existData={photos}
              dropId="photos"
              multiple={false}
            />
          </div>
        </div>
      </form>
    </section>
  );
};

export default DashboardContentHandle;

{
  /* <div className={styles.content__section}>
          <div className={styles.section__top}>
            <h4 className={styles.section__title}>Видео на главной</h4>
            <DashboardButton text="Сохранить" />
          </div>
          <div className={styles.section__content}>
            <DragNDrop
              
              setFile={() => { }}
              requiredFormat="mp4"
              subtitle="Добавить можно только 1 видео. Формат mp4"
              id="main-video"
              main={true}
            />
          </div>
        </div>
        <div className={styles.content__section}>
          <h4 className={styles.section__title}>Галерея видеофайлов</h4>
          <div className={styles.section__content}>
            <DragNDrop
              
              setFile={() => { }}
              requiredFormat="mp4"
              subtitle="Необходимо загрузить 6 видеофайлов. Формат mp4"
              id="video-gallery"
              main={false}
            />
          </div>
        </div>
        <div className={styles.content__section}>
          <h4 className={styles.section__title}>Галерея фотографий</h4>
          <div className={styles.section__content}>
            <Drag2
              subtitle="Рекомендуется больше 3-х фотографий"
              requiredFormat="jpeg"
              id={'photoMain'}
              idProd={undefined}
              newProd={false}
              isGallery={true}
            />
          </div>
        </div>  */
}
