import { useState, useEffect } from 'react';
import { MAP_STATE } from '../models/constants';

interface MapConfig {
  center: number[];
  zoom: number;
}

export const useMapConfig = () => {
  const [mapConfig, setConfig] = useState<MapConfig>();

  const getClientWidth = (clientWidth: number) => {
    if (clientWidth > 300 && clientWidth < 768) {
      setConfig(MAP_STATE.mobile);
      return;
    }

    if (clientWidth >= 768 && clientWidth < 1440) {
      setConfig(MAP_STATE.tablet);
      return;
    }

    if (clientWidth >= 1440) {
      setConfig(MAP_STATE.desktop);
    }
  };

  useEffect(() => {
    getClientWidth(window.innerWidth);
  }, []);

  return {
    mapConfig,
  };
};
