import styles from './header.module.css';
import Logo from '../UIKit/Logo';
import { ReactComponent as Burger } from '../../assets/svg/burger.svg';
import { ReactComponent as Magnifier } from '../../assets/svg/magnifying-glass.svg';
import { ReactComponent as AdminIco } from '../../assets/svg/settings-gears-svgrepo-com.svg';
import { useSearchInput } from '../../hooks/useSearchInput';
import CatalogButton from '../UIKit/CatalogButton';
import UserInfo from '../UserInfo';
import SearchInput from '../SearchInput';
import { useRef } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useSticky } from '../../hooks/useSticky';
import cnBind from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { Link } from 'react-router-dom';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const cx = cnBind.bind(styles);

type HeaderProps = {
  burgerFunc: () => void;
  mobMenu: boolean;
};

const Header = ({ burgerFunc, mobMenu }: HeaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { is_superuser } = useAppSelector((state) => state.user);
  const { searchVisible, toggleSearchVisible } = useSearchInput();
  const { isMobile, isTablet } = useMatchMedia();

  useOutsideClick(containerRef, toggleSearchVisible, searchVisible);
  const { fixed } = useSticky();

  return (
    <header
      className={cx(styles.header__wrapper, styles.sticky, {
        wrapper_blocked: mobMenu,
        fixed: fixed,
      })}
    >
      <div className={cx(styles.header__container, 'container')} ref={containerRef}>
        {searchVisible && (
          <SearchInput handleClose={toggleSearchVisible} isVisible={searchVisible} />
        )}
        {mobMenu ? (
          <Logo />
        ) : (
          <div className={styles.header__left_mob}>
            <Burger
              className={cx('icon__default', styles.icon__custom)}
              onClick={() => burgerFunc()}
            />
            <Magnifier
              className={cx('icon__default', styles.icon__custom, styles.icon__custom_offset)}
              onClick={() => toggleSearchVisible()}
            />
          </div>
        )}
        {!isMobile && (
          <div
            className={cx(styles.header__section, {
              header__section_admin: is_superuser && isTablet,
            })}
          >
            <Logo />

            {is_superuser && (
              <NavLink to="/admin">
                <div className={styles.header__element}>
                  <AdminIco className="icon__default" />
                  <span className={styles.link__text}>Админка</span>
                </div>
              </NavLink>
            )}
            <Link to="/catalog">
              <CatalogButton color="black" />
            </Link>
          </div>
        )}
        <div className={styles.header__section}>
          {!isMobile && (
            <Magnifier
              className={cx('icon__default', styles.icon__custom, styles.icon__custom_offset, {
                magnifer__visible: searchVisible,
              })}
              onClick={() => toggleSearchVisible()}
            />
          )}
          {!mobMenu && <UserInfo nameVisible={searchVisible} />}
        </div>
      </div>
    </header>
  );
};

export default Header;
