import { useState } from 'react';
import CategoriesItem from '../../components/Dashboard copy/CategoriesItem';
import DashboardButton from '../../components/UIKit/DashboardButton';
import { v4 } from 'uuid';
import styles from './d-categories.module.css';
import cn from 'classnames';
import { useAppSelector } from '../../store';
import { useForm } from 'react-hook-form';
import { GetCategoriesReq } from '../../store/storeInterfaces';
import { ProductsAPI } from '../../store/services/ProductsService';

// function getNextId(categories: GetCategoriesReq[]) {
//   const maxId = categories.reduce((max, category) => (category.id > max ? category.id : max), 0);
//   return maxId + 1;
// }
const DashboardCategories = () => {
  const [activeItem, setActiveItems] = useState<number>(0);
  const categoriesFromStore = useAppSelector((state) => state.categories);
  const [isCatInput, setCatInput] = useState('');
  const [postCategories] = ProductsAPI.usePostCategoriesMutation();
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async () => {
    // const nextId = getNextId(categoriesFromStore);
    postCategories({ id: 0, title: isCatInput }).unwrap;
    setCatInput('');
  };

  const onItemClick = (id: number) => {
    setActiveItems(id);
  };

  return (
    <section className={styles.categories__wrapper}>
      <div className={cn(styles.categories__container, 'container')}>
        <div className={styles.categories__section}>
          <h4 className={styles.section__title}>Создание новой категории</h4>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.section__top}>
            <input
              type="text"
              className={`${styles.top__input} ${errors.cat ? styles.errorIn : ''}`}
              placeholder="Название категории"
              {...register('cat', {
                required: true,
              })}
              onChange={(evt) => setCatInput(evt.target.value)}
              value={isCatInput}
              onInput={() => clearErrors('cat')}
            />

            <DashboardButton
              text="Добавить"
              type="submit"
              // eslint-disable-next-line prettier/prettier
              onClick={() => { }}
            />
          </form>
        </div>
        <div className={styles.categories__section}>
          <h4 className={styles.section__title}>Список категорий</h4>
          <div className={styles.section__content}>
            <span className={styles.list__title}>Название категории</span>
            <ul className={styles.categories__list}>
              {categoriesFromStore &&
                categoriesFromStore.map((category) => (
                  <CategoriesItem
                    defaultValue={category.title}
                    key={v4()}
                    itemId={category.id}
                    activeItem={activeItem}
                    toggleActive={onItemClick}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardCategories;
