export enum Url {
  BASE_URL = 'https://kazatskaya-izba.ru/api/',
  API_LOGIN = 'auth/jwt/login',
  API_VERIFY = 'auth/verify',
  API_REG = 'auth/register',
  API_FORGOT_PASSWORD = 'auth/forgot-password',
  API_USER_ME = 'users/me',
  API_SET_PASSWORD = 'auth/set-password',
  API_CATEGORIES = 'categories',
  API_PRODUCTS = 'products',
  API_GET_GUEST_ID = '/shopping-cart/get-guest-id',
  API_SHOPPING_CART = '/shopping-cart',
  API_ORDERS = '/orders',
  API_RECAPTCHA = '/recaptcha/check',
  API_FEEDBACK = '/feedback/',
  API_ORDER_STATISTIC = '/orders-statistic',
  API_ADMIN_ORDERS = '/admin-orders',
  API_VIDEO_DELETE = '/video',
  API_PHOTO_DELETE = '/photo',
  API_VIDEO = '/video-gallery',
  API_VIDEO_ON_MAIN = '/video-main-page',
  API_PHOTO_GALLERY = '/photo-gallery',
  API_PHOTO_GALLERY_ON_MAIN = '/photo-main-page',
}
