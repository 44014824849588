import DashboardSelect from '../DSelect';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus_icon.svg';
import { ReactComponent as CloseCross } from '../../../assets/svg/close_cross.svg';
import cnBind from 'classnames/bind';
import styles from './reg-item.module.css';

const cx = cnBind.bind(styles);

type RegGoodItemProps = {
  allTitles: string[];
  goodRecomendationsList?: string[];
  defaultValue?: string;
  onCloseClick: (arg1: number) => void;
  onAddClick: () => void;
  index: number;
  currentRecomendationsLength: number;
  onValueChange: (arg1: string, arg2: number) => void;
};

const RegGoodsItem = ({
  allTitles,
  defaultValue,
  onCloseClick,
  index,
  onAddClick,
  currentRecomendationsLength,
  onValueChange,
}: RegGoodItemProps) => {
  const collector = (value: string) => {
    onValueChange(value, index);
  };

  return (
    <div className={styles.item__wrapper}>
      <DashboardSelect list={allTitles} defaultValue={defaultValue} onChange={collector} />
      {index === currentRecomendationsLength - 1 ? (
        <PlusIcon
          className={cx('icon__default', styles.icon__custom)}
          onClick={() => onAddClick()}
        />
      ) : (
        <CloseCross
          className={cx('icon__default', styles.icon__custom)}
          onClick={() => onCloseClick(index)}
        />
      )}
    </div>
  );
};

export default RegGoodsItem;
