import { MediaPlayer, MediaOutlet } from '@vidstack/react';
import { ReactComponent as DeleteButton } from '../../../assets/svg/error_icon.svg';
import styles from './drag-file-item.module.css';

type DragFileItemProps = {
  id: string | number;
  link: string;
  onDeleteClick: (arg1: string | number) => void;
};

const DragFileItem = ({ id, link, onDeleteClick }: DragFileItemProps) => {
  return (
    <li className={styles.list__item}>
      <MediaPlayer aspectRatio={16 / 9} src={link}>
        <MediaOutlet />
      </MediaPlayer>
      <DeleteButton
        className={styles.delete__icon}
        onClick={() => onDeleteClick(id)}
        title="Удалить видео из галлереи"
      />
    </li>
  );
};

export default DragFileItem;
