import styles from './App.module.css';
import GlobalRoutes from '../../router/GlobalRoutes';

function App() {
  return (
    <div className={styles.app}>
      <GlobalRoutes />
    </div>
  );
}

export default App;
