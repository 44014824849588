import styles from './breadscrumbs.module.css';
import { v4 } from 'uuid';
import BreadScrumbsItem from '../BreadScrumbsItem';
import { routesNames } from '../../models/constants';

type BreadScrumbsProps = {
  location: string;
};

const BreadScrumbs = ({ location }: BreadScrumbsProps) => {
  const locations = location.split('/');

  return (
    <ul className={styles.breadscrumbs__wrapper}>
      {locations.map((link, ind, arr) => {
        const title = routesNames[link as keyof typeof routesNames] || link;
        return ind === arr.length - 1 ? (
          <BreadScrumbsItem key={v4()} current={true} title={title} />
        ) : (
          <BreadScrumbsItem key={v4()} route={link} title={title} />
        );
      })}
    </ul>
  );
};

export default BreadScrumbs;
