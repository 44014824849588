import styles from './cart-item.module.css';
import { ReactComponent as PlusIcon } from '../../assets/svg/counter_plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/svg/counter_minus.svg';
import { ReactComponent as CloseBtn } from '../../assets/icons/rotated_cross.svg';
import { useAppDispatch } from '../../store';
import { removeProductFromCart, updateProductCount } from '../../store/slices/CartSlice';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { NavLink } from 'react-router-dom';

type CartItemProps = {
  image: string;
  title: string;
  unit: string;
  price: number;
  count: number;
  amount: number;
  id: number;
};

const CartItem = ({ image, title, unit, price, count, amount, id }: CartItemProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMatchMedia();

  const handleIncrement = () => {
    const newCount = count * amount + amount;

    dispatch(updateProductCount({ id: id.toString(), count: newCount }));
  };

  const handleDecrement = () => {
    const newCount = count * amount - amount;

    dispatch(updateProductCount({ id: id.toString(), count: newCount }));
  };

  const handleClose = () => {
    dispatch(removeProductFromCart(id.toString()));
  };

  return (
    <li className={styles.item__wrapper}>
      <div className={styles.item__head}>
        <img src={image} alt="Изображение продукции" className={styles.head__image} />
        <NavLink to={`/catalog/${id}#top`} className={styles.head__title}>
          {title}
        </NavLink>
        {isMobile && <CloseBtn className={styles.icon__custom} onClick={handleClose} />}
      </div>
      <div className={styles.item__info}>
        {!isMobile && <CloseBtn className={styles.icon__custom} onClick={handleClose} />}
        <div className={styles.item__sum}>
          <span className={styles.sum__price_price}>{(price * count).toFixed(2)} ₽</span>
          <div className={styles.sum__weight}>
            <span>{(count * amount).toFixed(1)}</span>
            <span>{unit}</span>
          </div>
        </div>
        <div className={styles.item__counter}>
          <div className={styles.counter__button}>
            <MinusIcon className={styles.button__icon} onClick={handleDecrement} />
          </div>
          <div className={styles.counter__value}>
            <span>{amount}</span>
            <span>{unit}</span>
          </div>
          <div className={styles.counter__button}>
            <PlusIcon className={styles.button__icon} onClick={handleIncrement} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;

/*
  <li className={styles.item__wrapper}>
      <div className={styles.item__left}>
        <img src={image} alt="Изображение товара" className={styles.item__image} />
        <Link to={`/catalog/${id}#top`}>
          <span className={styles.item__title}>{title}</span>
        </Link>
        {isMobile && <CloseBtn className={styles.close__icon} onClick={handleClose} />}
      </div>
      <div className={styles.item__right}>
        <div className={styles.right__handlers}>
          <div className={styles.handlers__counter}>
            <div className={styles.counter__button}>
              <MinusIcon className={styles.button__icon} onClick={handleDecrement} />
            </div>
            <p className={styles.counter__text}>
              <span>{amount}</span>
              <span>{unit}</span>
            </p>
            <div className={styles.counter__button}>
              <PlusIcon className={styles.button__icon} onClick={handleIncrement} />
            </div>
          </div>
          <div className={styles.handlers__sum}>
            <p className={styles.sum__price}>
              <span>{(price * count).toFixed(2)}</span>
              <span>&#8381;</span>
            </p>
            <p className={styles.sum__weight}>
              <span>{(count * amount).toFixed(1)}</span>
              <span>{unit}</span>
            </p>
          </div>
        </div>
        {!isMobile && <CloseBtn className={styles.close__icon} onClick={handleClose} />}
      </div>
    </li>
*/
