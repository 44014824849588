import { Helmet } from 'react-helmet';
import BreadScrumbs from '../../components/BreadScrumbs';
import styles from './policy.module.css';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

const Policy = () => {
  const location = useLocation();

  return (
    <section className={styles.page__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className="page__shadow" />
      <div className={cn(styles.page__container, 'container')}>
        <BreadScrumbs location={location.pathname} />
        <div className={styles.page__content}>
          <p className={styles.content__part}>Политика в отношении обработки персональных данных</p>
          <p className={styles.content__part}>
            1. Общие положения <br />
            Настоящая политика обработки персональных данных составлена в&nbsp;соответствии
            с&nbsp;требованиями Федерального закона от&nbsp;27.07.2006. №152-ФЗ «О&nbsp;персональных
            данных» и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры
            по&nbsp;обеспечению безопасности персональных данных специи-опт.рф (далее – Оператор).
          </p>
          <p className={cn(styles.content__part)}>
            1.1.Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей
            деятельности соблюдение прав и&nbsp;свобод человека и&nbsp;гражданина при&nbsp;обработке
            его персональных данных, в&nbsp;том&nbsp;числе защиты прав на&nbsp;неприкосновенность
            частной жизни, личную и&nbsp;семейную тайну. <br />
            1.2.Настоящая политика Оператора в&nbsp;отношении обработки персональных данных (далее –
            Политика) применяется ко&nbsp;всей информации, которую Оператор может получить
            о&nbsp;посетителях веб-сайта https://kazatskaya-izba.ru.
          </p>
          <p className={styles.content__part}>2. Основные понятия, используемые в&nbsp;Политике</p>
          <p className={styles.content__part}>
            2.1 Автоматизированная обработка персональных данных – обработка персональных данных
            с&nbsp;помощью средств вычислительной техники;
            <br />
            2.2 Блокирование персональных данных – временное прекращение обработки персональных
            данных (за&nbsp;исключением случаев, если&nbsp;обработка необходима для&nbsp;уточнения
            персональных данных);
            <br />
            2.3 Веб-сайт – совокупность графических и&nbsp;информационных материалов, а&nbsp;также
            программ для&nbsp;ЭВМ и&nbsp;баз данных, обеспечивающих их доступность в&nbsp;сети
            интернет по&nbsp;сетевому адресу https://специи-опт.рф;
            <br />
            2.4 Информационная система персональных данных — совокупность содержащихся в&nbsp;базах
            данных персональных данных, и&nbsp;обеспечивающих их обработку информационных технологий
            и&nbsp;технических средств;
            <br />
            2.5 Обезличивание персональных данных — действия, в&nbsp;результате которых невозможно
            определить без&nbsp;использования дополнительной информации принадлежность персональных
            данных конкретному Пользователю или&nbsp;иному субъекту персональных данных;
            <br />
            2.6 Обработка персональных данных – любое действие (операция) или&nbsp;совокупность
            действий (операций), совершаемых с использованием средств автоматизации
            или&nbsp;без&nbsp;использования таких средств с&nbsp;персональными данными, включая
            сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных данных;
            <br />
            2.7 Оператор – государственный орган, муниципальный орган, юридическое
            или&nbsp;физическое лицо, самостоятельно или&nbsp;совместно с&nbsp;другими лицами
            организующие и&nbsp;(или)&nbsp;осуществляющие обработку персональных данных,
            а&nbsp;также определяющие цели обработки персональных данных, состав персональных
            данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными
            данными;
            <br />
            2.8 Персональные данные – любая информация, относящаяся прямо или&nbsp;косвенно
            к&nbsp;определенному или&nbsp;определяемому Пользователю веб-сайта
            https://kazatskaya-&nbsp;izba.ru;
            <br />
            2.9 Пользователь – любой посетитель веб-сайта https://kazatskaya-izba.ru;
            <br />
            2.10 Предоставление персональных данных – действия, направленные на&nbsp;раскрытие
            персональных данных определенному лицу или&nbsp;определенному кругу лиц;
            <br />
            2.11 Распространение персональных данных – любые действия, направленные
            на&nbsp;раскрытие персональных данных неопределенному кругу лиц (передача персональных
            данных) или&nbsp;на&nbsp;ознакомление с&nbsp;персональными данными неограниченного круга
            лиц, в&nbsp;том&nbsp;числе обнародование персональных данных в&nbsp;средствах массовой
            информации, размещение в&nbsp;информационно-телекоммуникационных сетях
            или&nbsp;предоставление доступа к&nbsp;персональным данным каким-либо иным способом;
            <br />
            2.12 Трансграничная передача персональных данных – передача персональных данных
            на&nbsp;территорию иностранного государства органу власти иностранного государства,
            иностранному физическому или&nbsp;иностранному юридическому лицу;
            <br />
            2.13 Уничтожение персональных данных – любые действия, в&nbsp;результате которых
            персональные данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего
            восстановления содержания персональных данных в&nbsp;информационной системе персональных
            данных и&nbsp;(или)&nbsp;результате которых уничтожаются материальные носители
            персональных данных.
          </p>
          <p className={styles.content__part}>
            3. Оператор может обрабатывать следующие персональные данные Пользователя
          </p>
          <p className={styles.content__part}>
            3.1 Фамилия, имя, отчество;
            <br />
            3.2 Электронный адрес;
            <br />
            3.3 Номера телефонов;
            <br />
            3.4 Также на&nbsp;сайте происходит сбор и&nbsp;обработка обезличенных данных
            о&nbsp;посетителях (в&nbsp;т.ч. файлов «cookie») с&nbsp;помощью сервисов
            интернет-статистики (Яндекс Метрика, Гугл Аналитика и&nbsp;других).
            <br />
            3.5 Вышеперечисленные данные далее по&nbsp;тексту Политики объединены общим понятием
            Персональные данные.
          </p>
          <p className={styles.content__part}>4. Цели обработки персональных данных</p>
          <p className={styles.content__part}>
            4.1 Цель обработки персональных данных Пользователя — информирование Пользователя
            посредством отправки электронных писем.
            <br />
            4.2 Также Оператор имеет право направлять Пользователю уведомления о&nbsp;новых
            продуктах и&nbsp;услугах, специальных предложениях и&nbsp;различных событиях.
            Пользователь всегда может отказаться от&nbsp;получения информационных сообщений,
            направив Оператору письмо на&nbsp;адрес электронной почты ux4iz@icloud.com
            с&nbsp;пометкой «Отказ от&nbsp;уведомлениях о&nbsp;новых продуктах и&nbsp;услугах
            и&nbsp;специальных предложениях».
            <br />
            4.3 Обезличенные данные Пользователей, собираемые с&nbsp;помощью сервисов
            интернет-статистики, служат для&nbsp;сбора информации о&nbsp;действиях Пользователей
            на&nbsp;сайте, улучшения качества сайта и&nbsp;его содержания.
          </p>
          <p className={styles.content__part}>
            5. Правовые основания обработки персональных данных
          </p>
          <p className={styles.content__part}>
            5.1 Оператор обрабатывает персональные данные Пользователя только в&nbsp;случае
            их&nbsp;заполнения и/&nbsp;или&nbsp;отправки Пользователем самостоятельно через
            специальные формы, расположенные на&nbsp;сайте https://&nbsp;специи-опт.рф. Заполняя
            соответствующие формы и/&nbsp;или&nbsp;отправляя свои персональные данные Оператору,
            Пользователь выражает свое согласие с&nbsp;данной Политикой.
            <br />
            5.2 Оператор обрабатывает обезличенные данные о&nbsp;Пользователе в&nbsp;случае,
            если&nbsp;это разрешено в&nbsp;настройках браузера Пользователя (включено сохранение
            файлов «cookie» и&nbsp;использование технологии JavaScript).
          </p>
          <p className={styles.content__part}>
            6. Порядок сбора, хранения, передачи и&nbsp;других видов обработки персональных данных
          </p>
          <p className={styles.content__part}>
            Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается
            путем реализации правовых, организационных и&nbsp;технических мер, необходимых
            для&nbsp;выполнения в&nbsp;полном объеме требований действующего законодательства
            в&nbsp;области защиты персональных данных.
            <br />
            6.1 Оператор обеспечивает сохранность персональных данных и&nbsp;принимает все возможные
            меры, исключающие доступ к&nbsp;персональным данным неуполномоченных лиц.
            <br />
            6.2 Персональные данные Пользователя никогда, ни&nbsp;при&nbsp;каких условиях
            не&nbsp;будут переданы третьим лицам, за&nbsp;исключением случаев, связанных
            с&nbsp;исполнением действующего законодательства.
            <br />
            6.3 В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может
            актуализировать их&nbsp;самостоятельно, путем направления Оператору уведомление
            на&nbsp;адрес электронной почты Оператора info@специи-опт.рф с&nbsp;пометкой
            «Актуализация персональных данных».
            <br />
            6.4 Срок обработки персональных данных является неограниченным. Пользователь может
            в&nbsp;любой момент отозвать свое согласие на&nbsp;обработку персональных данных,
            направив Оператору уведомление посредством электронной почты на&nbsp;электронный адрес
            Оператора ux4iz@icloud.com с&nbsp;пометкой «Отзыв согласия на&nbsp;обработку
            персональных данных».
          </p>
          <p className={styles.content__part}>7. Трансграничная передача персональных данных</p>
          <p className={styles.content__part}>
            7.1 Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных
            обязан убедиться в&nbsp;том, что&nbsp;иностранным государством, на&nbsp;территорию
            которого предполагается осуществлять передачу персональных данных, обеспечивается
            надежная защита прав субъектов персональных данных.
            <br />
            7.2 Трансграничная передача персональных данных на&nbsp;территории иностранных
            государств, не&nbsp;отвечающих вышеуказанным требованиям, может осуществляться только
            в&nbsp;случае наличия согласия в&nbsp;письменной форме субъекта персональных данных
            на&nbsp;трансграничную передачу его персональных данных и/&nbsp;или&nbsp;исполнения
            договора, стороной которого является субъект персональных данных.
          </p>
          <p className={styles.content__part}>8. Заключительные положения</p>
          <p className={styles.content__part}>
            8.1 Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам,
            касающимся обработки его персональных данных, обратившись к&nbsp;Оператору
            с&nbsp;помощью электронной почты ux4iz@icloud.com.
            <br />
            8.2 В&nbsp;данном документе будут отражены любые изменения политики обработки
            персональных данных Оператором. Политика действует бессрочно до&nbsp;замены ее новой
            версией.
            <br />
            8.3 Актуальная версия Политики в&nbsp;свободном доступе расположена в&nbsp;сети Интернет
            по&nbsp;адресу https://&nbsp;kazatskaya-&nbsp;izba.ru.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Policy;
