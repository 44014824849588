export const PHONE_NUMBER = '+7 (925) 754-96-00';

export const routesNames = {
  '': 'Главная',
  catalog: 'Каталог',
  // 'detail-good': 'Категория',
  cart: 'Корзина',
  ordering: 'Оформление заказа',
  profile: 'Личный кабинет',
  admin: 'панель администрирования',
  'confidentiality-policy': 'Политика конфиденциальности',
};

export const FORM_TITLES = {
  Регистрация: 'Зарегистрироваться',
  'Забыли пароль?': 'Восстановить',
  Вход: 'Войти',
};

export const FORM_MAPPER = {
  '/login': 'Вход',
  '/recovery-pass': 'Забыли пароль?',
  '/signup': 'Регистрация',
  // '/reset-pass': 'Введите новый пароль',
};

export const EMAIL_REGEX = /^[\w.%+-]+@[\w.-]+\.[a-zA-Zа-яА-Я]{2,}$/;
export const PHONE_REGEX = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

export const TABLET_QUERY = '(width < 769px)';
export const MOBILE_QUERY = '(width < 426px)';

export const MAP_STATE = {
  mobile: {
    center: [52.630376, 38.264979],
    zoom: 15,
  },
  tablet: {
    center: [52.630376, 38.264979],
    zoom: 15,
  },
  desktop: {
    center: [52.625289, 38.267557],
    zoom: 12,
  },
};

export const CAROUSEL_CONFIG = {
  desktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 3,
  },
  laptop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 320 },
    items: 2,
  },
};

export const LOCATION_MAP = {
  admin: 'Заказы',
  orders: 'Заказы',
  goods: 'Товары',
  'good-detail': 'Товары',
  content: 'Контент',
  categories: 'Категории',
};

export const OPRDER_LIST_HEADERS = ['Номер', 'Имя', 'Телефон', 'Сумма', 'Дата заказа', 'Статус'];

export const GOODS_LIST_HEADERS = [
  'Картинка',
  'Название',
  'На главной',
  'Цена',
  'Категория',
  'Действия',
];

export const FORM_LOCATIONS = ['/signup', '/login', '/recovery-pass'];

export const FILES_ERRORS_TEXTS = {
  size: 'Файл слишком большой',
  format: 'Недопустимый формат',
  load: 'Ошибка загрузки',
};

export const GREETING_TEXT_MOB = `Добро пожаловать в наш магазин! 
Мы специализируемся на производстве
и продаже свежих копченостей.
Наша цель — дать вам возможность
насладиться вкусными продуктами,
изготовленными с любовью и мастерством.
Мы тщательно подбираем только самые
лучшие ингредиенты, чтобы обеспечить
отличный вкус и сохранить полезные свойства
каждого изделия.`;

export const GREETING_TEXT_TABLET = `Добро пожаловать в наш магазин! Мы специализируемся
на производстве и продаже свежих
копченостей. Наша цель — дать вам возможность
насладиться вкусными продуктами, изготовленными
с любовью и мастерством. Мы тщательно подбираем только
самые лучшие ингредиенты, чтобы обеспечить отличный вкус
и сохранить полезные свойства каждого изделия.
`;

export const GREETING_TEXT_DESKTOP = `Добро пожаловать в наш магазин! Мы
специализируемся на производстве и продаже свежих
копченостей. Наша цель — дать вам возможность
насладиться вкусными продуктами, изготовленными
с любовью и мастерством. Мы тщательно подбираем
только самые лучшие ингредиенты, чтобы обеспечить
отличный вкус и сохранить полезные свойства каждого
изделия.
`;

export const GREETING_TEXT_FULL = `Добро пожаловать в наш магазин! Мы специализируемся 
на производстве и продаже свежих копченостей.
Наша цель — дать вам возможность насладиться вкусными
продуктами, изготовленными с любовью и мастерством.
Мы тщательно подбираем только самые лучшие ингредиенты,
чтобы обеспечить отличный вкус и сохранить полезные
свойства каждого изделия.
`;
