import { ReactComponent as SwipeUp } from '../../../assets/svg/swipe_up.svg';
import { ReactComponent as SwipeDown } from '../../../assets/svg/swipe_down.svg';
import cnBind from 'classnames/bind';
import styles from './sausage.module.css';

const cx = cnBind.bind(styles);

type SausageBlockProps = {
  forward: boolean;
  back: boolean;
};

const SausageBlock = ({ forward, back }: SausageBlockProps) => {
  return (
    <div className={styles.sausage__wrapper}>
      <div className={styles.sausage__container}>
        <SwipeUp className={cx(styles.swipe__arrow, styles.swipe__up)} />
        <div className={cx(styles.sausage, { rotate_forward: forward, rotate_back: back })}></div>
        <SwipeDown className={cx(styles.swipe__arrow, styles.swipe__down)} />
      </div>
    </div>
  );
};

export default SausageBlock;
