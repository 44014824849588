import { ReactComponent as BtnUp } from '../../../assets/svg/button_up.svg';
import { ReactComponent as BtnDown } from '../../../assets/svg/button_down.svg';
import styles from './button-group.module.css';

type ButtonGroupProps = {
  setPrev: (node: HTMLDivElement | null) => void;
  setNext: (node: HTMLDivElement | null) => void;
  onForwardClick: () => void;
  onBackClick: () => void;
};

const ButtonGroup = ({ setPrev, setNext, onForwardClick, onBackClick }: ButtonGroupProps) => {
  return (
    <div className={styles.group__wrapper}>
      <div className={styles.group__container}>
        <div ref={(node) => setNext(node)}>
          <BtnUp className={styles.icon__custom} onClick={() => onForwardClick()} />
        </div>
        <div ref={(node) => setPrev(node)}>
          <BtnDown className={styles.icon__custom} onClick={() => onBackClick()} />
        </div>
      </div>
    </div>
  );
};

export default ButtonGroup;
