import { useEffect, useState } from 'react';
import styles from './d-select.module.css';

type DashboardSelectProps = {
  placeholder?: string;
  list: string[];
  defaultValue?: string;
  onChange: (arg1: string) => void | null;
};

const DashboardSelect = ({
  placeholder,
  list,
  defaultValue = '',
  onChange,
}: DashboardSelectProps) => {
  const [value, setValue] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    console.log(defaultValue, 'defaultValue');
    if (defaultValue) setValue(defaultValue);
    return () => {
      setValue(''); // Очистка значения при разрушении компонента
    };
  }, [defaultValue]);

  const onClickOption = (str: string) => {
    setValue(str);
    setVisible(false);
    onChange(str);
  };

  return (
    <div className={styles.select__wrapper}>
      <div className={styles.select__container}>
        <input
          type="text"
          placeholder={placeholder ? placeholder : 'Выберите наименование'}
          className={styles.select__input}
          disabled
          value={value}
        />
        <div className={styles.select__caret} onClick={() => setVisible(!visible)} />
        {visible && (
          <ul className={styles.options__list}>
            {list.map((option) => (
              <li
                key={option}
                className={styles.list__option}
                onClick={() => onClickOption(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DashboardSelect;
