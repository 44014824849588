import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReCaptchaComponent } from './components/Form/ReCapcha';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY as string}>
      <ReCaptchaComponent />
    </GoogleReCaptchaProvider>
  </Provider>
  // </React.StrictMode>
);
