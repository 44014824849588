import { useState } from 'react';

export const useMobileMenu = () => {
  const [curtainOpen, setOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setOpen(!curtainOpen);
  };

  return {
    curtainOpen,
    toggleMenu,
  };
};
