import { setCategories } from '../slices/CategoriesSlice';
import { setOrderProducts, setOrdersfromServer } from '../slices/GeneralConditionsSlice';
import { setProducts } from '../slices/ProductsSlice';
import { setProductPhotos } from '../slices/ProductPhotoSlice';
import {
  GetCategoriesReq,
  GetProductPhoto,
  OrdersGetRequest,
  OrdersRequest,
  OrdersResponse,
  PaymentLink,
  PhotoDel,
  ProductPhotoFile,
  ProductPhotoFileReq,
  ProductType,
  ProductTypePatch,
  ProductsSearchParams,
  SoppingCart,
  SoppingCartResponse,
  UpdatedOrderReq,
  ProductRecomendationsPost,
  DeleteProdFromRecomends,
  OrdersPatchAdmin,
} from '../storeInterfaces';
import { Url } from '../urlConstants';
import { commonApi } from './common.api';

export const ProductsAPI = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<GetCategoriesReq[], void>({
      query: () => ({ url: Url.API_CATEGORIES }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setCategories(result.data));
        } catch (e) {
          // Intentionally empty catch block - error handling is performed elsewhere
        }
      },

      providesTags: ['Categories'],
    }),
    postCategories: build.mutation<GetCategoriesReq, GetCategoriesReq>({
      query: (info) => ({
        url: Url.API_CATEGORIES,
        method: 'POST',
        body: info,
      }),
      invalidatesTags: ['Categories'],
    }),
    pathCategories: build.mutation<GetCategoriesReq, GetCategoriesReq>({
      query: (info) => ({
        url: `${Url.API_CATEGORIES}/${info.id}`,
        method: 'PATCH',
        body: info,
      }),
      invalidatesTags: ['Categories'],
    }),
    deleteCategories: build.mutation<void, number>({
      query: (id) => ({
        url: `${Url.API_CATEGORIES}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories'],
    }),
    postPhoto: build.mutation<ProductPhotoFile, ProductPhotoFileReq>({
      query: (info) => ({
        url: `${Url.API_PRODUCTS}/${info.id}/upload-photo`,
        method: 'POST',
        body: info.photo,
      }),
      invalidatesTags: ['Products', 'Photo'],
    }),
    deletePhoto: build.mutation<void, PhotoDel>({
      query: (info) => ({
        url: `${Url.API_PRODUCTS}/${info.id}/upload-photo/${info.pk}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products', 'Photo'],
    }),

    getProductById: build.query<ProductType, string>({
      query: (id) => ({ url: `${Url.API_PRODUCTS}/${id}` }),
    }),

    deleteProductById: build.mutation({
      query: (id) => ({
        url: `${Url.API_PRODUCTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products'],
    }),
    getProductPhotoById: build.query<GetProductPhoto[], string>({
      query: (id) => ({ url: `${Url.API_PRODUCTS}/${id}/upload-photo` }),
      providesTags: ['Photo'],
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setProductPhotos(result.data));
        } catch (e) {
          console.error('no photos', e);
        }
      },
    }),
    addProductById: build.mutation<ProductType, ProductType>({
      query: (prodInfo) => ({
        url: Url.API_PRODUCTS,
        method: 'POST',
        body: prodInfo,
      }),
      invalidatesTags: ['Products'],
    }),
    patchProductById: build.mutation<ProductType, ProductTypePatch>({
      query: (prodInfo) => ({
        url: `${Url.API_PRODUCTS}/${prodInfo.id}`,
        method: 'PATCH',
        body: prodInfo.body,
      }),
      invalidatesTags: ['Products'],
    }),
    getAllProducts: build.query<ProductType[], ProductsSearchParams>({
      query: ({ category, limit = 100, skip = 0 }) => {
        const params: Record<string, string | number> = {};
        if (category !== undefined) params.category = category;
        if (limit) params.limit = limit;
        if (skip) params.skip = skip;
        return {
          url: Url.API_PRODUCTS,
          params,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setProducts(result.data));
        } catch (e) {
          // Intentionally empty catch block - error handling is performed elsewhere
        }
      },
      providesTags: ['Products'],
    }),
    getAllRecomendationsProducts: build.query<ProductType[], string>({
      query: (id) => ({ url: `${Url.API_PRODUCTS}/${id}/recomendation` }),
      providesTags: ['Recommendation'],
    }),
    shoppingCartPost: build.mutation<SoppingCartResponse[], SoppingCart[]>({
      query: (cartInfo) => ({
        url: Url.API_SHOPPING_CART,
        method: 'POST',
        body: cartInfo,
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const prods: number[] = [];
          result.data.forEach((item) => {
            prods.push(item.id);
          });
          dispatch(setOrderProducts(prods));
        } catch (e) {
          console.error('shoppingCartPost error', e);
        }
      },
    }),
    paymentLinkGet: build.query<PaymentLink, number>({
      query: (id) => ({ url: `${Url.API_ORDERS}/${id}/payment-link` }),
    }),
    orderPost: build.mutation<OrdersResponse, OrdersRequest>({
      query: (orderInfo) => ({
        url: Url.API_ORDERS,
        method: 'POST',
        body: orderInfo,
      }),
    }),

    ordersGet: build.query<OrdersGetRequest[], void>({
      query: () => ({ url: Url.API_ORDERS }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setOrdersfromServer(result.data));
        } catch (e) {
          // Intentionally empty catch block - error handling is performed elsewhere
        }
      },
      providesTags: ['Orders'],
    }),

    orderPatchAA: build.mutation<OrdersGetRequest, OrdersPatchAdmin>({
      query: (orderInfo) => ({
        url: `${Url.API_ORDERS}/${orderInfo.id}`,
        method: 'PATCH',
        body: orderInfo.order,
      }),
      invalidatesTags: ['Orders'],
    }),

    singleOrderGet: build.query<OrdersGetRequest, string>({
      query: (id) => ({ url: `${Url.API_ORDERS}/${id}` }),
    }),
    productByIdGet: build.query<ProductType, number>({
      query: (id) => ({ url: `${Url.API_PRODUCTS}/${id}` }),
    }),
    updateProductStatus: build.mutation<void, UpdatedOrderReq>({
      query: ({ id, status }) => ({
        url: `${Url.API_ORDERS}/${id}/status`,
        method: 'PATCH',
        body: {
          status,
        },
      }),
    }),
    postProductRecomendations: build.mutation<void, ProductRecomendationsPost>({
      query: ({ id, ids }) => ({
        url: `${Url.API_PRODUCTS}/${id}/recomendation`,
        method: 'POST',
        body: {
          prod_ids: ids,
        },
      }),
      invalidatesTags: ['Recommendation'],
    }),
    deleteItemFromRecomendations: build.mutation<void, DeleteProdFromRecomends>({
      query: ({ id, pk }) => ({
        url: `${Url.API_PRODUCTS}/${id}/recomendation/${pk}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recommendation'],
    }),
  }),
});
