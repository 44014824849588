import styles from './catalog-preview.module.css';
import cnBind from 'classnames/bind';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { useEffect, useState } from 'react';
import Button from '../atoms/Button';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CartPlus } from '../../assets/svg/cart_plus.svg';
import { ReactComponent as ShortArrow } from '../../assets/svg/short_desktop_arrow.svg';
import { ReactComponent as LongArrow } from '../../assets/svg/long_desktop_arrow.svg';
import { ReactComponent as MobileArrow } from '../../assets/svg/catalog_item_arrow.svg';
import { ReactComponent as TabletArrow } from '../../assets/svg/tablet_arrow.svg';
import { HashLink } from 'react-router-hash-link';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  addProductToCart,
  removeProductFromCart,
  updateProductCount,
} from '../../store/slices/CartSlice';
import CounterButton from '../atoms/CounterButton';

const cx = cnBind.bind(styles);

type CatalogPreviewItemProps = {
  text?: string;
  img?: string;
  id?: string;
  title?: string;
  cook_time?: string;
  weight?: number;
};

const CatalogPreviewItem = ({
  text,
  img,
  id,
  title,
  cook_time,
  weight,
}: CatalogPreviewItemProps) => {
  const [hovered, setHovered] = useState(false);
  const { isMobile, isTablet, isDesktop, isFullHD } = useMatchMedia();
  const categoriesFromStore = useAppSelector((state) => state.categories);
  const count = useAppSelector((state) => {
    const product = state.cart.find((product) => product.id?.toString() === id);
    return product ? product.count : 0;
  });
  const cartProducts = useAppSelector((state) => state.cart);
  const [isCategory, setCategory] = useState('');
  const [isCountWight, setCountWight] = useState<number>();
  const [inCart, setInCart] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (count) setCountWight(count);
  }, [count]);

  // useEffect(() => {
  //   if (categoriesFromStore) {
  //     const categoryFromId = categoriesFromStore.find((categoryObj) => categoryObj.id === category);
  //     if (categoryFromId?.title) setCategory(categoryFromId?.title);
  //   }
  // }, [categoriesFromStore, category]);

  useEffect(() => {
    const productInCart = cartProducts.find((product) => product.id?.toString() === id);
    setInCart(!!productInCart);
  }, [cartProducts, id]);

  const handleAddToCart = () => {
    dispatch(addProductToCart({ id, count: weight, productWight: weight }));
    setInCart(true);
  };

  const handleUpdateCount = (newCount: number) => {
    if (newCount <= 0) {
      dispatch(removeProductFromCart(id));
      setInCart(false);
    } else {
      dispatch(updateProductCount({ id, count: newCount }));
    }
  };

  return (
    <div
      className={styles.catalog_item__wrapper}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={cx(styles.catalog_item__container, 'container')}>
        <div className={styles.item__header}>
          <div className={styles.header__left}>
            <h3 className={styles.header__top}>{title}</h3>
            <span className={cx(styles.header__bottom, { hovered__block: hovered })}>
              Под заказ {cook_time ? parseInt(cook_time) : '25'} дней со дня заказа
            </span>
          </div>
          {!isMobile && (
            <div className={cx(styles.header__right, { hovered__flex: hovered })}>
              <div className={styles.header__button}>
                {inCart ? (
                  <CounterButton
                    id={id?.toString() || ''}
                    onCountChange={handleUpdateCount}
                    text={text || ''}
                    weight={isCountWight ?? 1}
                  />
                ) : (
                  <Button
                    isPrimary={true}
                    text={text}
                    hasIcon={true}
                    icon={<CartPlus className={styles.button__icon} />}
                    // eslint-disable-next-line prettier/prettier
                  onClick={handleAddToCart}
                    full
                  />
                )}
              </div>
              <HashLink smooth to={`/catalog/${id}#top`} className={styles.item__link}>
                <span>Подробнее</span>
              </HashLink>
            </div>
          )}
        </div>
        {isTablet && <TabletArrow className={styles.icon__custom} />}
        {(isDesktop || isFullHD) && !hovered && <ShortArrow />}
        {(isDesktop || isFullHD) && hovered && <LongArrow />}
        <img
          src={img}
          alt="Изображение товара"
          className={cx(styles.item__img, { hovered__block: hovered })}
        />
        {isMobile && <MobileArrow className={styles.icon__custom} />}
        {isMobile && (
          <div className={styles.item__footer}>
            <HashLink smooth to={`/catalog/${id}#top`} className={styles.item__link}>
              <span>Подробнее</span>
            </HashLink>
            <div className={styles.footer__button}>
              {inCart ? (
                <CounterButton
                  id={id?.toString() || ''}
                  onCountChange={handleUpdateCount}
                  text={text || ''}
                  weight={isCountWight ?? 1}
                />
              ) : (
                <Button
                  isPrimary={true}
                  text={text}
                  hasIcon={true}
                  icon={<CartPlus className={styles.button__icon} />}
                  // eslint-disable-next-line prettier/prettier
                  onClick={handleAddToCart}
                  full
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CatalogPreviewItem;

/*
  <li className={cx(styles.catalog_item__wrapper, { wrapper__tablet: hovered })}>
      <div
        className={cx(styles.item__header, { opened: hovered })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className={styles.header__left}>
          <h3 className={styles.left__title}>{title}</h3>
          {hovered && <p className={styles.left__subtitle}>Под заказ 25 дней со дня заказа</p>}
        </div>
        {hovered && (
          <div className={styles.header__right}>
            <Button
              isPrimary={true}
              text={text}
              hasIcon={true}
              icon={<CartPlus className={styles.icon} />}
              onClick={() => {}}
            />
            <Link to={`/catalog/${id}#top`}>
              <p className={styles.right__link}>Подробнее</p>
            </Link>
          </div>
        )}
        <img src={img} className={cx(styles.item__image, { visible: hovered })}></img>
      </div>

      <div className={cx(styles.item__arrow, { long: hovered })}>
        {!hovered ? (
          <ShortArrow className={styles.arrow} />
        ) : (
          <LongArrow className={cx(styles.arrow, { long: hovered })} />
        )}
      </div>
    </li>
*/
