import { ReactElement, SyntheticEvent } from 'react';
import cnBind from 'classnames/bind';
import styles from './button.module.css';

const cx = cnBind.bind(styles);

type ButtonProps = {
  text?: string;
  hasIcon: boolean;
  iconOnly?: boolean;
  icon?: ReactElement;
  isPrimary: boolean;
  disabled?: boolean;
  onClick: (event: SyntheticEvent) => void;
  full?: boolean;
  unit?: 'шт.' | 'кг.' | 'руб.' | '₽';
};

const Button = ({
  text,
  disabled,
  hasIcon,
  icon,
  iconOnly,
  isPrimary,
  onClick,
  full,
  unit,
}: ButtonProps) => {
  return (
    <button
      className={cx(styles.button, {
        button__primary: isPrimary,
        button__secondary: !isPrimary,
        full: full,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.button__container}>
        {hasIcon && icon}
        {!iconOnly && (
          <div className={styles.button__text}>
            <span>{text}</span>
            {unit && <span>{unit}</span>}
          </div>
        )}
      </div>
    </button>
  );
};

export default Button;
