import GoodsIcon from '../../../assets/img1/goods_icon.png';
import styles from './order_content_item.module.css';
import { ProductsAPI } from '../../../store/services/ProductsService';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { OrderProducts } from '../../../store/storeInterfaces';
import Select from 'react-select';
import { useAppSelector } from '../../../store';
export type OrderContentItemProps = {
  product_id: number;
  product_amount?: number;
  product_price?: number;
  updateDataOrderContentForPath: (data: OrderProducts) => void;
  delProd: () => void;
  exceptions: number[];
};
type Options = {
  value: string;
  label: string;
};
const OrderContentItemNew = ({
  product_id,
  product_amount = 0,
  product_price = 0,
  updateDataOrderContentForPath,
  delProd,
  exceptions,
}: OrderContentItemProps) => {
  const [trigger, { data: product, isSuccess }] = ProductsAPI.useLazyGetProductByIdQuery();
  const products = useAppSelector((state) => state.products);
  const [inputAmountValue, setAmountValue] = useState<string>('');
  const [isSelectOptions, setSelectOptions] = useState<Options[]>([]);
  const [inputPriceValue, setPriceValue] = useState<string>('');
  const [newProdId, setNewProdId] = useState(0);
  const [isWi, setWi] = useState('0');

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter((el) => el.id && !exceptions.includes(el.id));
      const selectOptions: Options[] = filteredProducts.map((el) => {
        return { label: el.title || 'наиминованиe', value: String(el.id) };
      });
      setSelectOptions(selectOptions);
    }
  }, [products]);

  useEffect(() => {
    if (newProdId !== 0) {
      trigger(newProdId.toString());
    }
  }, [newProdId, trigger]);

  useEffect(() => {
    if (Number(inputAmountValue) !== 0) {
      setWi((parseFloat(inputPriceValue) * parseFloat(inputAmountValue)).toFixed(1));
    }
  }, [inputPriceValue, inputAmountValue]);

  const addNewProd = () => {
    updateDataOrderContentForPath({
      product: Number(newProdId),
      amount: parseFloat(parseFloat(inputAmountValue).toFixed(2)),
      price: parseFloat(parseFloat(isWi).toFixed(1)),
    });
  };
  const MyKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    if (evt.key === 'Delete' || evt.key === 'Backspace') {
      return true;
    }
    if (allowedKeys.includes(evt.key)) {
      return true;
    }
    evt.preventDefault();
    return false;
  };

  return (
    <div className={styles.item__wrapper}>
      <img
        src={isSuccess && product?.photo ? product?.photo[0] : GoodsIcon}
        alt="good's icon"
        className={styles.item__image}
      />
      <div className={styles.item__content}>
        <div className={styles.product_title}>
          <Select
            className={styles.select__input}
            options={isSelectOptions}
            placeholder={'выбирите  товар '}
            onChange={(evt) => {
              if (evt?.value) {
                setNewProdId(Number(evt?.value));
              }
            }}
          />
        </div>
        <input
          className={styles.item__input}
          value={inputPriceValue}
          onChange={(evt) => {
            const value = evt.target.value;
            const updatedValue = value.replace(',', '.');
            setPriceValue(updatedValue);
          }}
          onKeyDown={(evt) => MyKeyPress(evt)}
        />
        <span className={styles.content__text}>
          <span>₽</span>
        </span>
        <input
          className={styles.item__input}
          value={inputAmountValue}
          onChange={(evt) => {
            const value = evt.target.value;
            const updatedValue = value.replace(',', '.');
            setAmountValue(updatedValue);
          }}
          onKeyDown={(evt) => MyKeyPress(evt)}
        />
        <span>{product?.units ?? 'кг'}</span>
        <span className={styles.content__text}>
          {isWi} <span>₽</span>
        </span>
        <button className={styles.add__button} type="button" onClick={addNewProd}>
          {' '}
          сохранить
        </button>
        <button className={styles.del__button} type="button" onClick={delProd}>
          {' '}
          удалить
        </button>
      </div>
    </div>
  );
};

export default OrderContentItemNew;
