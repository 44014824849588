import List from '../../components/Dashboard copy/List';
import StatisticsWidget from '../../components/Dashboard copy/Statistic';
import { OPRDER_LIST_HEADERS } from '../../models/constants';
import cn from 'classnames';
import styles from './d-orders.module.css';
import OrdersItem from '../../components/Dashboard copy/OrdersItem';
import { ProductsAPI } from '../../store/services/ProductsService';
import { AdminAPI } from '../../store/services/AdminrService';
import { dateFormatter } from '../../pages/DOrderDetail/helpers/dateFormatter';
import { orderSumCalculator } from '../../pages/DOrderDetail/helpers/orderSumCalculator';
import { Helmet } from 'react-helmet';

const DashboardOrders = () => {
  const { data: statisticData } = AdminAPI.useOrdersStatisticQuery();
  const { data: ordersData } = AdminAPI.useAdminOrdersGetQuery(undefined, {
    pollingInterval: 30000,
  });
  ProductsAPI.useOrdersGetQuery();

  const orders = ordersData?.map((order) => (
    <OrdersItem
      key={order.id}
      order_date={dateFormatter(order.created_on)}
      order_number={order.id}
      order_sum={orderSumCalculator(order.order_products)}
      customer_name={order.name}
      phone={order.phone}
      deliveryStatus={order.status}
      paymentStatus={order.payment}
    />
  ));

  return (
    <section className={styles.orders__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className={cn(styles.orders__container, 'container')}>
        <div className={styles.orders__widget}>
          <h4 className={styles.widget__title}>Статистика</h4>
          <StatisticsWidget
            total_orders={statisticData?.total_orders ?? 0}
            total_price={statisticData?.total_price ?? 0}
            orders_per_month={statisticData?.orders_per_month ?? 0}
            total_users={statisticData?.total_users ?? 0}
          />
        </div>
        <div className={styles.orders__widget}>
          <h4 className={styles.widget__title}>Список заказов</h4>
          <div className={styles.list__wrapper}>
            {ordersData && <List headerTitles={OPRDER_LIST_HEADERS} list={orders ?? []} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardOrders;
