import { setVideoFromServer } from '../slices/GeneralConditionsSlice';

import { OrderStatistic, OrdersAdmin, ProductPhotoFile, ProductVideoRes } from '../storeInterfaces';
import { Url } from '../urlConstants';
import { commonApi } from './common.api';

export const AdminAPI = commonApi.injectEndpoints({
  endpoints: (build) => ({
    adminOrdersGet: build.query<OrdersAdmin[], void>({
      query: () => ({ url: Url.API_ADMIN_ORDERS }),
    }),
    ordersStatistic: build.query<OrderStatistic, void>({
      query: () => ({ url: Url.API_ORDER_STATISTIC }),
    }),
    getVideo: build.query<ProductVideoRes[], void>({
      query: () => ({ url: Url.API_VIDEO }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(setVideoFromServer(result.data));
        } catch (e) {
          // Intentionally empty catch block - error handling is performed elsewhere
        }
      },
      providesTags: ['Video'],
    }),
    getVideGalleryMain: build.query<ProductVideoRes[], void>({
      query: () => ({ url: Url.API_VIDEO_ON_MAIN }),
      providesTags: ['VideoMain'],
    }),

    postVideo: build.mutation<ProductVideoRes, FormData | File>({
      query: (userInfo) => ({
        url: Url.API_VIDEO,
        method: 'POST',
        body: userInfo,
      }),
      invalidatesTags: ['Video'],
    }),
    postVideoMain: build.mutation<ProductVideoRes, FormData | File>({
      query: (userInfo) => ({
        url: Url.API_VIDEO_ON_MAIN,
        method: 'POST',
        body: userInfo,
      }),
      invalidatesTags: ['VideoMain'],
    }),
    deleteVideo: build.mutation<void, number | string>({
      query: (id) => ({
        url: `${Url.API_VIDEO_DELETE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Video', 'VideoMain'],
    }),
    getPhotoGallery: build.query<ProductPhotoFile[], void>({
      query: () => ({ url: Url.API_PHOTO_GALLERY }),
      providesTags: ['PhotoGallery'],
    }),
    deletePhotoGallery: build.mutation<void, number | string>({
      query: (id) => ({
        url: `${Url.API_PHOTO_DELETE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PhotoGallery'],
    }),
    postPhotoGallery: build.mutation<ProductPhotoFile, FormData>({
      query: (info) => ({
        url: Url.API_PHOTO_GALLERY,
        method: 'POST',
        body: info,
      }),
      invalidatesTags: ['PhotoGallery'],
    }),
    // deleteDoc: build.mutation({
    //   query: (id) => ({
    //     url: `${Url.API_DOCS}${id}/`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Docs'],
    // }),
  }),
});
