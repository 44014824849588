import { useEffect } from 'react';

import styles from './reset-page.module.css';
import { AuthorizationUserAPI } from '../../store/services/UserService';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const password = searchParams.get('password');
  const [resetPassword] = AuthorizationUserAPI.useResetPasswordMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && password) {
      resetPassword({ token: token, password: password })
        .then(() => {
          navigate('/login', { state: { scrollToTop: true } });
        })
        .catch((error) => {
          // Обработка ошибок
        });
    }
  }, []);

  return (
    <div className={styles.main_page__container}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
    </div>
  );
};

export default ResetPasswordPage;
