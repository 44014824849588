import { ReactNode } from 'react';
import { v4 } from 'uuid';
import cnBind from 'classnames/bind';
import styles from './list.module.css';

type ListProps = {
  headerTitles: string[];
  hiddenLast?: boolean;
  list: ReactNode[];
  enviroment?: 'goods' | 'orders';
};

const cx = cnBind.bind(styles);

const List = ({ headerTitles, hiddenLast = false, list, enviroment = 'orders' }: ListProps) => {
  return (
    <div className={styles.list__wrapper}>
      {enviroment === 'orders' && (
        <div className={styles.list__header}>
          {headerTitles.map((title) => (
            <span
              key={v4()}
              className={cx(styles.header__item, {
                item__visible: !hiddenLast,
                hidden__last: hiddenLast,
              })}
            >
              {title}
            </span>
          ))}
        </div>
      )}
      {enviroment === 'goods' && (
        <div className={styles.header__variation}>
          <div className={styles.variation__aside}>
            <span className={styles.header__item}>{headerTitles[0]}</span>
          </div>
          <div className={styles.variation__main}>
            <div className={styles.main__left}>
              <span className={styles.header__item}>{headerTitles[1]}</span>
              <span className={styles.header__item}>{headerTitles[2]}</span>
            </div>
            <div className={styles.main__right}>
              <div className={styles.right__aside}>
                <span className={styles.header__item}>{headerTitles[3]}</span>
                <span className={styles.header__item}>{headerTitles[4]}</span>
              </div>
              <div>
                <span className={cx(styles.header__item, styles.hidden__last)}>
                  {headerTitles[5]}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.orders__container}>
        <ul className={styles.orders__list}>{list}</ul>
      </div>
    </div>
  );
};

export default List;
