import { useMatchMedia } from '../../../hooks/useMatchMedia';
import styles from './step-five.module.css';
import cn from 'classnames';
import MobileSlider from '../../../components/MobileSlider';
import { ReactComponent as PlayIcon } from '../../../assets/svg/play_video_icon.svg';
import { AdminAPI } from '../../../store/services/AdminrService';
import { useEffect, useState } from 'react';
import {
  MediaPlayer,
  MediaOutlet,
  MediaPlayButton,
  MediaFullscreenButton,
  MediaMuteButton,
} from '@vidstack/react';
import { ProductVideoRes } from '../../../store/storeInterfaces';
import {
  GREETING_TEXT_MOB,
  GREETING_TEXT_TABLET,
  GREETING_TEXT_DESKTOP,
  GREETING_TEXT_FULL,
} from '../../../models/constants';
import cnBind from 'classnames/bind';

const cx = cnBind.bind(styles);

const Step5 = () => {
  const { isMobile, isTablet, isDesktop, isFullHD } = useMatchMedia();
  const { data: videoF } = AdminAPI.useGetVideoQuery();
  const [videoLinks, setFileList] = useState<string[]>([]);

  useEffect(() => {
    if (videoF) {
      if (isMobile) {
        const arr = videoF.map((el) => el.file_link);
        setFileList(arr);
        return;
      }

      if (!isMobile && isTablet) {
        const arr = videoF.filter((_, i) => i < 5).map((el) => el.file_link);
        setFileList(arr);
        return;
      }
      if (!isMobile && !isTablet) {
        const arr = videoF.filter((_, i) => i < 6).map((el) => el.file_link);
        setFileList(arr);
        return;
      }
    }
  }, [videoF, isMobile, isTablet]);

  return (
    <section className={styles.step_five__wrapper} id="video-section">
      <div className={styles.tomato} />
      <div className={cn('container', styles.step_five__container)}>
        <div className={styles.step_five__content}>
          <div className={styles.content__header}>
            <h3 className="step__title">О нас</h3>
          </div>
          <div className={styles.content__body}>
            <div className={styles.body__text}>
              <pre>
                {isMobile && GREETING_TEXT_MOB}
                {isTablet && GREETING_TEXT_TABLET}
                {isDesktop && GREETING_TEXT_DESKTOP}
                {isFullHD && GREETING_TEXT_FULL}
              </pre>
            </div>
            {isMobile ? (
              <MobileSlider videoLinks={videoLinks} />
            ) : (
              <ul
                className={cx(styles.content__grid, {
                  content__grid_1: videoLinks.length === 1,
                  content__grid_2: videoLinks.length === 2,
                  content__grid_3: videoLinks.length === 3,
                  content__grid_4: videoLinks.length === 4,
                  content__grid_5: videoLinks.length === 5,
                  content__grid_6: videoLinks.length === 6,
                })}
              >
                {videoLinks.map((el, i) => (
                  <li
                    key={`${el}${i}`}
                    className={cx(styles.grid__item, {
                      grid__item_1: videoLinks.length === 1,
                      grid__item_2: videoLinks.length === 2,
                      grid__item_3: videoLinks.length === 3,
                      grid__item_4: videoLinks.length === 4,
                      grid__item_5: videoLinks.length === 5,
                      grid__item_6: videoLinks.length === 6,
                    })}
                  >
                    <MediaPlayer aspectRatio={16 / 9} src={el} muted autoplay>
                      <MediaOutlet>
                        <MediaPlayButton>
                          <PlayIcon />
                        </MediaPlayButton>
                        <MediaMuteButton />
                        <MediaFullscreenButton />
                      </MediaOutlet>
                    </MediaPlayer>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className={styles.leaf} />
    </section>
  );
};

export default Step5;
