import styles from './ordinaring-viewer.module.css';
import cnBind from 'classnames/bind';
import Button from '../atoms/Button';
import { useAppDispatch, useAppSelector } from '../../store';
import { OrdersRequest } from '../../store/storeInterfaces';
import { removeCart } from '../../store/slices/CartSlice';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../models/constants';
import { ProductsAPI } from '../../store/services/ProductsService';
import { LoadingScreen } from '../atoms/loading';
import { Link } from 'react-router-dom';

const cx = cnBind.bind(styles);

const OrderingViewer = () => {
  const [orderPost] = ProductsAPI.useOrderPostMutation();
  const [triggerPaymentLinkGet, { data: paymentLink }] = ProductsAPI.useLazyPaymentLinkGetQuery();
  const dispatch = useAppDispatch();
  const cartProducts = useAppSelector((state) => state.cart);
  const { total, totalWight, order_products } = useAppSelector((state) => state.generalConditions);
  const user = useAppSelector((state) => state.user);
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [userId, setUserId] = useState('');
  const [commentInput, setComment] = useState('');
  const [addressInput, setAddress] = useState('');
  const [selectedOption, setSelectedOption] = useState(true);
  const [isDisabledButton, setDisabledButton] = useState(true);
  const [isSomePending, setSomePending] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    clearErrors,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (user.name) setNameInput(user.name);
    if (user.phone) setPhoneInput(user.phone);
    if (user.email) setEmailInput(user.email);
    if (user.id) setUserId(user.id);
  }, [user.email, user.id, user.name, user.phone]);

  useEffect(() => {
    if (paymentLink && paymentLink.payment_link) {
      window.location.href = paymentLink.payment_link;
      dispatch(removeCart());
      setSomePending(false);
    }
  }, [dispatch, navigate, paymentLink]);

  useEffect(() => {
    if (!selectedOption) clearErrors('address');
  }, [clearErrors, selectedOption]);

  const orderRequest = async (orderData: OrdersRequest) => {
    const response = await orderPost(orderData).unwrap();
    if (!('error' in response)) {
      await triggerPaymentLinkGet(response.order_id).unwrap();
    }
  };

  const submitForm = async () => {
    const hasErrors = Object.keys(errors).length > 0;

    if (
      !hasErrors &&
      nameInput.trim() &&
      phoneInput &&
      emailInput &&
      (addressInput || !selectedOption)
    ) {
      const orderData: OrdersRequest = {
        user_id: userId,
        order_products: order_products,
        name: nameInput,
        comment: commentInput,
        phone: phoneInput,
        email: emailInput,
        address: addressInput,
        delivery: selectedOption,
      };
      setSomePending(true);
      try {
        orderRequest(orderData);
      } catch (error) {
        navigate('/fail-pay', { state: { scrollToTop: true } });
      }
    } else return;
  };
  const MyKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+'];
    if (evt.key === 'Delete' || evt.key === 'Backspace') {
      return true;
    }
    if (allowedKeys.includes(evt.key)) {
      return true;
    }
    evt.preventDefault();
    return false;
  };
  if (isSomePending) {
    return <LoadingScreen />;
  }
  return (
    <div className={styles.viewer__wrapper}>
      <h4 className={styles.viewer__header}>Ваши контакты</h4>
      <form onSubmit={handleSubmit(submitForm)} className={styles.viewer__form}>
        <div className={styles.form__left}>
          <div className={styles.form__top}>
            <div className={styles.form__inputs}>
              <input
                type="text"
                value={nameInput}
                {...register('name', {
                  minLength: 2,
                  required: 'Поле не может быть пустым',
                })}
                placeholder="Имя"
                className={cx(styles.form__input, { input__error: errors.name })}
                onChange={(evt) => setNameInput(evt.target.value)}
                onInput={() => {
                  clearErrors('name');
                }}
              />
              <input
                type="email"
                {...register('email', {
                  minLength: 4,
                  pattern: EMAIL_REGEX,
                  required: 'Поле не может быть пустым',
                })}
                placeholder="E-mail"
                className={cx(styles.form__input, { input__error: errors.email })}
                onChange={(evt) => setEmailInput(evt.target.value)}
                value={emailInput}
                onInput={() => {
                  clearErrors('email');
                }}
              />
              <input
                type="text"
                value={phoneInput}
                {...register('phone', {
                  minLength: 11,
                  required: 'Поле не может быть пустым',
                })}
                placeholder="+7 ХХХ ХХХ ХХ ХХ"
                className={cx(styles.form__input, { input__error: errors.phone })}
                onChange={(evt) => setPhoneInput(evt.target.value)}
                onInput={() => {
                  clearErrors('phone');
                }}
                onKeyDown={(evt) => MyKeyPress(evt)}
              />
            </div>
            <textarea
              className={styles.form__textaria}
              placeholder="Комментарий к заказу"
              onChange={(evt) => setComment(evt.target.value)}
            />
          </div>
          <div className={styles.delivery__details}>
            <div className={styles.details__top}>
              <h5 className={styles.details__header}>Доставка</h5>
              <div className={styles.top__checkboxes}>
                <label htmlFor="door" className={styles.checkbox__label}>
                  До двери
                  <input
                    {...register('delivery')}
                    type="radio"
                    name="delivery"
                    id="door"
                    value="delivery"
                    className={styles.checkbox__input}
                    onChange={() => setSelectedOption(true)}
                    checked={selectedOption === true}
                  />
                  <div className={styles.checkbox__indicator} />
                </label>
                <label htmlFor="self" className={styles.checkbox__label}>
                  Самовывоз
                  <input
                    {...register('radioOption')}
                    type="radio"
                    name="delivery"
                    id="self"
                    value="pickup"
                    className={styles.checkbox__input}
                    checked={selectedOption === false}
                    onChange={() => setSelectedOption(false)}
                  />
                  <div className={styles.checkbox__indicator} />
                </label>
              </div>
            </div>
            <div className={styles.details__bottom}>
              <h5 className={styles.details__header}>Адрес доставки</h5>
              <input
                disabled={!selectedOption}
                type="text"
                {...register('address', {
                  minLength: 7,
                  required: 'Поле не может быть пустым',
                })}
                placeholder="Напишите свой адрес"
                className={cx(styles.form__input, { input__error: errors.address })}
                onChange={(evt) => setAddress(evt.target.value)}
                onInput={() => {
                  clearErrors('address');
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.cart__total}>
          <div className={styles.total__top}>
            <div className={styles.total__item}>
              <span className={styles.item__element}>Доставка</span>
              <span className={styles.item__element}>По согласованию</span>
            </div>
            <div className={styles.total__item}>
              <span className={styles.item__element}>Всего товаров</span>
              <span className={styles.item__element}>{cartProducts.length} шт</span>
            </div>
            <div className={styles.total__item}>
              <span className={styles.item__element}>Общий вес</span>
              <span className={styles.item__element}>{totalWight.toFixed(2)} кг</span>
            </div>
            <div className={styles.total__item}>
              <span className={styles.item__total}>Итого</span>
              <span className={styles.item__total}>{total.toFixed(2)} ₽</span>
            </div>
          </div>
          <div className={styles.total__divider} />
          <p className={styles.bottom__link}>
            Оформляя заказ вы соглашаетесь <br />с{' '}
            <Link to="/confidentiality-policy" target="_blank">
              <span>политикой конфиденциальности</span>
            </Link>
          </p>

          {/* <button type="submit">Оплатиить заказ</button> */}
          <Button
            // disabled={isDisabledButton}
            hasIcon={false}
            text="Оплатить"
            isPrimary
            onClick={submitForm}
            full
          />
        </div>
      </form>
    </div>
  );
};

export default OrderingViewer;
