import Smoke from '../../../components/Background/Smoke';
import styles from './step-three.module.css';
import cn from 'classnames';
import Step3Img from '../../../assets/img1/step_3_bg.png';

const Step3 = () => {
  return (
    <section className={styles.step_three__wrapper}>
      <Smoke />
      <div className={cn(styles.step_three__container, 'container')}>
        <div className={styles.step_three__content}>
          <img src={Step3Img} alt="Изображение продукции" className={styles.content__image} />

          <div className={styles.content__section}>
            <h3 className="step__title">Особенности</h3>
            <ul className={styles.section__list}>
              <li className={styles.list__item}>
                <span className={styles.item__top}>Большой ассортимент</span>
                <span className={styles.item__bottom}>Самая высококачественная продукция </span>
              </li>
              <li className={styles.list__item}>
                <span className={styles.item__top}>Собственное производство</span>
                <span className={styles.item__bottom}>Мощность 60 кг в сутки</span>
              </li>
              <li className={styles.list__item}>
                <span className={styles.item__top}>Все под заказ</span>
                <span className={styles.item__bottom}>Продукция производится именно под вас</span>
              </li>
              <li className={styles.list__item}>
                <span className={styles.item__top}>Ветеринарные документы</span>
                <span className={styles.item__bottom}>Полный пакет документов и разрешений</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step3;
