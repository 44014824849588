import { Link } from 'react-router-dom';
import { useState } from 'react';
import DashboardButton from '../../components/UIKit/DashboardButton';
import cn from 'classnames';
import { GOODS_LIST_HEADERS } from '../../models/constants';
import styles from './d-goods.module.css';
import List from '../../components/Dashboard copy/List';
import GoodItem from '../../components/Dashboard copy/GoodItem';
import { useAppSelector, useAppDispatch } from '../../store';
import GoodImage from '../../assets/img1/goods_icon.png';
import { Helmet } from 'react-helmet';
import { setChange } from '../../store/slices/ProductPhotoSlice';

const DashboardGoods = () => {
  const [activeItem, setActiveItem] = useState<string>('');
  const products = useAppSelector((state) => state.products);
  const onClickItem = (id: string) => {
    setActiveItem(id);
  };
  const categoriesFromStore = useAppSelector((state) => state.categories);
  const dispatch = useAppDispatch();
  const clearStroreFlag = () => {
    dispatch(setChange(false));
  };

  const goods = products.map((good) => {
    const category = categoriesFromStore.find((category) => category.id === good.category);

    return (
      <GoodItem
        key={good.id}
        good_photo={good?.photo ? good.photo[0] : GoodImage}
        good_category={category ? category.title : ''}
        good_id={good.id?.toString() ?? ''}
        good_price={good.price ?? 0}
        good_title={good.title ?? ''}
        onMain={good.main_page ?? false}
        onToggleActive={onClickItem}
        active_id={activeItem}
      />
    );
  });

  return (
    <section className={styles.goods__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className={cn(styles.goods__container, 'container')}>
        <div className={styles.goods__top}>
          <h3 className={styles.top__title}>Товары</h3>
          <Link to="/admin/good-detail/new">
            <DashboardButton
              text="Добавить"
              hasIcon
              // eslint-disable-next-line prettier/prettier
              onClick={clearStroreFlag}
            />
          </Link>
        </div>
        <div className={styles.list}>
          <List headerTitles={GOODS_LIST_HEADERS} hiddenLast list={goods} enviroment="goods" />
        </div>
      </div>
    </section>
  );
};

export default DashboardGoods;
