import { useState, useEffect } from 'react';
const TIMEOUT = 3000;

export const useSwiperHandler = () => {
  const [rotateForward, setRotetateForward] = useState<boolean>(false);
  const [rotateBack, setRotetateBack] = useState<boolean>(false);

  const onForwardClick = () => {
    setRotetateForward(true);
  };

  const onBackClick = () => {
    setRotetateBack(true);
  };

  useEffect(() => {
    if (rotateBack) {
      setTimeout(() => {
        setRotetateBack(false);
        return;
      }, TIMEOUT);
    }

    if (rotateForward) {
      setTimeout(() => {
        setRotetateForward(false);
        return;
      }, TIMEOUT);
    }
  }, [rotateBack, rotateForward]);

  return {
    rotateBack,
    rotateForward,
    onForwardClick,
    onBackClick,
  };
};
