import styles from './step-six.module.css';
import cn from 'classnames';
import { ReactComponent as CatalogIcon } from '../../../assets/icons/catalog_icon_big.svg';
import CatalogPreviewItem from '../../../components/CatalogPreviewItem';
import GalleryItem from '../../../assets/img/gallery_item.png';
import Button from '../../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store';
import { ProductType } from '../../../store/storeInterfaces';
import { v4 } from 'uuid';

const Step6 = () => {
  const navigate = useNavigate();
  const products = useAppSelector((state) => state.products);
  const [productsForDisplay, setProductsForDisplay] = useState<ProductType[]>([]);

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter((product) => product.main_page);
      setProductsForDisplay(filteredProducts);
    }
  }, [products]);

  return (
    <section className={styles.step_six__wrapper}>
      <div className={cn(styles.step_six__container, 'container')}>
        <div className={styles.step_six__content}>
          <div className={styles.content__header}>
            <CatalogIcon className={styles.icon__custom} />
            <h3 className={styles.header__title}>Каталог</h3>
          </div>
          <ul className={styles.catalog__list}>
            {productsForDisplay.map((product) => (
              <div key={v4()} className={styles.list__item}>
                <CatalogPreviewItem
                  weight={product.weight}
                  title={product.title}
                  id={product.id?.toString()}
                  text={`${product.price?.toString()} ₽`}
                  img={product.photo?.[0] ?? GalleryItem}
                  cook_time={product.cook_time}
                />
              </div>
            ))}
          </ul>
        </div>
        <div className={styles.button__block}>
          <Button
            text="Посмотреть все"
            isPrimary={true}
            hasIcon={false}
            onClick={() => {
              navigate('/catalog', { state: { scrollToTop: true } });
            }}
            full
          />
        </div>
      </div>
    </section>
  );
};

export default Step6;
