import { Link } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/trash_bin.svg';
import cnBind from 'classnames/bind';
import styles from './good-item.module.css';
import GoodImage from '../../../assets/img1/goods_icon.png';
import { useState } from 'react';
import Button from '../../atoms/Button';
import { ProductsAPI } from '../../../store/services/ProductsService';

type GoodItemProps = {
  good_id: string;
  active_id: string;
  good_title: string;
  onMain: boolean;
  good_price: number;
  good_category: string;
  good_photo: string;
  onToggleActive: (arg1: string) => void;
};

const cx = cnBind.bind(styles);

const GoodItem = ({
  active_id,
  good_id,
  good_price,
  good_title,
  good_category,
  onMain,
  good_photo,
  onToggleActive,
}: GoodItemProps) => {
  const [removeCartWindow, setRemoveCartWindow] = useState(false);
  const [deleteProductById] = ProductsAPI.useDeleteProductByIdMutation();

  const handleRemoveTrue = (id: string) => {
    deleteProductById(id);
    setRemoveCartWindow(false);
  };

  const handleRemove = () => {
    setRemoveCartWindow(true);
  };

  const handleClickCancel = () => {
    setRemoveCartWindow(false);
  };
  return (
    <>
      {removeCartWindow && (
        <div className={styles.removeCartWindow}>
          <h2> Удалить товар из каталога?</h2>
          <div className={styles.row}>
            <button onClick={handleClickCancel} className={styles.repeat__button}>
              Отмена
            </button>
            <Button
              hasIcon={false}
              text="Удалить"
              isPrimary={true}
              onClick={() => handleRemoveTrue(good_id)}
              full={true}
            />
          </div>
        </div>
      )}
      <li
        className={cx(styles.item__wrapper, { active: good_id === active_id })}
        onClick={() => onToggleActive(good_id)}
      >
        <div className={styles.item__container}>
          <img
            className={styles.photo}
            src={good_photo ?? GoodImage}
            alt={`изображение ${good_title}`}
          />
          <div className={styles.item__content}>
            <div className={styles.content__left}>
              <span className={styles.item__element}>{good_title}</span>
              <span className={styles.item__element}>{onMain ? 'Да' : 'Нет'}</span>
            </div>
            <div className={styles.content__right}>
              <div className={styles.right__elements}>
                <span className={styles.item__element}>
                  {good_price}
                  <span>₽</span>
                </span>
                <span className={styles.item__element}>{good_category}</span>
              </div>
              <div className={styles.item__icons}>
                <Link to={`/admin/good-detail/${good_id}`}>
                  <SettingsIcon className={styles.icon__custom} />
                </Link>
                {/* <button onClick={handleRemove}> */}
                <DeleteIcon onClick={handleRemove} className={styles.icon__custom} />
                {/* </button> */}
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default GoodItem;
