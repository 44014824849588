import { Swiper, SwiperSlide } from 'swiper/react';
import {
  MediaPlayer,
  MediaOutlet,
  MediaPlayButton,
  MediaFullscreenButton,
  MediaMuteButton,
} from '@vidstack/react';
import styles from './mobile-slider.module.css';
import 'swiper/css';
import { v4 } from 'uuid';
import { ReactComponent as PlayIcon } from '../../assets/svg/play_video_icon.svg';
import 'vidstack/styles/defaults.css';

type MobileSliderProps = {
  images?: string[];
  videoLinks?: string[];
};

const MobileSlider = ({ images, videoLinks }: MobileSliderProps) => {
  return (
    <Swiper
      className={styles.slider__container}
      slidesPerView="auto"
      spaceBetween={14}
      slidesOffsetAfter={50}
    >
      {images?.length &&
        images.map((num) => (
          <SwiperSlide key={num} className={styles.slider__item}>
            <img src={num} alt="product" className={styles.slider__image} />
          </SwiperSlide>
        ))}
      {videoLinks?.length &&
        videoLinks.map((link) => (
          <SwiperSlide key={v4()} className={styles.video__item}>
            <MediaPlayer src={link} aspectRatio={16 / 9} autoplay muted>
              <MediaOutlet>
                <MediaPlayButton>
                  <PlayIcon />
                </MediaPlayButton>
                <MediaMuteButton />
                <MediaFullscreenButton />
              </MediaOutlet>
            </MediaPlayer>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default MobileSlider;
