import { useMatchMedia } from '../../../hooks/useMatchMedia';
import ItemCartImg from '../../../assets/img1/item_icon_cart.png';
import ItemDeliveryImg from '../../../assets/img1/item_icon_delivery.png';
import ItemProductImg from '../../../assets/img1/item_icon_product.png';
import ItemOrderImg from '../../../assets/img1/item_icon_order.png';
import styles from './step-four.module.css';
import cn from 'classnames';

const Step4 = () => {
  const { isMobile } = useMatchMedia();

  return (
    <section className={styles.step_four__wrapper}>
      <div className={cn(styles.step_four__container, 'container')}>
        <div className={styles.step_four__content}>
          <h3 className="step__title">Как сделать заказ</h3>
          <ul className={styles.content__section}>
            <li className={cn(styles.section__item, styles.item_one)}>
              <div className={styles.item__top}>
                <span className={styles.top__left}>01</span>
                <div className={styles.top__right}>
                  <div className={styles.right__highlight} />
                  <img src={ItemCartImg} alt="Первый элемент списка" />
                </div>
              </div>
              <div className={styles.item__bottom}>
                <span className={styles.bottom__title}>Оформить заказ</span>
                <span className={styles.bottom__text}>
                  Собрать и оплатить корзину можно на сайте
                </span>
              </div>
            </li>
            <li className={cn(styles.section__item, styles.item_two)}>
              <div className={styles.item__top}>
                <span className={styles.top__left}>02</span>
                <div className={styles.top__right}>
                  <div className={styles.right__highlight} />
                  <img src={ItemOrderImg} alt="Второй элемент списка" />
                </div>
              </div>
              <div className={styles.item__bottom}>
                <span className={styles.bottom__title}>Подтвердить заказ</span>
                <span className={styles.bottom__text}>
                  Мы свяжемся с вами для уточнения деталей
                </span>
              </div>
            </li>
            <li className={cn(styles.section__item, styles.item_three)}>
              <div className={styles.item__top}>
                <span className={styles.top__left}>03</span>
                <div className={styles.top__right}>
                  <div className={styles.right__highlight} />
                  <img src={ItemProductImg} alt="Третий элемент списка" />
                </div>
              </div>
              <div className={styles.item__bottom}>
                <span className={styles.bottom__title}>Запускаем в производство</span>
                <span className={styles.bottom__text}>
                  Ваш заказ формируется и&nbsp;запускается&nbsp;в&nbsp;производство
                </span>
              </div>
            </li>
            <li className={cn(styles.section__item, styles.item_four)}>
              <div className={styles.item__top}>
                <span className={styles.top__left}>04</span>
                <div className={styles.top__right}>
                  <div className={styles.right__highlight} />
                  <img src={ItemDeliveryImg} alt="Четвертый элемент списка" />
                </div>
              </div>
              <div className={styles.item__bottom}>
                <span className={styles.bottom__title}>Отправляем в доставку</span>
                <span className={styles.bottom__text}>Упаковываем заказ и отправляем его вам</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {!isMobile && (
        <>
          <div className={styles.step_way_one} />
          <div className={styles.step_way_two} />
          <div className={styles.step_way_three} />
        </>
      )}
    </section>
  );
};

export default Step4;
