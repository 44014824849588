import { createSlice } from '@reduxjs/toolkit';
import { GetCategoriesReq } from '../storeInterfaces';

const initialState: GetCategoriesReq[] = [];
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(state, action) {
      return [...action.payload];
    },
  },
});

export const { setCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
