import { ReadOnlyFile } from '../../../../store/storeInterfaces';
import { FILES_ERRORS_TEXTS } from '../../../../models/constants';
const JPEG_LIMIT = 9000000;
const MP4_LIMIT = 90000000;

export const checkoutFile = (file: ReadOnlyFile, format = 'jpeg') => {
  const fileFormat = file.fileType.split('/').pop();
  const fileReader = new FileReader();
  fileReader.readAsText(file.file);

  let error = false;
  let errorMessage = '';

  fileReader.onerror = () => {
    error = true;
    errorMessage = FILES_ERRORS_TEXTS.load;
  };

  if (
    (format === 'jpeg' && file.fileSize > JPEG_LIMIT) ||
    (format === 'mp4' && file.fileSize > MP4_LIMIT)
  ) {
    error = true;
    errorMessage = FILES_ERRORS_TEXTS.size;
  }

  if (fileFormat !== format) {
    error = true;
    errorMessage = FILES_ERRORS_TEXTS.format;
  }

  return {
    error,
    errorMessage,
  };
};
