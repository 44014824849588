import styles from './catalog.module.css';
import { ReactComponent as CatalogIcon } from '../../assets/icons/catalog_icon_big.svg';
import { ReactComponent as CatalogIconMob } from '../../assets/icons/catalog_icon_mobile.svg';
import { useLocation } from 'react-router-dom';
import BreadScrumbs from '../../components/BreadScrumbs';
import Select from '../../components/atoms/Select';
import Card from '../../components/Card';
import GoodImage from '../../assets/img/good_image.png';
import { useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import { ProductType } from '../../store/storeInterfaces';
import { v4 } from 'uuid';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const Catalog = () => {
  const { categoryValue, searchValue } = useAppSelector((state) => state.generalConditions);
  const products = useAppSelector((state) => state.products);
  const location = useLocation();
  const [productsForDisplay, setProductsForDisplay] = useState<ProductType[]>([]);
  const { isMobile } = useMatchMedia();

  useScrollToTop();

  useEffect(() => {
    if (products) {
      let filteredProducts = products;
      if (categoryValue && categoryValue !== 0) {
        filteredProducts = filteredProducts.filter((product) => product.category === categoryValue);
      }

      if (searchValue) {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        filteredProducts = filteredProducts.filter((product) => {
          const { title, description } = product;
          return (
            title?.toLowerCase().includes(lowerCaseSearchValue) ||
            description?.toLowerCase().includes(lowerCaseSearchValue)
          );
        });
      }

      setProductsForDisplay(filteredProducts);
    }
  }, [products, categoryValue, searchValue]);

  return (
    <section className={styles.catalog__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className="page__shadow" />
      <div id="top" className={cn(styles.catalog__container, 'container')}>
        <BreadScrumbs location={location.pathname} />
        <div className={styles.catalog__header}>
          <div className={styles.header__left}>
            {isMobile ? <CatalogIconMob /> : <CatalogIcon />}
            <h2 className={styles.left__title}>Каталог</h2>
          </div>
          {!isMobile && <Select />}
        </div>
        {isMobile && <Select />}
        <div className={styles.catalog__grid}>
          {productsForDisplay.map((product) => (
            <Card
              key={v4()}
              units={product.units ?? 'кг'}
              weight={product.weight ?? 1}
              id={product.id?.toString() ?? ''}
              title={product.title ?? ''}
              category={product.category ?? 0}
              image={product.photo?.[0] ?? GoodImage}
              price={product.price?.toString() ?? ''}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Catalog;
