import styles from './form.module.css';
import Button from '../atoms/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { FORM_TITLES, EMAIL_REGEX } from '../../models/constants';
import cn from 'classnames/bind';
import { useEffect, useState } from 'react';
import { AuthorizationUserAPI } from '../../store/services/UserService';
import { RegData } from '../../store/storeInterfaces';
import { useAppSelector } from '../../store';
import { ServerError } from '../atoms/ServerError/server-error';
import { HashLink } from 'react-router-hash-link';

const cx = cn.bind(styles);

interface IFormData {
  email: string;
  password?: string;
  username?: string;
}

type FormProps = {
  title: string;
};

const Form = ({ title }: FormProps) => {
  const { tokenReCapcha } = useAppSelector((state) => state.authorization);
  const navigate = useNavigate();
  const [regUser, { isSuccess }] = AuthorizationUserAPI.useRegUserMutation();
  const [authorizationUser, { isSuccess: isSuccessAuthorizationUser }] =
    AuthorizationUserAPI.useAuthorizationUserMutation();
  const [forgotPassword, { isSuccess: isSuccessForgotPassword }] =
    AuthorizationUserAPI.useForgotPasswordMutation();

  const [isSuccessReg, setSuccessReg] = useState(false);
  const [isForgotPasswordOk, setForgotPasswordOk] = useState(false);
  const [isModal, setModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormData>();

  useEffect(() => {
    if (isSuccessForgotPassword) setForgotPasswordOk(true);
  }, [isSuccessForgotPassword]);

  // useEffect(() => {
  //   if (token) navigate('/', { replace: true });
  // }, [token, navigate]);

  useEffect(() => {
    if (isSuccess) setSuccessReg(true);
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessAuthorizationUser) navigate('/', { state: { scrollToTop: true } });
  }, [isSuccessAuthorizationUser, navigate]);

  const onSubmit = async (data: FieldValues) => {
    const formData: RegData = {
      email: data.email,
      password: data.password,
      name: data.username || undefined,
    };

    if (title === 'Регистрация') {
      if (tokenReCapcha === 'True') await regUser(formData).unwrap();
    }

    if (title === 'Вход') authorizationUser(formData).unwrap();
    if (title === 'Забыли пароль?') forgotPassword({ email: data.email }).unwrap();
  };

  if (isSuccessReg || isForgotPasswordOk)
    return (
      <div className={styles.finally__message}>
        <span className={styles.message__text}>
          {isSuccessReg
            ? 'вам на почту пришла ссылка на подтверждение электронной почты'
            : 'на вашу электронную почту отправлен новый пароль'}
        </span>
      </div>
    );

  return (
    <form className={styles.form__container} onSubmit={handleSubmit(onSubmit)}>
      {isModal && (
        <ServerError
          message={'Возможно такой пользователь уже есть'}
          close={() => setModal(false)}
        />
      )}
      <h3 className={styles.form__title}>{title}</h3>
      {title === 'Регистрация' && (
        <div className={styles.input__group}>
          <label htmlFor="username">Имя</label>
          <input
            type="text"
            id="username"
            {...register('username', {
              required: 'Поле должно быть заполнено',
            })}
            onInput={() => clearErrors('username')}
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}
            className={cx(styles.form__input, { error: errors?.username })}
          />
          <span className={cx(styles.error__message, { visible: !isValid })}>
            {errors && errors.username?.message}
          </span>
        </div>
      )}

      <div className={styles.input__group}>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          {...register('email', {
            required: 'Поле должно быть заполнено',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Введите корректный адрес эл. почты',
            },
          })}
          onInput={() => clearErrors('email')}
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
          className={cx(styles.form__input, { error: errors?.email })}
        />
        <span className={cx(styles.error__message, { visible: !isValid })}>
          {errors && errors.email?.message}
        </span>
      </div>
      {title !== 'Забыли пароль?' && (
        <div className={styles.input__group}>
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            {...register('password', {
              required: 'Поле должно быть заполнено',
              minLength: {
                value: 8,
                message: 'Пароль должен состоять минимум из 8 символов',
              },
            })}
            onInput={() => clearErrors('password')}
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            className={cx(styles.form__input, { error: errors?.password })}
          />
          <span className={cx(styles.error__message, { visible: !isValid })}>
            {errors && errors?.password?.message}
          </span>
          {!errors?.password && title !== 'Регистрация' && (
            <NavLink
              to="/recovery-pass"
              className={cx(styles.advantage, styles.advantage__link, styles.forgot_pass__link)}
            >
              Забыли пароль?
            </NavLink>
          )}
        </div>
      )}
      <div className={styles.form__buttons}>
        <Button
          full={true}
          hasIcon={false}
          isPrimary={false}
          // eslint-disable-next-line prettier/prettier
          onClick={() => { }}
          text={FORM_TITLES[title as keyof typeof FORM_TITLES]}
        />
        {title === 'Забыли пароль?' && (
          <NavLink to="/signup" className={styles.custom__navbutton}>
            <span>Назад к регистрации</span>
          </NavLink>
        )}
        {title === 'Регистрация' && (
          <HashLink smooth to="/#top" className={styles.custom__navbutton}>
            <span>На главную</span>
          </HashLink>
        )}
      </div>
      {title === 'Регистрация' && (
        <p className={styles.advantage}>
          Уже есть аккаунт?{' '}
          <HashLink smooth to="/login" className={styles.advantage__link}>
            Войти
          </HashLink>
        </p>
      )}
      {title !== 'Вход' && (
        <div className={styles.advantage}>
          <pre>
            Регистрируясь вы соглашаетесь {'\n'} с{' '}
            <HashLink
              smooth
              target="_blank"
              to="/confidentiality-policy"
              className={styles.advantage__link}
            >
              политикой конфиденциальности
            </HashLink>
          </pre>
        </div>
      )}

      {title === 'Вход' && (
        <p className={styles.advantage}>
          Нет аккаунта?{' '}
          <NavLink to="/signup" className={styles.advantage__link}>
            Зарегистрироваться
          </NavLink>
        </p>
      )}
    </form>
  );
};

export default Form;
