import OrderDetailsWidget, { DataForPath } from '../../components/Dashboard copy/OrderDetails';
import OrderContentItem, {
  OrderContentItemProps,
} from '../../components/Dashboard copy/OrderContentItem';
import DashboardButton from '../../components/UIKit/DashboardButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductsAPI } from '../../store/services/ProductsService';
import cn from 'classnames';
import styles from './d-order-detail.module.css';
import { dateFormatter } from './helpers/dateFormatter';
import { v4 } from 'uuid';
import { orderSumCalculator } from './helpers/orderSumCalculator';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { OrderProducts, OrdersPatchAdmin } from '../../store/storeInterfaces';

import OrderContentItemNew from '../../components/Dashboard copy/OrderContentItem/index-new';

const DashboardOrdersDetail = () => {
  const location = useLocation();

  const [newDataForPathOrderDetailsWidget, setNewDataForPathOrderDetailsWidget] =
    useState<DataForPath>();

  const [newDataForPathOrderContentItem, setNewDataForPathOrderContentItem] = useState<
    OrderProducts[]
  >([]);
  const [isOrderPrice, setOrderPrice] = useState(0);
  const [isNewProdList, setNewProdList] = useState<OrderProducts[]>([]);
  const [isDisabled, setDisabled] = useState(true);
  const [isIdExceptions, setIdExceptions] = useState<number[]>([]);
  const [orderPatchAA, { isError, isSuccess }] = ProductsAPI.useOrderPatchAAMutation();
  const orderNumber = location.pathname
    .split('/')
    .filter((el) => Boolean(parseInt(el)))
    .join();
  const navigate = useNavigate();

  const { data: currentOrder } = ProductsAPI.useSingleOrderGetQuery(orderNumber);
  const [trigger] = ProductsAPI.useLazySingleOrderGetQuery();

  useEffect(() => {
    if (currentOrder) {
      setNewDataForPathOrderDetailsWidget({
        delivery: currentOrder.delivery,
        address: currentOrder.address,
      });
      setNewDataForPathOrderContentItem(currentOrder.order_products);
      const exceptions = currentOrder.order_products.map((e) => e.product);
      setIdExceptions(exceptions);
    }
  }, [currentOrder]);

  useEffect(() => {
    console.log(newDataForPathOrderContentItem, 'newDataForPathOrderContentItem');
    const sum = newDataForPathOrderContentItem.reduce((total, item) => total + item.price, 0);
    setOrderPrice(sum);
  }, [newDataForPathOrderContentItem]);

  useEffect(() => {
    if (isError) {
      setDisabled(false);
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      trigger(orderNumber);
      navigate('/admin', { state: { scrollToTop: true } });
    }
  }, [isSuccess, navigate, orderNumber, trigger]);

  const addNewProd = () => {
    setDisabled(false);
    const prod = {
      product: 0,
      amount: 0,
      price: 0,
    };
    setNewProdList([...isNewProdList, prod]);
  };
  const handleUpdateProdListOld = (data: OrderProducts) => {
    setDisabled(false);
    setNewDataForPathOrderContentItem((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.product === data.product) {
          return data;
        }
        return item;
      });

      return updatedData;
    });
  };

  const handleDelNewProd = () => {
    setNewProdList([]);
  };
  const handleDelProd = (id: number) => {
    setDisabled(false);
    const updatedData = newDataForPathOrderContentItem.filter((item) => item.product !== id);
    setNewDataForPathOrderContentItem(updatedData);
  };

  const handleUpdateProdList = (data: OrderProducts) => {
    setDisabled(false);
    setNewDataForPathOrderContentItem([...newDataForPathOrderContentItem, data]);
    setNewProdList([]);
  };
  const handleDetails = (data: DataForPath) => {
    setDisabled(false);
    setNewDataForPathOrderDetailsWidget(data);
  };
  const HandleClickSave = () => {
    setNewProdList([]);
    if (newDataForPathOrderDetailsWidget?.delivery !== undefined && currentOrder?.id) {
      const sendData: OrdersPatchAdmin = {
        id: currentOrder?.id.toString(),
        order: {
          delivery: newDataForPathOrderDetailsWidget.delivery,
          address: newDataForPathOrderDetailsWidget.address,
          order_products: newDataForPathOrderContentItem,
        },
      };
      orderPatchAA(sendData);
      setDisabled(true);
    }
  };
  return (
    <section className={styles.detail__wrapper}>
      <Helmet>
        <title>Казацкая изба</title>
      </Helmet>
      <div className={cn(styles.detail__container, 'container')}>
        <div className={styles.detail__top}>
          <h3 className={styles.detail__title}>Заказ #{orderNumber}</h3>
          <DashboardButton
            text="Сохранить"
            // eslint-disable-next-line prettier/prettier
            onClick={HandleClickSave}
            disabled={isDisabled}
          />
        </div>

        <div className={styles.details__widget}>
          <h4 className={styles.widget__title}>Детали заказа</h4>
          <OrderDetailsWidget
            updateDataForPath={(data) => handleDetails(data)}
            order_id={currentOrder?.id}
            order_date={dateFormatter(currentOrder?.created_on)}
            order_sum={currentOrder ? orderSumCalculator(currentOrder?.order_products) : 0}
            order_status={currentOrder?.payment}
            delivery_type={currentOrder?.delivery}
            customer_name={currentOrder?.name}
            customer_email={currentOrder?.email}
            customer_phone={currentOrder?.phone}
            customer_address={currentOrder?.address}
            delivery_status={currentOrder?.status || 'В процессе'}
          />
        </div>
        <div className={styles.details__widget}>
          <h4 className={styles.widget__title}>Состав заказа</h4>
          <div className={styles.order__content}>
            <div className={styles.column}>
              <div className={styles.sum}>
                <button
                  disabled={isNewProdList.length > 0}
                  className={styles.add__button}
                  type="button"
                  onClick={addNewProd}
                >
                  {' '}
                  дополнить заказ
                </button>{' '}
                {/* {!isDisabled && <p>Cумма обновленного заказа {isOrderPrice.toFixed(1)} ₽</p>} */}
              </div>
              {isNewProdList && isNewProdList.length > 0 && (
                <ul className={styles.content__list}>
                  <OrderContentItemNew
                    key={v4()}
                    product_id={isNewProdList[0].product}
                    product_amount={isNewProdList[0].amount}
                    product_price={isNewProdList[0].price}
                    updateDataOrderContentForPath={(data) => handleUpdateProdList(data)}
                    delProd={handleDelNewProd}
                    exceptions={isIdExceptions}
                  />
                </ul>
              )}
            </div>
            <ul className={styles.content__list}>
              {newDataForPathOrderContentItem.map((prod) => (
                <OrderContentItem
                  key={v4()}
                  product_id={prod.product}
                  product_amount={prod.amount}
                  product_price={prod.price}
                  updateDataOrderContentForPath={(data) => handleUpdateProdListOld(data)}
                  delProd={(id) => handleDelProd(id)}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardOrdersDetail;
