import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '../Card';
import GoodImage from '../../assets/img/good_image.png';
import { v4 } from 'uuid';
import styles from './carousel.module.css';
import { useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import { ProductType } from '../../store/storeInterfaces';

const config = {
  desktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 3,
  },
  laptop: {
    breakpoint: { max: 1439, min: 1025 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 320 },
    items: 1,
  },
};

type CarouselComponentProps = {
  title: string;
  recommended?: ProductType[];
};

const CarouselComponent = ({ title, recommended }: CarouselComponentProps) => {
  const products = useAppSelector((state) => state.products);
  const [productsForDisplay, setProductsForDisplay] = useState<ProductType[]>([]);

  useEffect(() => {
    if (products && !recommended) {
      const shuffledProducts = [...products].sort(() => Math.random() - 0.5);
      setProductsForDisplay(shuffledProducts);
    }
    if (recommended) {
      setProductsForDisplay(recommended);
    }
  }, [products, recommended]);

  return (
    <div className={styles.carousel__wrapper}>
      <div className={styles.carousel__container}>
        <h3 className={styles.carousel__title}>{title}</h3>
        <Carousel
          responsive={config}
          infinite
          keyBoardControl
          draggable={false}
          swipeable
          removeArrowOnDeviceType={['tablet', 'mobile']}
        >
          {productsForDisplay.map((product) => (
            <div key={v4()}>
              <Card
                units={product.units ?? 'кг'}
                weight={product.weight ?? 1}
                id={product.id?.toString() ?? ''}
                title={product.title ?? ''}
                category={product.category ?? 0}
                image={product.photo?.[0] ?? GoodImage}
                price={product.price?.toString() ?? ''}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselComponent;
