import StatusTag from '../../UIKit/StatusTag';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arr_up.svg';
import styles from './order-details.module.css';
import { useState, SyntheticEvent, useEffect, useRef } from 'react';
import { ProductsAPI } from '../../../store/services/ProductsService';
export type DataForPath = {
  delivery?: boolean;
  address?: string;
};
type OrderDetailsWidgetProps = {
  order_date?: string;
  order_sum: number;
  order_status?: boolean;
  delivery_type?: boolean;
  customer_name?: string;
  customer_email?: string;
  customer_phone?: string;
  customer_address?: string;
  delivery_status: string;
  order_id?: number;
  updateDataForPath: (data: DataForPath) => void;
};

const OrderDetailsWidget = ({
  order_date,
  order_sum,
  order_status,
  delivery_type,
  customer_name,
  customer_email,
  customer_phone,
  customer_address,
  delivery_status,
  order_id,
  updateDataForPath,
}: OrderDetailsWidgetProps) => {
  const [rubles, kopecks] = order_sum.toString().split('.');
  const [visible, setVisible] = useState<boolean>(false);
  const [inputAddressValue, setAddressValue] = useState('');
  const [selectDeliverValue, setSelectDeliverValue] = useState('');

  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const [updateProductStatus] = ProductsAPI.useUpdateProductStatusMutation();
  useEffect(() => {
    if (delivery_type) setSelectDeliverValue('До двери');
    else setSelectDeliverValue('Самовывоз');
  }, [delivery_type]);
  useEffect(() => {
    setCurrentStatus(delivery_status);
  }, [delivery_status]);

  useEffect(() => {
    if (customer_address) setAddressValue(customer_address);
  }, [customer_address]);

  const handleClose = (evt: SyntheticEvent) => {
    const target = evt?.target as HTMLLIElement;
    if (order_id) updateProductStatus({ id: order_id, status: target.textContent });
    setCurrentStatus(target.textContent);
    setVisible(false);
  };

  return (
    <div className={styles.order_details__wrapper}>
      <div className={styles.order_details__element}>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Дата заказа</span>
          <span className={styles.row__text}>{order_date}</span>
        </div>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Сумма</span>
          <span className={styles.row__text}>
            {rubles}.<span className={styles.row__text_lighten}>{kopecks || '00'}</span>₽
          </span>
        </div>
        <div className={styles.specific__row}>
          <StatusTag text={order_status ? 'Оплачен' : 'На согласовании'} />
        </div>
      </div>
      <div className={styles.order_details__element}>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Имя</span>
          <span className={styles.row__text}>{customer_name}</span>
        </div>
        <div className={styles.element__row}>
          <span className={styles.row__text}>E-mail</span>
          <span className={styles.row__text}>{customer_email}</span>
        </div>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Телефон</span>
          <span className={styles.row__text}>{customer_phone}</span>
        </div>
      </div>
      <div className={styles.order_details__element}>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Доставка</span>
          <select
            className={styles.item__input}
            name="deliver"
            value={selectDeliverValue}
            onChange={(evt) => {
              const isDelivery = evt.target.value === 'До двери';
              setSelectDeliverValue(evt.target.value);
              updateDataForPath({
                address: inputAddressValue,
                delivery: isDelivery,
              });
            }}
          >
            <option value="До двери">До двери</option>
            <option value="Самовывоз">Самовывоз</option>
          </select>
        </div>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Адрес</span>
          {selectDeliverValue !== 'Самовывоз' && (
            <input
              className={styles.item__input}
              value={inputAddressValue}
              onChange={(evt) => {
                setAddressValue(evt.target.value);
                updateDataForPath({
                  address: evt.target.value,
                  delivery: selectDeliverValue === 'До двери' ? true : false,
                });
              }}
            />
          )}
          {/* <span className={styles.row__text}>{customer_address}</span> */}
        </div>
      </div>
      <div className={styles.order_details__element}>
        <div className={styles.element__row}>
          <span className={styles.row__text}>Статус заказа</span>
          <div className={styles.order__status}>
            <StatusTag text={currentStatus} />
            {!visible ? (
              <ArrowDown className={styles.icon__custom} onClick={() => setVisible(true)} />
            ) : (
              <ArrowUp className={styles.icon__custom} onClick={() => setVisible(false)} />
            )}
          </div>
        </div>
        {visible && (
          <ul className={styles.status__list} onClick={(evt) => handleClose(evt)}>
            <li className={styles.list__item}>На согласовании</li>
            <li className={styles.list__item}>В процессе</li>
            <li className={styles.list__item}>Отгружен</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default OrderDetailsWidget;
