import GoodsIcon from '../../../assets/img1/goods_icon.png';
import styles from './order_content_item.module.css';
import { ProductsAPI } from '../../../store/services/ProductsService';
import { KeyboardEvent, useEffect, useState } from 'react';
import { OrderProducts } from '../../../store/storeInterfaces';
import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings_icon.svg';
export type OrderContentItemProps = {
  product_id: number;
  product_amount?: number;
  product_price?: number;
  updateDataOrderContentForPath: (data: OrderProducts) => void;
  delProd: (id: number) => void;
};

const OrderContentItem = ({
  product_id,
  product_amount = 0,
  product_price = 0,
  delProd,
  updateDataOrderContentForPath,
}: OrderContentItemProps) => {
  const { data: product, isSuccess } = ProductsAPI.useGetProductByIdQuery(product_id.toString());

  const [inputAmountValue, setAmountValue] = useState<string>(product_amount.toString());
  const [isChange, setChange] = useState(false);

  const [inputPriceValue, setPriceValue] = useState<string>(
    (product_price / product_amount).toFixed(2)
  );

  const [isWi, setWi] = useState(product_price.toString());

  useEffect(() => {
    if (Number(inputAmountValue) !== 0) {
      setWi((parseFloat(inputPriceValue) * parseFloat(inputAmountValue)).toFixed(1));
    }
  }, [inputPriceValue, inputAmountValue]);
  const MyKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    if (evt.key === 'Delete' || evt.key === 'Backspace') {
      return true;
    }
    if (allowedKeys.includes(evt.key)) {
      return true;
    }
    evt.preventDefault();
    return false;
  };

  const addNewProd = () => {
    console.log(
      parseFloat(parseFloat(inputAmountValue).toFixed(2)),
      parseFloat(parseFloat(isWi).toFixed(2)),
      inputAmountValue,
      isWi
    );
    updateDataOrderContentForPath({
      product: product_id,
      amount: parseFloat(parseFloat(inputAmountValue).toFixed(2)),
      price: parseFloat(parseFloat(isWi).toFixed(1)),
    });
  };

  return (
    <div className={styles.item__wrapper}>
      <img
        src={isSuccess && product?.photo ? product?.photo[0] : GoodsIcon}
        alt="good's icon"
        className={styles.item__image}
      />
      <div className={styles.item__content}>
        <div className={styles.product_title}>
          <span className={styles.content__text}>{product?.title ?? 'Наименование товара'}</span>
        </div>
        {isChange ? (
          <>
            <input
              className={styles.item__input}
              value={inputPriceValue}
              onChange={(evt) => {
                const value = evt.target.value;
                const updatedValue = value.replace(',', '.');
                setPriceValue(updatedValue);
              }}
              onKeyDown={(evt) => MyKeyPress(evt)}
            />{' '}
            <span className={styles.content__text}>
              <span>₽</span>
            </span>
          </>
        ) : (
          <span className={styles.content__text}>
            {(product_price / product_amount).toFixed(2)} <span>₽</span>
          </span>
        )}
        {isChange ? (
          <>
            <input
              className={styles.item__input}
              value={inputAmountValue}
              onChange={(evt) => {
                const value = evt.target.value;
                const updatedValue = value.replace(',', '.');
                setAmountValue(updatedValue);
              }}
              onKeyDown={(evt) => MyKeyPress(evt)}
            />
            <span>{product?.units ?? 'кг'}</span>
          </>
        ) : (
          <span className={styles.content__text}>
            {product_amount} <span>{product?.units}</span>
          </span>
        )}

        <span className={styles.content__text}>
          {isWi} <span>₽</span>
        </span>
      </div>
      {isChange && (
        <>
          <button className={styles.add__button} type="button" onClick={addNewProd}>
            {' '}
            сохранить
          </button>
          <button className={styles.del__button} type="button" onClick={() => delProd(product_id)}>
            {' '}
            удалить
          </button>
        </>
      )}
      <SettingsIcon className={styles.icon__custom} onClick={() => setChange(!isChange)} />
    </div>
  );
};

export default OrderContentItem;
