import styles from './pay.module.css';
import cn from 'classnames';
import FailIcon from '../../assets/img1/fail_pay.png';
import Button from '../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const FailPay = () => {
  const { isMobile } = useMatchMedia();
  const navigate = useNavigate();

  const redirectToMain = () => {
    navigate('/', { state: { scrollToTop: true } });
  };

  return (
    <section className={styles.page__wrapper}>
      <div className="page__shadow" />
      <div className={cn(styles.page__container, 'container')}>
        <div className={styles.page__content}>
          <div className={styles.content__top}>
            <img src={FailIcon} alt="Оплата не прошла" className={styles.top__img} />
            <span className={styles.top__text}>
              Что-то пошло не так. {!isMobile && <br />}Попробуй снова
            </span>
          </div>
          <div className={styles.content__button}>
            <Button isPrimary text="На главную" onClick={redirectToMain} hasIcon={false} full />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FailPay;
