import { forwardRef } from 'react';
import styles from './toggle-switch.module.css';

type ToggleSwitchProps = {
  checked: boolean;
  toggleChange: () => void;
};

const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>((props, ref) => {
  return (
    <label className={styles.switch__label} htmlFor="toggle-switch">
      <input
        id="toggle-switch"
        type="checkbox"
        className={styles.switch__input}
        ref={ref}
        checked={props.checked}
        onChange={() => props.toggleChange()}
      />
      <span className={styles.switch__slider} />
    </label>
  );
});

export default ToggleSwitch;
