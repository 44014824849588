import SwiperCore, { EffectCards, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import styles from './swiper-cards.module.css';
import { v4 } from 'uuid';
SwiperCore.use([EffectCards, Navigation]);

type SwiperCardsProps = {
  prevElement: HTMLDivElement | null;
  nextElement: HTMLDivElement | null;
  photoArr: string[];
  forward: () => void;
  back: () => void;
};

const SwiperCards = ({ prevElement, nextElement, photoArr, forward, back }: SwiperCardsProps) => {
  return (
    <Swiper
      navigation={{
        prevEl: prevElement,
        nextEl: nextElement,
      }}
      effect="cards"
      grabCursor
      className={styles.container}
      cardsEffect={{
        slideShadows: false,
      }}
      onSlideNextTransitionStart={() => forward()}
      onSlidePrevTransitionStart={() => back()}
    >
      {photoArr && photoArr.length > 0 && (
        <>
          {' '}
          {photoArr.map((image) => (
            <SwiperSlide key={v4()}>
              <img
                src={image}
                alt={`Изображение продукта ${image} `}
                className={styles.item__image}
              />
            </SwiperSlide>
          ))}
        </>
      )}
    </Swiper>
  );
};

export default SwiperCards;
