import DashboardButton from '../../components/UIKit/DashboardButton';
import DashboardSelect from '../../components/Dashboard copy/DSelect';
import ToggleSwitch from '../../components/UIKit/ToggleSwitch';
import { useRef, useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus_icon.svg';
import cnBind from 'classnames/bind';
import styles from './d-good-detail.module.css';
import RecomendationsGoods from '../../components/Dashboard copy/RecomendationGoods';
import { useForm, FieldValues } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from '../../store';
import { ProductType } from '../../store/storeInterfaces';
import { ProductsAPI } from '../../store/services/ProductsService';
import { useLocation, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { useRecomendations } from '../../hooks/useRecomendations';
import { setChange } from '../../store/slices/ProductPhotoSlice';

const cx = cnBind.bind(styles);

type ContextType = {
  handleDragModal: (arg0: boolean, arg1?: string) => void;
};

const DashboardGoodDetail = () => {
  const toggleRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const categoriesFromStore = useAppSelector((state) => state.categories);
  const products = useAppSelector((state) => state.products);
  const { productPhotos, isChanged } = useAppSelector((state) => state.productPhoto);
  const [patchProductById, { data: dataPatch }] = ProductsAPI.usePatchProductByIdMutation();
  const [addProductById, { data: returnedProd }] = ProductsAPI.useAddProductByIdMutation();
  const [selectCategory, setSelectCategory] = useState('');
  const [selectCategoryNum, setSelectCategoryNum] = useState<number>(0);
  const [selectUnits, setSelectUnits] = useState('кг');
  const [isNewProd, setNewProd] = useState(false);
  const [isIdProd, setIdProd] = useState('');
  const [isGood, setGood] = useState<ProductType>();
  const [categories, setCategories] = useState<string[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: recomendationsProducts } = ProductsAPI.useGetAllRecomendationsProductsQuery(
    id ?? '0'
  );

  const [postProductRecomendations] = ProductsAPI.usePostProductRecomendationsMutation();
  const { titles, ids, addCurrentRecomend, removeFromCurrentRecomends, onValueChange } =
    useRecomendations(id, recomendationsProducts, products);
  ProductsAPI.useGetProductPhotoByIdQuery(id ?? '0');
  const { handleDragModal } = useOutletContext<ContextType>();
  const dispatch = useAppDispatch();

  const [isTitleInput, setTitleInput] = useState('');
  const [isPriceInput, setPriceInput] = useState<number>(0);
  const [isPriceDeliveryInput, setPriceDeliveryInput] = useState('');
  const [isCookTimeInput, setCookTimeInput] = useState('');
  const [isWeightInput, setWeightInput] = useState(1);
  const [isTemperatureInput, setTemperaturenput] = useState('');
  const [isCompositionInput, setCompositionInput] = useState('');
  const [isDescriptionInput, setDescriptionInput] = useState('');
  const [isSeoTitleInput, setSeoTitleInput] = useState('');
  const [isSeoDescriptionInput, setSeoDescriptionInput] = useState('');
  const [isOnMain, setOnMain] = useState(false);
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const handleToggleChange = () => {
    setOnMain(!isOnMain);
  };

  useEffect(() => {
    if (location.pathname.includes('new')) {
      setNewProd(true);
    } else {
      if (id && products) {
        setIdProd(id);
        const goodFromId = products.find((e) => e.id === Number(id));
        setGood(goodFromId);
        const categoryGood = categoriesFromStore.find(
          (category) => category.id === goodFromId?.category
        );
        if (categoryGood) {
          setSelectCategory(categoryGood?.title);
        }
      }
    }
  }, [categoriesFromStore, id, location.pathname, products]);

  useEffect(() => {
    if (isGood) {
      if (isGood.title) setTitleInput(isGood.title);
      if (isGood.price) setPriceInput(isGood.price);
      if (isGood.delivery) setPriceDeliveryInput(isGood.delivery);
      if (isGood.cook_time) setCookTimeInput(isGood.cook_time);
      if (isGood.weight) setWeightInput(isGood.weight);
      if (isGood.temperature) setTemperaturenput(isGood.temperature);
      if (isGood.composition) setCompositionInput(isGood.composition);
      if (isGood.description) setDescriptionInput(isGood.description);
      if (isGood.seo_description) setSeoDescriptionInput(isGood.seo_description);
      if (isGood.seo_title) setSeoTitleInput(isGood.seo_title);
      if (isGood.main_page) setOnMain(isGood.main_page);
      if (isGood.category) {
        const currentCategory = categoriesFromStore.find((el) => el.id === isGood.category);
        if (currentCategory) {
          setSelectCategory(currentCategory.title);
        }
      }
    }
  }, [isGood, categoriesFromStore]);

  useEffect(() => {
    console.log(
      'titles',
      titles,
      recomendationsProducts,
      'recomendationsProducts DashboardGoodDetail'
    );
    if (returnedProd) {
      handleDragModal(true, returnedProd.id?.toString());
    }
    if (returnedProd && isNewProd) {
      navigate('/admin/goods');
    }
  }, [returnedProd]);

  useEffect(() => {
    if (categoriesFromStore) {
      const categoryTitles = categoriesFromStore.map((category) => category.title);
      setCategories(categoryTitles);
    }
  }, [categoriesFromStore]);

  useEffect(() => {
    if (categoriesFromStore && selectCategory) {
      const foundCategory = categoriesFromStore.find(
        (category) => category.title === selectCategory
      );

      if (foundCategory) {
        setSelectCategoryNum(foundCategory.id);
      }
    }
  }, [categoriesFromStore, selectCategory]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyValue = event.key;
    const regex = /^[0-9.]+$/;

    if (!regex.test(keyValue) && !['Backspace', 'Delete'].includes(keyValue)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const formData: ProductType = {
      title: isTitleInput,
      price: isPriceInput,
      category: selectCategoryNum,
      temperature: isTemperatureInput,
      weight: data.weight,
      composition: isCompositionInput,
      description: isDescriptionInput,
      seo_title: isSeoTitleInput,
      seo_description: isSeoDescriptionInput,
      cook_time: isCookTimeInput,
      delivery: isPriceDeliveryInput,
      units: selectUnits,
      main_page: isOnMain,
    };
    if (isNewProd) {
      dispatch(setChange(true));
      addProductById(formData).unwrap();
    } else if (id) {
      patchProductById({ id: id, body: formData }).unwrap();

      if (ids && ids?.length > 0) {
        postProductRecomendations({ id, ids });
      }
      sessionStorage.removeItem('recomendTitles');
    }
  };
  return (
    <section className={styles.detail__wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={cx(styles.detail__container, 'container')}>
        <div className={cx(styles.detail__section)}>
          <div className={styles.section__top}>
            <h4 className={styles.section__title}>
              {isNewProd ? 'Создание товарной позиции' : 'Обновление товарной позиции'}
            </h4>
            <DashboardButton
              text="Сохранить"
              type="submit"
              // eslint-disable-next-line prettier/prettier
              onClick={() => { }} />
          </div>
          <div className={cx(styles.section__content, styles.section__left)}>
            <label className={styles.seo__description}>Название</label>
            <input
              type="text"
              className={`${styles.simple__input} ${errors.title ? styles.errorIn : ''}`}
              placeholder="Название"
              {...register('title', {
                required: isNewProd ? true : false,
              })}
              onChange={(evt) => setTitleInput(evt.target.value)}
              value={isTitleInput}
              onInput={() => clearErrors('title')}
            />
            <label className={styles.seo__description}>Цена</label>
            <input
              type="text"
              {...register('price', {
                required: isNewProd ? true : false,
              })}
              onKeyDown={handleKeyPress}
              className={`${styles.simple__input} ${errors.price ? styles.errorIn : ''}`}
              placeholder="Цена"
              onChange={(evt) => setPriceInput(Number(evt.target.value))}
              value={isPriceInput}
              onInput={() => clearErrors('price')}
            />
            <label className={styles.seo__description}>Выберите категорию</label>
            <div className={styles.select__block}>
              <DashboardSelect
                placeholder="Выберите категорию"
                list={categories}
                onChange={(e) => setSelectCategory(e)}
                defaultValue={selectCategory}
              />
              <PlusIcon
                className={cx('icon__default', styles.icon__custom)}
                onClick={() => navigate('/admin/categories', { state: { scrollToTop: true } })}
              />
            </div>
            <div className={styles.switch__section}>
              <ToggleSwitch ref={toggleRef} checked={isOnMain} toggleChange={handleToggleChange} />
              <label htmlFor="toggle-switch" className={styles.switch__text}>
                Добавить на главную страницу
              </label>
            </div>
          </div>
        </div>
        <div className={styles.section__relative}>
          <div className={cx(styles.detail__section, styles.section__left)}>
            <h4 className={styles.section__title}>Характеристики</h4>
            <div className={styles.section__content}>
              <label className={styles.seo__description}>Срок изготовления</label>
              <input
                {...register('cookTime', {
                  required: isNewProd ? true : false,
                })}
                className={`${styles.simple__input} ${errors.cookTime ? styles.errorIn : ''}`}
                type="text"
                placeholder="Срок изготовления"
                onChange={(evt) => setCookTimeInput(evt.target.value)}
                value={isCookTimeInput}
                onInput={() => clearErrors('cookTime')}
              />
              <label className={styles.seo__description}>Цена доставки</label>
              <input
                type="text"
                {...register('priceDelivery', {
                  required: isNewProd ? true : false,
                })}
                className={`${styles.simple__input} ${errors.priceDelivery ? styles.errorIn : ''}`}
                placeholder="Цена доставки"
                onChange={(evt) => setPriceDeliveryInput(evt.target.value)}
                value={isPriceDeliveryInput}
                onInput={() => clearErrors('priceDelivery')}
              />
              <label className={styles.seo__description}>Температура хранения</label>
              <input
                type="text"
                {...register('temperature', {
                  required: isNewProd ? true : false,
                })}
                className={`${styles.simple__input} ${errors.temperature ? styles.errorIn : ''}`}
                placeholder="Температура хранения"
                onChange={(evt) => setTemperaturenput(evt.target.value)}
                value={isTemperatureInput}
                onInput={() => clearErrors('temperature')}
              />
              <label className={styles.seo__description}>Единицы измерения</label>
              <DashboardSelect
                placeholder="Единицы измерения"
                list={['шт', 'кг', 'г']}
                onChange={(e) => setSelectUnits(e)}
                defaultValue={isGood?.units ?? 'кг'}
              />
              <label className={styles.seo__description}>Вес</label>
              <input
                type="text"
                {...register('weight', {
                  required: isNewProd ? true : false,
                })}
                onKeyDown={handleKeyPress}
                className={`${styles.simple__input} ${errors.weight ? styles.errorIn : ''}`}
                placeholder="Вес"
                onChange={(evt) => setWeightInput(Number(evt.target.value))}
                value={isWeightInput}
                onInput={() => clearErrors('weight')}
              />
              <label className={styles.seo__description}>Состав</label>
              <input
                type="text"
                {...register('composition', {
                  required: isNewProd ? true : false,
                })}
                className={`${styles.simple__input} ${errors.composition ? styles.errorIn : ''}`}
                placeholder="Состав"
                onChange={(evt) => setCompositionInput(evt.target.value)}
                value={isCompositionInput}
                onInput={() => clearErrors('composition')}
              />
              <label className={styles.seo__description}>Описание</label>
              <textarea
                value={isDescriptionInput}
                {...register('description', {
                  required: isNewProd ? true : false,
                })}
                className={cx(styles.simple__input, styles.simple__textarea, {
                  errorIn: errors.description,
                })}
                placeholder="Описание"
                onChange={(evt) => setDescriptionInput(evt.target.value)}
                onInput={() => clearErrors('description')}
              />
            </div>
          </div>
          <div className={cx(styles.detail__section, styles.section__right, styles.photo__section)}>
            <div className={styles.photo__content}>
              {productPhotos && productPhotos.length > 0 && (
                <ul className={styles.photo__images}>
                  {productPhotos.map((image) => (
                    <li key={image.id} className={styles.images__item}>
                      <img src={image.file_link} alt="" />
                    </li>
                  ))}
                </ul>
              )}

              <div className={styles.btn__container}>
                {(!isNewProd || isChanged) && (
                  <button
                    className={styles.other__button}
                    type="button"
                    onClick={() => handleDragModal(true, id)}
                  >
                    {!productPhotos?.length ? 'Загрузить фото' : 'Редактировать фото'}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={cx(styles.detail__section, styles.section__right, styles.seo__section)}>
            <h4 className={styles.section__title}>SEO</h4>
            <div className={styles.section__content}>
              <label className={styles.seo__description}>Title</label>
              <input
                type="text"
                {...register('seoTitle', {
                  required: isNewProd ? true : false,
                })}
                className={`${styles.simple__input} ${errors.seoTitle ? styles.errorIn : ''}`}
                placeholder="Title"
                onChange={(evt) => setSeoTitleInput(evt.target.value)}
                value={isSeoTitleInput}
                onInput={() => clearErrors('seoTitle')}
              />
              <span className={styles.seo__description}>
                Рекомендуется 50-60 символов включая пробелы
              </span>
              <label className={styles.seo__description}>Description</label>
              <textarea
                {...register('seoDescription', {
                  required: isNewProd ? true : false,
                })}
                className={cx(styles.simple__input, styles.seo__textarea, {
                  errorIn: errors.seoDescription,
                })}
                placeholder="Description"
                onChange={(evt) => setSeoDescriptionInput(evt.target.value)}
                value={isSeoDescriptionInput}
                onInput={() => clearErrors('seoDescription')}
              />
              <span className={styles.seo__description}>
                Рекомендуется 150-170 символов включая пробелы
              </span>
            </div>
          </div>
          <div
            className={cx(styles.detail__section, styles.section__right, styles.recomend__section)}
          >
            <div className={styles.recomend__top}>
              <h4 className={styles.section__title}>Рекомендуемые товары</h4>
              <span className={styles.seo__description}>Рекомендуется больше 5 наименований</span>
            </div>
            <div className={styles.section__content}>
              <RecomendationsGoods
                recomendationsList={titles}
                allProducts={products}
                onValueChange={onValueChange}
                removeFromCurrentRecomends={removeFromCurrentRecomends}
                addCurrentRecomend={addCurrentRecomend}
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default DashboardGoodDetail;
